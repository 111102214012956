import {UrlUtils} from './api/urls';
import {CookiesManagement} from './cookies_management';
import {searchEventHandler, sphereEventHandler} from './custom_event_utils';
import {SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES as EVENTS} from './event-names';
import {MATOMO_EVENT_NAMES} from './metric-events';
import {Metrics} from './metrics';
import Router from './router';
import {
  CLUSTER_CAPTION_REGEX,
  CLUSTER_NAME_REGEX,
  DIRECTION,
  PRODUCT_PAGE_ROUTES,
  SPHERE_ITEM_TYPES
} from './shared/constants';
import {SphereApp} from './sphere_app';
import {SphereItem} from './sphere_item';
import {WebUtils} from './utils/web_utils';
import {
  ACTION_TYPE,
  AnimateActionData,
  ClusterActionData,
  LinkActionData,
  ProductActionData,
} from './interfaces/planogram.interface';
import {Overlay} from './overlay';
import {BaseInputHandler} from './base_input_handler';
import {L10nUtils} from './utils/l10n_utils';
import {AppUtils} from './utils/app_utils';

export class InputHandler extends BaseInputHandler {
  overlay: Overlay;
  isClusterSelected: boolean;
  selectedCluster: string;

  constructor(sphereApp: SphereApp) {
    super(sphereApp);
  }

  setProductOverlay(productIdentifier: string, overlayType = PRODUCT_PAGE_ROUTES.GALLERY) {
    const item = this.sphereApp.sphere.findSphereItemByIdentifier(productIdentifier);
    if (item && !this.isAnimatingToProduct) {
      this.overlay.showItem(item, overlayType);
    }
  }

  navigateToAutoplayClusters() {
    if (this.autoplayStarted) {
      this.autoplaySpeedTime = Math.max(this.autoplaySpeedTime - 1000, SphereApp.MIN_AUTOPLAY_DURATION);
    } else if (this.selectedCluster) {
      this.startAutoplay();
    } else if (this.planogram.clustersOrder && this.planogram.clustersOrder.length) {
      const clusterId = AppUtils.extractClusterName(this.planogram.clustersOrder[0]);
      Router.navigateToCluster(this.planogram.name, clusterId, {autoplay: true});
    }
  }

  autoplay() {
    this.repeatCallback = () => (this.autoplayTimeoutId = setTimeout(this.autoplay.bind(this), this.autoplaySpeedTime));
    if (this.autoplayTimeoutId && this.autoplayStarted) {
      this.cycleThroughClusters(DIRECTION.RIGHT, true);
    } else {
      const clusterName = this.selectedCluster ? AppUtils.extractClusterName(`cluster-${this.selectedCluster}`) : '';
      Router.navigateToCluster(this.planogram.name, clusterName, {autoplay: true});
    }
  }

  handleClick(x, y) {
    let isCookieOpened = false;
    try {
      isCookieOpened = CookiesManagement.cookiePopup.isOpen();
    } catch (e) {
      console.error('--- Can not handle response from Cookies popup');
    }

    if (this.overlay.isShowing() || isCookieOpened) {
      return;
    }

    const {mesh, point} = this.raycastControls.getInteractableObjectAtScreenCoordinate(
      x,
      y,
      this.scene,
      this.camera.perspectiveCamera
    );

    const item: SphereItem = mesh ? mesh.userData?.component : undefined;
    this.sphereApp.heatMapService.sendClickEvent(x, y, this.camera.currentZoomFraction(), item);

    sphereEventHandler.emit(EVENTS.CONTROL.CLICK_ITEM, {item});

    if (item === undefined) {
      return;
    }
    item.onClick(point);

    if (item.action) {
      this.handleAction(item);
    }
  }

  handleAnimateAction(data: AnimateActionData, planogramName: string, activate: boolean, redirect = false) {
    switch (data.itemType) {
      case SPHERE_ITEM_TYPES.CLUSTER:
        Router.navigateToCluster(planogramName, AppUtils.extractClusterName(data.clusterLink));
        break;
      case SPHERE_ITEM_TYPES.PRODUCT:
        if (!redirect) {
          this.animateCameraToIdentifer(data.productIdentifier, activate);
        } else {
          if (data.productName) {
            Router.navigateToProductIdAndName(
              data.productIdentifier,
              data.productName,
              planogramName,
              !activate && PRODUCT_PAGE_ROUTES.SHOW
            );
          } else {
            Router.navigateToProductId(data.productIdentifier, planogramName, !activate && PRODUCT_PAGE_ROUTES.SHOW);
          }
        }
        break;
      case SPHERE_ITEM_TYPES.IMAGE:
        Router.navigateToImage(data.imageId, data.imageName.split('.')[0].replace(/\W/g, ''), planogramName, activate);
        break;
      case SPHERE_ITEM_TYPES.IFRAME:
        Router.navigateToIframeId(data.itemId, planogramName);
        break;
      case SPHERE_ITEM_TYPES.VIDEO:
        Router.navigateToVideo(data.itemId, planogramName);
        break;
      case SPHERE_ITEM_TYPES.TEXT:
      case SPHERE_ITEM_TYPES.TEXT_AREA:
        Router.navigateToText(data.itemId, planogramName, activate);
        break;
      case SPHERE_ITEM_TYPES.SHAPE:
      case SPHERE_ITEM_TYPES.CURVE:
        Router.navigateToShapeOrCurve(data.itemId, planogramName, activate);
        break;
      default:
        Router.navigateToPlanogram(planogramName);
    }
  }

  handleAction(item) {
    switch (item.action?.type) {
      case ACTION_TYPE.ANIMATE:
        const data = item.action.data as AnimateActionData;

        if (data.sphereName) {
          const language = data.sphereName.split('_').pop();
          data.sphereName = data.sphereName.replace(`_${language}`, '');
          L10nUtils.selectLanguage(language).then(() =>
            this.handleAnimateAction(data, data.sphereName, data.applyItemsActionInTheEnd, true)
          );
          CookiesManagement.init();
          this.overlay.hide();
          this.sphereApp.isSphereLoaded = false;
        } else {
          this.handleAnimateAction(data, this.planogram.name, data.applyItemsActionInTheEnd);
        }
        Metrics.storeTheEvent(
          this.planogram.name,
          'click',
          `${MATOMO_EVENT_NAMES.WEBGL_CLICK_ANIMATE}_${item.type.toLowerCase()}${WebUtils.getItemName(item, '-')}`
        );
        break;
      case ACTION_TYPE.LINK:
        WebUtils.openLink((item.action.data as LinkActionData).url);
        Metrics.storeTheEvent(
          this.planogram.name,
          'click',
          `${MATOMO_EVENT_NAMES.WEBGL_CLICK_LINK}-${(item.action.data as LinkActionData).url}${WebUtils.getItemName(
            item
          )}`
        );
        break;

      case ACTION_TYPE.CLUSTER:
        const clusterLink = AppUtils.extractClusterName((item.action.data as ClusterActionData).clusterLink);
        Router.navigateToCluster(this.planogram.name, clusterLink);
        Metrics.storeTheEvent(
          this.planogram.name,
          'click',
          `${MATOMO_EVENT_NAMES.WEBGL_CLICK_CLUSTER_NAME}${clusterLink}`
        );
        break;

      case ACTION_TYPE.PRODUCT_OVERLAY:
        const product = this.sphere.findSphereItemByIdentifier(
          (item.action.data as ProductActionData).productIdentifier
        );
        Router.navigateToProduct(product, PRODUCT_PAGE_ROUTES.GALLERY);
        break;

      case ACTION_TYPE.SINGLE_IMAGE:
        Router.navigateToImage(
          item.data.picture?.id || item.data.id,
          (item.data.picture?.name || item.data.image_name || '').split('.')[0].replace(/\W/g, ''),
          this.planogram.name,
          true
        );
        break;

      case ACTION_TYPE.PRODUCT:
        this.animateCameraToIdentifer((item.action.data as ProductActionData).productIdentifier);
        break;

      case ACTION_TYPE.AUDIO:
        this.sphereApp.audioController.playActionAudio(item.action.data.url);
        break;

      case ACTION_TYPE.VIDEO_OVERLAY:
        Router.navigateToVideoOverlay(item);
        break;

      case ACTION_TYPE.IFRAME:
        Router.navigateToIframe(item);
        break;

      case ACTION_TYPE.SOCIAL_CONTENT_OVERLAY:
        Router.navigateToSocialMedia(item);
        break;

      case ACTION_TYPE.CONTENT_OVERLAY:
        Router.navigateToContentOverlay(item);
        break;

      case ACTION_TYPE.PRODUCT_RELEASE_COUNTDOWN:
        Router.navigateToProduct(item);
        break;

      case ACTION_TYPE.COPYRIGHT:
      case ACTION_TYPE.PRIVACY_POLICY:
      case ACTION_TYPE.ABOUT_US:
      case ACTION_TYPE.CONTACT_US:
      case ACTION_TYPE.COOKIES_POLICY:
        Router.navigateToInfoOverlay(this.planogram.name, item.action.type);
        break;

      default:
        if (item.action) {
          this.overlay.showItem(item);
        }
    }
  }

  animateCameraToCluster(clusterIdentification: string, upArrow?: boolean, callback?: Function): void {
    if (!(this.planogram.clustersOrder && this.planogram.clustersOrder.length)) {
      return;
    }
    if (!callback && this.repeatCallback) {
      callback = this.repeatCallback;
    }
    if (!clusterIdentification) {
      this.selectedCluster = clusterIdentification = this.planogram.clustersOrder[0];
    }
    this.cameraControls.clearAnimation();
    let clusterName, animation;

    if (clusterIdentification.match(CLUSTER_CAPTION_REGEX)) {
      clusterName = AppUtils.extractClusterFullName(clusterIdentification);
    } else if (clusterIdentification.match(CLUSTER_NAME_REGEX)) {
      clusterName = AppUtils.extractClusterName(clusterIdentification);
    } else {
      clusterName = clusterIdentification;
    }

    const item = this.sphere.findClusterByClusterName(clusterName);
    const delay = this.sphereApp.isSphereLoaded ? 0 : SphereApp.AFTER_LOAD_ANIMATION_DELAY;
    if (item) {
      if (this.selectedCluster === clusterIdentification && this.isClusterSelected && !upArrow) {
        animation = this.cameraControls.animateZoomFov();
        this.isClusterSelected = false;
      } else {
        animation = this.cameraControls.animateTo(item, callback, {clusterAnimation: true, delay});
        this.isClusterSelected = true;
      }
      this.selectedCluster = clusterIdentification;

      if (callback && animation) {
        this.autoplayAnimation = animation;
      } else {
        this.resetAutoplay();
      }
    } else {
      console.error('There are no cluster with this name');
    }
  }

  animateToClusterAfterLoad(clusterName: string) {
    searchEventHandler.emit(SEARCH_EVENTS.CLOSE_SEARCH);

    const afterAnimationEnd = () => {
      CookiesManagement.isRedirectAnimationProcessing = false;
      CookiesManagement.init();
    };

    const isAutoplay = UrlUtils.getQueryValueFromUrl('autoplay') === 'true';
    if (isAutoplay && !this.autoplayStarted) {
      this.selectedCluster = clusterName;
      this.startAutoplay();
    }

    if (!isAutoplay) {
      this.resetAutoplay();
    }

    const fn = () => {
      CookiesManagement.isRedirectAnimationProcessing = true;
      this.animateCameraToCluster(clusterName, true, afterAnimationEnd);
      if (isAutoplay && this.repeatCallback) {
        this.repeatCallback();
      }
    };

    this.sphereApp.isSphereLoaded ? fn() : this.sphereApp.afterLoadQueue.push(fn);
  }
}
