import * as THREE from 'three';
import {SphereGeometry} from './geometries/sphere_geometry';
import {Planogram} from './planogram';
import {
  Action,
  ACTION_TYPE,
  ImageMetaData,
  ItemData,
  LODSMetaData,
  MetaData,
  ProductActionData
} from './interfaces/planogram.interface';
import {SPHERE_ITEM_TYPES} from './shared/constants';
import {ComponentInterface} from './components/component_interface';
import {CurveGeometry} from './geometries/curve_geometry';
import {SphereItemHTMLElement} from './accessibility/aria-live-templates/sphere_item_HTML_element';
import {disposeObject3D} from './utils/disposeThree';
import {Material, Vector2} from 'three';

export class SphereItem implements ComponentInterface {
  protected geometry: SphereGeometry | CurveGeometry;
  object3D: THREE.Group;
  htmlElement: SphereItemHTMLElement | undefined;
  imageName: string;
  material: Material | undefined;
  planogram: Planogram;
  readonly type: SPHERE_ITEM_TYPES | ACTION_TYPE;
  readonly id: number | string;
  readonly identifier: string;
  readonly name: string;
  readonly code: string;
  // TODO: Update back to readonly after the designer text centering update.
  x: number;
  y: number;
  protected width: number;
  protected height: number;
  azimuthStartRadians: number;
  action?: Action;
  data: MetaData;
  filtered: any;
  private url: any;
  readonly renderOrder: number;
  readonly itemData: ItemData;
  cluster: string;

  overridePosition(x: number, y: number) {
    this.x = x;
    this.y = y;

    this.updateAzimuthStartRadians();
  }

  overrideSize(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.updateAzimuthStartRadians();
  }

  private updateAzimuthStartRadians() {
    this.azimuthStartRadians = SphereGeometry.calcAzimuthStartRadians(
      this.x,
      this.width > 0 ? this.width : 0,
      this.planogram.width
    );
  }

  constructor(params: ItemData, planogram: Planogram) {
    this.planogram = planogram;
    this.itemData = params;
    this.data = params.data as MetaData;
    this.id = params.id;
    this.x = params.x;
    this.y = params.y;
    this.width = params.width;
    this.height = params.height;
    this.code = params.data.code;
    this.updateAzimuthStartRadians();

    this.imageName = (this.data as ImageMetaData)?.imageName;
    this.renderOrder = params.renderOrder;

    if (params.action?.type === ACTION_TYPE.PRODUCT_OVERLAY) {
      this.data.product = {
        id: (params.action.data as ProductActionData)?.productId || this.data.product?.id,
        identifier: (params.action.data as ProductActionData)?.productIdentifier || this.data.product?.identifier,
        name: (params.action.data as ProductActionData)?.productName || this.data.product?.name
      };
    }

    if (params.data?.product?.name) {
      this.name = params.data.product.name;
    } else if (params.data?.item?.name) {
      this.name = params.data.item.name;
    }

    if (params.data?.product?.identifier) {
      this.identifier = params.data.product.identifier;
    } else if (params.data?.product?.id) {
      this.identifier = params.data.product.id.toString();
    }

    if (params.action) {
      this.action = params.action;
    }
    if (params.data.item?.action?.data && (params.data.item?.action?.data as any).url) {
      this.url = (params.data.item?.action?.data as any).url;
    }

    this.type = params.type;
  }

  createMesh(): Promise<void> {
    this.geometry = this.generateGeometry();
    this.object3D = new THREE.Group();

    const mesh = new THREE.Mesh(this.geometry, this.material);

    if (
      ![
        SPHERE_ITEM_TYPES.IMAGE,
        SPHERE_ITEM_TYPES.PRODUCT,
        SPHERE_ITEM_TYPES.TEXT,
        SPHERE_ITEM_TYPES.TEXT_AREA,
        SPHERE_ITEM_TYPES.VIDEO,
        SPHERE_ITEM_TYPES.SHAPE
      ].includes(this.type as SPHERE_ITEM_TYPES) ||
      ((this.type === SPHERE_ITEM_TYPES.IMAGE || this.type === SPHERE_ITEM_TYPES.PRODUCT) &&
        !(this.data as LODSMetaData).lods)
    ) {
      this.object3D.visible = false;
    }

    this.object3D.renderOrder = this.renderOrder;
    // Render order is added to the mesh to make it easier to sort elements when dealing only with meshes.
    mesh.renderOrder = this.renderOrder;
    mesh.userData = {
      component: this
    };
    this.object3D.add(mesh);
    this.htmlElement = new SphereItemHTMLElement(this);
    if (this.hasInput()) {
      mesh.layers.enable(2);
      this.object3D.layers.enable(2);
    }

    return Promise.resolve();
  }

  hasInput() {
    return !!this.action || this.type === SPHERE_ITEM_TYPES.CLICKABLE_AREA || this.type === SPHERE_ITEM_TYPES.VIDEO;
  }

  generateGeometry(): SphereGeometry {
    const azimuthLength = SphereGeometry.calcAzimuthLengthRadians(Math.abs(this.width), this.planogram.width);
    const cols = Math.max(1, Math.ceil(azimuthLength / ((2 * Math.PI) / Planogram.COLUMN_COUNT)));
    const rows = Math.max(1, Math.ceil(Math.abs(this.height) / (this.planogram.height / Planogram.ROW_COUNT)));
    const isReflectedOnX = Math.sign(this.width) < 0;

    // us are calculated going backwards from the right
    // since texture is on outside of sphere
    const geom = new SphereGeometry(
      Planogram.ALPHA,
      this.planogram.largeRadius,
      this.planogram.fixedRadius,
      cols,
      rows,
      this.azimuthStartRadians,
      azimuthLength,
      this.planogram.height,
      this.y,
      this.height,
      true,
      new THREE.Vector2(isReflectedOnX ? 0 : 1, 1),
      new THREE.Vector2(isReflectedOnX ? 1 : 0, 0)
    );

    return geom;
  }

  onVisibilityToggle(isVisible: boolean): void {}

  onClick(position: THREE.Vector3): void {}

  onHoverEnter(): void {}

  onHoverLeave(): void {}

  onVisibilityChange(): void {}

  onDrag(position: THREE.Vector3): void {}

  isDraggable(): boolean {
    return false;
  }

  getPosition() {
    return new Vector2(this.x, this.y);
  }

  getSize() {
    return new Vector2(Math.abs(this.width), Math.abs(this.height));
  }

  getViewportCenter() {
    return new Vector2(
      this.x + 0.5 * Math.abs(this.width),
      this.y + 0.5 * Math.abs(this.height) - this.planogram.height * 0.25
    );
  }

  dispose(): void {
    disposeObject3D(this.object3D);
    this.geometry?.dispose();
    this.geometry = undefined;
    this.material?.dispose();
    this.material = undefined;
    this.htmlElement?.dispose();
  }
}
