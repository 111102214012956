import {SPHERE_ITEM_TYPES} from '../shared/constants';
import {SphereApp} from '../sphere_app';
import {SphereItem} from '../sphere_item';
import {ARIA} from './aria-live-templates/templates';

export class AccessibilityService {
  static isActive: boolean = false;
  private template: ARIA;
  items: Map<string, SphereItem> = new Map<string, SphereItem>();
  private itemsList: Array<SphereItem> = new Array<SphereItem>();
  constructor(private sphereApp: SphereApp) {
    // Animate to item when user tabs through items
    document.addEventListener('focus', this.focusHandler, true);

    this.template = new ARIA(
      this,
      {
        title: this.sphereApp.planogram?.seoTitle,
        description: this.sphereApp.planogram?.seoDescription
      },
      this.itemsList
    );

    this.sphereApp.planogram.itemsOrder.forEach((id, index) => {
      const item = this.sphereApp.sphere.findSphereItemByIdentifier(id);
      if (!item) {
        return;
      }

      // Only use top level items for navigation
      if (!item.itemData.parentId && (item.type !== SPHERE_ITEM_TYPES.IMAGE || item.action)) {
        this.itemsList.push(item);
      }
      this.items.set(item.identifier, item);
      this.items.set(item.id.toString(), item);

      // Generate the item html element
      this.template.generateItemHTML(item);
    });
  }

  focusHandler = () => {
    const identifier = document.activeElement.getAttribute('item-identifier');
    if (identifier) {
      this.navigateToItem(this.items.get(identifier));
    }
  }

  navigateToItem(item: SphereItem, assertive?: boolean) {
    this.sphereApp.inputHandler.animateCameraToIdentifer(item.id as string, false);
    item.htmlElement?.info.focus();
  }

  isLastItem(item: SphereItem) {
    if (item.itemData.parent) {
      const index = item.itemData.parent.childrenIds.findIndex(id => item.id === id);
      if (index === -1) {
        return false;
      }
      return index === item.itemData.parent.childrenIds.length - 1;
    }
  }

  navigateToParent(item: SphereItem) {
    this.navigateToItem(this.items.get(item.itemData.parent.id as string));
  }

  navigateToGroup(item: SphereItem) {
    if (item.itemData.childrenIds) {
      this.navigateToItem(this.items.get(item.itemData.childrenIds[0] as string));
    }
  }

  navigateToNextItem(item: SphereItem) {
    const currentItem = !!item.itemData.parent?.id ? this.items.get(item.itemData.parent?.id as string) : item;

    const index = this.itemsList.findIndex(sphereItem => currentItem.id === sphereItem.id);
    const nextItem = this.itemsList[(index + 1) % this.itemsList.length];

    if (nextItem) {
      this.navigateToItem(nextItem);
    }
  }

  navigateToItemById(id: string): any {
    if (this.items.has(id)) {
      this.navigateToItem(this.items.get(id));
    } else {
      console.error('Cluster index out of range');
    }
  }

  toggleGroupNavigation(group: SphereItem, enable: boolean) {
    group.itemData.childrenIds.forEach(id => {
      const child = this.items.get(id.toString())?.htmlElement;
      if (enable) {
        child.container.childNodes.forEach(node => {
          (node as HTMLElement).tabIndex = 0;
        });
      } else {
        child.container.childNodes.forEach(node => {
          (node as HTMLElement).tabIndex = -1;
        });
      }
    });
  }

  navigateToSphereList() {
    this.template.focusOnSphereList();
  }

  navigateToItemList() {
    this.template.focusOnItemList();
  }

  navigateToFirstItem() {
    this.itemsList[0].htmlElement.info.focus();
  }

  repeatParentNavigation(item: SphereItem) {
    this.navigateToItem(this.items.get(item.itemData.parent?.childrenIds[0] as string));
  }

  navigateToNextItemInParent(item: SphereItem) {
    const index = item.itemData.parent.childrenIds.findIndex(id => item.id === id);
    this.navigateToItem(this.items.get(item.itemData.parent.childrenIds[index + 1] as string));
  }

  action(item: SphereItem) {
    this.sphereApp.inputHandler.handleAction(item);
  }

  dispose() {
    document.removeEventListener('focus', this.focusHandler, true);
    this.focusHandler = null;
    this.template.dispose();
  }
}
