import * as THREE from 'three';
import Cookies from 'js-cookie';

import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {BlurPass} from 'postprocessing';

import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';

import {Planogram} from './planogram';
import {Sphere} from './sphere';
import {Camera} from './camera';
import {CameraControls} from './controls/camera_controls';
import {Overlay} from './overlay';
import {Logger} from './logger';
import {Fullscreen} from './fullscreen';
import {IntersectCalculator} from './intersect_calculator';
import Router from './router';
import {InputEventUtils} from './utils/input_event_utils';
import {URLS, UrlUtils} from './api/urls';
import {FontLoader} from './font_loader';
import {CookiesManagement} from './cookies_management';
import {SphereAppData} from './interfaces/sphere.interface';
import {
  COOKIES_ATTRIBUTES,
  ECOMMERCE_BUTTON_TYPE,
  ITEM_IDENTIFIER_URL_REGEX,
  NAVIGATION_BUTTON_TYPE,
  PLANOGRAM_NAME,
  SPHERE_ITEM_TYPES
} from './shared/constants';
import {WebUtils} from './utils/web_utils';
import {MATOMO_EVENT_NAMES} from './metric-events';
import {SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES as EVENTS} from './event-names';
import {Metrics} from './metrics';
import {searchEventHandler, sphereEventHandler} from './custom_event_utils';
import {AppUtils} from './utils/app_utils';
import {AppState} from './shared/app.state';
import {EntranceGuide} from './entrance-guide';
import {ProductInfoService} from './api/services/product_info.service';
import {Account} from './account/account';
import {Search} from './search/search';
import {SearchService} from './api/services/search.service';
import {RaycastControls} from './controls/raycast_controls';
import {BaseInputHandler} from './base_input_handler';
import {InputHandler} from './input_handler';
import {L10nUtils} from './utils/l10n_utils';
import {L10nButtonComponent} from './components/l10n-button.component';
import {HeatMapService} from './api/services/heatmap.service';
import {
  ACTION_TYPE,
  ALIGNMENT_TYPES,
  PlanogramVersionControlButtonMenu,
  PlanogramVersionControlButtonMenuItem,
  ProductMetaData
} from './interfaces/planogram.interface';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {ShoppingCartService} from './api/services/shopping-cart.service';
import {CurrencySwitcher} from './components/currency-switcher/currency-switcher';
import {AccessibilityService} from './accessibility/accessibility_service';
import {CurrencyService} from './api/services/currency.service';
import {VTPipeline} from './vt/vt_pipeline';
import {SMAAPass} from 'three/examples/jsm/postprocessing/SMAAPass';
import {KeyboardControls} from './controls/keyboard_controls';
import {MouseControls} from './controls/mouse_controls';
import {TouchControls} from './controls/touch_controls';
import loadingService, {LOADING_STAGES} from './api/services/loading_progress.service';
import {normalizeMouse} from './utils/math_utils';
import {VideoComponent} from './components/video';
import {BrowserUtils} from './utils/browser_utils';
import {VideoLimiter} from './vt/video_limiter';
import {SphereShape} from './maths/sphereShape';
import {SphereItem} from './sphere_item';
import {ShoppingCartUtils} from './utils/shopping-cart_utils';
import {SphereAudio} from './sphere_audio';
import {HTMLUtils} from './utils/html_utils';
import {PlanogramFonts} from './interfaces/planogram-fonts.interface';

export class SphereApp {
  sphere: Sphere;
  scene: THREE.Scene;
  private renderer: THREE.WebGLRenderer;
  private camera: Camera;
  private cameraControls: CameraControls;
  private keyboardControls: KeyboardControls;
  private mouseControls: MouseControls;
  private touchControls: TouchControls;
  private intersectCalculator: IntersectCalculator;
  private raycastControls: RaycastControls;
  accessibilityService: AccessibilityService;
  private readonly canvas: HTMLCanvasElement;
  private composer: EffectComposer;
  private passes: {renderPass: RenderPass; blurPass: BlurPass; smaaPass: SMAAPass} = {
    renderPass: undefined,
    blurPass: undefined,
    smaaPass: undefined
  };
  private controlButton: HTMLElement;
  private firstLoad: boolean = true;
  afterLoadQueue: Array<Function> = [];
  private defaultLogoUrl: string;
  private hoveredItem;
  private searchService: SearchService;
  heatMapService: HeatMapService;
  private fullScreen: Fullscreen;
  private l10nControl: L10nButtonComponent;
  private currencySwitcherControl: CurrencySwitcher;
  currencyService: CurrencyService;
  private readonly resetAutoplayRef: Function;
  private readonly resetNavigationRef: Function;
  private readonly pageInfoRef: Function;
  account: Account;
  search: Search;
  inputHandler: BaseInputHandler;
  isSphereLoaded: boolean = false;
  isClusterSelected: boolean = false;
  selectedCluster: string;
  shoppingCartService: ShoppingCartService;
  private previousPlanogram: string;
  private previousLanguage: string;
  private clickedImageFilename: string;
  private debugMode: boolean;
  private performanceMode: boolean;
  private shoppingCart: ShoppingCartComponent;
  private disposeLoadedSubject: Function;
  private clock: THREE.Clock = new THREE.Clock();
  private audio: SphereAudio;
  private sphereShape: SphereShape;
  vtPipeline: VTPipeline;
  private videoLimiter: VideoLimiter;
  isOverlayActive: boolean = false;

  private videos: VideoComponent[];

  private cancelLoading?: () => void;
  private onTileLoad?: () => void;

  get ACTUAL_INPUT_HANDLER() {
    return this.inputHandler;
  }

  static get SPLASH_TIMEOUT() {
    return 10000;
  }

  static get BACKGROUND_COLOR() {
    return 0x121212;
  }

  static get BLUR_RESOLUTION_WIDTH() {
    return 960;
  }

  static get BLUR_DEFAULT_KERNEL_SIZE() {
    return 3;
  }

  static get BLUR_SEARCH_KERNEL_SIZE() {
    return 5;
  }

  static get BLOOM_STRENGTH() {
    return 1;
  }

  static get BLOOM_KERNEL_SIZE() {
    return 25;
  }

  static get BLOOM_SIGMA() {
    return 4;
  }

  static get BLOOM_RESOLUTION() {
    return 256;
  }

  static get MAX_AUTOPLAY_DURATION() {
    return 4000;
  }

  static get MIN_AUTOPLAY_DURATION() {
    return 500;
  }

  static get AFTER_LOAD_ANIMATION_DELAY() {
    return 500;
  }

  get threeScene() {
    return this.scene;
  }

  get threeCamera() {
    return this.camera;
  }

  get threeRenderer() {
    return this.renderer;
  }

  get effectComposer() {
    return this.composer;
  }

  get audioController() {
    return this.audio;
  }

  planogram: Planogram;

  constructor(private params: SphereAppData) {
    this.debugMode = window.location.search.includes('debug=true') && !IS_PRODUCTION;
    this.performanceMode = this.debugMode && window.location.search.includes('performance=true');
    this.controlButton = null; // Control button
    this.canvas = this.params.canvas;
    this.previousPlanogram = '';
    this.resetAutoplayRef = this.resetAutoplay.bind(this);
    this.resetNavigationRef = this.resetNavigation.bind(this);
    this.pageInfoRef = this.pageInfo.bind(this);

    window.logger = new Logger();

    this.addRoutes();

    const sphereLogoButton = document.getElementById('sphere-logo');

    if (sphereLogoButton) {
      InputEventUtils.addSelectEvents(sphereLogoButton, () => {
        Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.CLICK_LOGO);
        this.cameraControls.clearAnimation();
        this.handleLogoClick();
      });
    }

    this.sphereShape = new SphereShape(Planogram.ALPHA, 3, this.planogram.width);
  }

  private render() {
    if (this.isOverlayActive) {
      return;
    }
    const dt = this.clock.getDelta();
    this.vtPipeline.update(dt);
    this.videoLimiter.update(dt);
    this.cameraControls.updateAnimations();

    sphereEventHandler.emit(EVENTS.SPHERE.UPDATE);
    sphereEventHandler.emit(EVENTS.SPHERE.RENDER);

    if (this.debugMode && !this.performanceMode) {
      return;
    }
    this.composer.render();
    this.videos.forEach(item => item.update(this.clock.getElapsedTime()));
  }

  initApp() {
    if (this.planogram.clustersOrder) {
      this.selectedCluster = this.planogram.clustersOrder[0];
    }

    this.setupThreeJSRenderer();
    this.setupComposerAndEffects();

    this.initSphere();
    if (this.debugMode) {
      import(/* webpackChunkName: "debug-module", webpackPrefetch: true */ './debug-module')
        .then(({default: DebugModule}) => DebugModule.init(this, this.performanceMode))
        .catch(console.warn);
    }

    this.heatMapService = new HeatMapService(
      this.planogram.versionId,
      window.screen.width,
      window.screen.height,
      this.getPlanogramCoordinates.bind(this),
      document.cookie
    );

    this._setupForegroundElements();

    this.searchService = new SearchService();
    this.search = new Search(this, this.searchService);
  }

  initializeServices() {
    this.storeCredentialCookies();

    const {ecommerceEnabled} = this.planogram;
    const checkoutId = `checkout-${this.planogram.clientName}`;

    if (ecommerceEnabled) {
      const fn = () => {
        this.initCurrencySwitcher();
        this.initShoppingCart();
      };
      L10nUtils.loaded ? fn() : this.afterLoadQueue.push(fn);
    } else if (!ecommerceEnabled && ShoppingCartUtils.getStoredShoppingCart(checkoutId)) {
      ShoppingCartUtils.deleteStoredShoppingCart(checkoutId);
    }

    CookiesManagement.isAudioAvailable =
      this.planogram.audioSettings && Object.keys(this.planogram.audioSettings).length > 0;
    const audioEnabled =
      CookiesManagement.isAudioAvailable || this.planogram.items.some(item => item.action?.type === 'audio');

    if (audioEnabled) {
      this.audio = new SphereAudio(this.planogram);
    }

    const productInfoService = new ProductInfoService();
    void productInfoService.getPurchasingFlowSettings();

    this.l10nControl = new L10nButtonComponent();
    this.disposeLoadedSubject = pipe(
      L10nUtils.languageLoadedSubject,
      subscribe({
        next: (langCode: string) => {
          if (!langCode) {
            return;
          }
          this.handleLanguageChanged();
          ProductInfoService.clearCustomButtonsSettingsCache();
          ProductInfoService.clearProductInfoCache();
        }
      })
    );
  }

  private pageView(title: string) {
    document.title = title;
    Metrics.updateTitle();
    if (!this.firstLoad) {
      Metrics.onUrlChange();
    }
  }

  private pagePlanogram() {
    this.pageView(this.planogram.seoTitle);
  }

  private pageItem(item: SphereItem) {
    const seo = this.planogram.itemSEO[item?.id];
    if (seo) {
      this.pageView(seo.title);
    } else {
      this.pagePlanogram();
    }
  }

  private pageContentOverlay(link: string) {
    this.pageView(link);
  }

  private pageInfo(data) {
    this.pageView(data.seo_title);
  }

  private pageProduct(item: SphereItem) {
    const id = (item.data as ProductMetaData)?.product?.id;
    if (!id) {
      this.pagePlanogram();
      return;
    }
    const seo = this.planogram.productSEO[id];
    if (seo) {
      this.pageView(seo.title);
    } else {
      this.pagePlanogram();
    }
  }

  private addRoutes() {
    Router.on(
      Router.NAVIGATION_PAGES.PLANOGRAM,
      Router.withLang(planogram => {
        this.onPlanogramChange(planogram, () => {
          this.inputHandler.hideOverlay();
          CookiesManagement.init();
          this.heatMapService.updatePlanogram(this.planogram.versionId, document.cookie);
          this.pagePlanogram();
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.INFO_OVERLAY,
      Router.withLang((planogram, overlayType) => {
        this.onPlanogramChange(planogram, () => {
          const item = {planogram: this.planogram, action: {type: overlayType}};
          this.inputHandler.overlay.showItem(item);
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.SEARCH_OVERLAY,
      Router.withLang(planogram => {
        this.onPlanogramChange(planogram, () => {
          this.cameraControls.clearAnimation();
          CookiesManagement.init();
          this.heatMapService.updatePlanogram(this.planogram.versionId, document.cookie);
          this.search.showSearch();
          this.pagePlanogram(); // TODO: should opening search be an analytics event?
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.IMAGE,
      Router.withLang((planogram, identifier, imageName, activate) => {
        this.onPlanogramChange(planogram, () => {
          const item = this.sphere.findSphereItemByImageId(identifier);
          if (item) {
            if (item.action?.type === ACTION_TYPE.SINGLE_IMAGE) {
              this.inputHandler.animateToImageAfterLoad(item.id.toString(), activate);
            } else {
              this.inputHandler.animateToItemAfterLoad(item, activate);
            }
            this.pageItem(item);
          }
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.VIDEO,
      Router.withLang((planogram, identifier, activate) => {
        this.onPlanogramChange(planogram, () => {
          const item = this.sphere.findSphereItemByActionIdentifier(identifier);
          if (item) {
            this.inputHandler.animateToItemAfterLoad(item, activate);
            this.pageItem(item);
          }
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.TEXT,
      Router.withLang((planogram, identifier, activate) => {
        this.onPlanogramChange(planogram, () => {
          const item = this.sphere.findSphereItemByIdentifier(identifier);
          if (item) {
            this.inputHandler.animateToItemAfterLoad(item, activate);
            this.pageItem(item);
          }
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.SOCIAL_MEDIA_OVERLAY,
      Router.withLang((planogram, identifier) => {
        this.onPlanogramChange(planogram, () => {
          const item = this.sphere.findSphereItemByActionIdentifier(identifier);
          if (this.isSphereLoaded) {
            this.inputHandler.overlay.showItem(item);
          } else {
            if (item) {
              this.pageItem(item);
            }
            this.inputHandler.animateToItemAfterLoad(
              item,
              true,
              item?.action?.type === ACTION_TYPE.SOCIAL_CONTENT_OVERLAY
            );
          }
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.NAVIGATION_MENU,
      Router.withLang((planogram, identifier) => {
        this.onPlanogramChange(planogram, () => {
          const id = parseInt(identifier, 10);
          const item = this.planogram.planogramVersionControlButtons[id];
          if (item) {
            this.activeMenu = id;
            this.showNavigationMenu(item.menu);
          }
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.SHAPE,
      Router.withLang((planogram, identifier, activate) => {
        this.onPlanogramChange(planogram, () => {
          const item = this.sphere.findSphereItemByIdentifier(identifier);
          if (item) {
            this.inputHandler.animateToItemAfterLoad(item, activate);
            this.pageItem(item);
          }
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.IFRAME,
      Router.withLang((planogram, identifier) => {
        this.onPlanogramChange(planogram, () => {
          const item = this.sphere.findSphereItemByActionIdentifier(identifier);
          if (this.isSphereLoaded) {
            this.inputHandler.overlay.showItem(item);
          } else {
            if (item) {
              this.pageItem(item);
            }
            this.inputHandler.animateToItemAfterLoad(item, true, item?.action?.type === ACTION_TYPE.IFRAME);
          }
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.VIDEO_OVERLAY,
      Router.withLang((planogram, videoId) => {
        this.onPlanogramChange(planogram, () => {
          const item = this.sphere.findSphereItemByActionIdentifier(videoId);
          if (this.isSphereLoaded) {
            this.inputHandler.overlay.showItem(item);
          } else {
            if (item) {
              this.pageItem(item);
            }
            this.inputHandler.animateToItemAfterLoad(item, true, true);
          }
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.CLUSTER_AREA,
      Router.withLang((planogram, clusterName) => {
        this.onPlanogramChange(planogram, () => {
          if (this.inputHandler.overlay.isShowing()) {
            this.inputHandler.overlay.closeOverlay(false);
          }
          this.inputHandler.animateToClusterAfterLoad(clusterName);

          const item = this.sphere.findClusterByClusterName(clusterName);
          this.pageItem(item);
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.CONTENT_OVERLAY,
      Router.withLang((planogram, link) => {
        this.onPlanogramChange(planogram, () => {
          const item = this.sphere.findSphereItemByContentLink(link);
          if (this.isSphereLoaded) {
            this.inputHandler.overlay.showItem(item);
          } else if (item) {
            this.inputHandler.animateToItemAfterLoad(item, true, true);
            this.pageContentOverlay(link);
          }
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.PRODUCT_BY_CODE_AND_NAME,
      Router.withLang((planogram, identifier, name, type) => {
        this.onPlanogramChange(planogram, () => {
          const item = this.sphere.findSphereItemByIdentifier(identifier);
          if (!item) {
            Router.navigateToPlanogram(planogram);
            return;
          }
          if (item?.action?.type === ACTION_TYPE.PRODUCT_OVERLAY) {
            this.isSphereLoaded
              ? this.inputHandler.setProductOverlay(identifier, type)
              : this.inputHandler.animateToProductAfterLoad(identifier, type);
          } else {
            this.inputHandler.animateToItemAfterLoad(item, type === 'action');
          }
          this.pageProduct(item);
        });
      })
    );

    Router.on(
      Router.NAVIGATION_PAGES.PLANOGRAM_FALLBACK,
      Router.withLang(planogram => {
        Router.navigateToPlanogram(planogram);
      })
    );

    Router.on(Router.NAVIGATION_PAGES.OTHER, () => {
      Router.navigateToPlanogram(PLANOGRAM_NAME.MAIN);
    });
  }

  onPlanogramChange(planogramName: string, cb = Function()) {
    if (this.planogram?.name === planogramName && this.previousLanguage === L10nUtils.getCurrentLanguage()) {
      cb();
      return;
    }

    if (this.planogram?.name !== planogramName) {
      this.l10nControl?.clearAvailableLanguagesList();
    }

    loadingService.onLoad(() => {
      sphereEventHandler.emit(EVENTS.SPHERE.INIT, this.planogram);
    });

    loadingService.onReset(() => {
      sphereEventHandler.emit(EVENTS.SPHERE.RESET, this.planogram);
    });

    if (!this.firstLoad) {
      loadingService.reset();
      this.isSphereLoaded = false;
      this.sphere.dispose();
      this.cameraControls.clearAnimation();
      this.vtPipeline?.dispose();
      this.renderer.setAnimationLoop(null);
      this.audio?.dispose();
    }

    this.previousPlanogram = this.planogram?.name;
    this.previousLanguage = L10nUtils.getCurrentLanguage();

    this.planogram = new Planogram(planogramName);
    L10nUtils.setCurrentPlanogram(planogramName);
    document.getElementById('navigation-menu-container').classList.add('is-hidden');
    this.planogram.loaded.then(() => {
      this.sphereShape = new SphereShape(Planogram.ALPHA, 3, this.planogram.width);
      L10nUtils.setPlanogramId(this.planogram.id);
      AppState.clientName = this.planogram.clientName;
      EntranceGuide.settings = this.planogram.entranceAnimation;
      this.afterLoadQueue.push(cb);
      this.setupThreeJSScene();
      this.setupThreeJSCamera();
      this.firstLoad ? this.initApp() : this.initSphere();
      this.initializeServices();
      this.syncLogoIcon();
      this.syncOverlayAndControlColors();
      this.syncSocialMediaOverlayAndControlColors();
      this.syncControlButtons();
      this.inputHandler.hideOverlay();
      sphereEventHandler.emit(EVENTS.PLANOGRAM_CHANGED, this.planogram);
      this.searchService.updateSearchSettings();
      this.firstLoad = false;
    });
  }

  resetSphere() {
    this.inputHandler!.overlay?.dispose();
    this.l10nControl?.dispose();
    this.account?.dispose();
    this.currencyService?.dispose();
    this.currencySwitcherControl?.dispose();
    this.shoppingCart?.dispose();
    this.shoppingCartService?.dispose();
    this.disposeLoadedSubject();
    sphereEventHandler.off(
      [
        EVENTS.CAMERA.ZOOM_BY,
        EVENTS.OVERLAY.SHOW_CONTENT,
        EVENTS.ON_MOVEMENT_START,
        EVENTS.ANIMATE_ZOOM_FOV,
        EVENTS.AUTOROTATE,
        EVENTS.VIDEO.PLAY,
        EVENTS.ACCOUNT.OPEN
      ].join(' '),
      this.resetAutoplayRef
    );
    sphereEventHandler.off(EVENTS.OVERLAY.SHOW_INFO_OVERLAY_CONTENT, this.pageInfoRef);
    sphereEventHandler.off(
      [
        EVENTS.CAMERA.ZOOM_BY,
        EVENTS.TILT_AND_PAN_TO,
        EVENTS.ANIMATE_ZOOM_FOV,
        EVENTS.AUTOROTATE,
        EVENTS.VIDEO.PLAY,
        EVENTS.ACCOUNT.OPEN
      ].join(' '),
      this.resetNavigationRef
    );

    sphereEventHandler.off(EVENTS.SPHERE.VR, this.toggleVR);
    this.cancelLoading!();
  }

  initSphere() {
    if (!this.firstLoad) {
      this.resetSphere();
    }

    this.updateComposerAndEffects();

    this.raycastControls = new RaycastControls(this.scene);
    this.intersectCalculator = new IntersectCalculator(this.planogram);

    const isMobileSafari = BrowserUtils.isMobile() && BrowserUtils.isSafari();

    this.vtPipeline = new VTPipeline(
      this.planogram.virtualTexture,
      this.renderer,
      isMobileSafari ? 5 : 7,
      4096,
      this.camera,
      this.planogram
    );
    this.camera.onUpdate(() => {
      if (!this.isSphereLoaded) {
        return;
      }
      this.vtPipeline.updateViewport();
      this.videoLimiter.updateViewport(this.camera.getViewport());
    });

    this.sphere = new Sphere(this.planogram, this.scene, this.vtPipeline);

    if (!this.firstLoad) {
      this.accessibilityService.dispose();
    }
    this.accessibilityService = new AccessibilityService(this);

    const loadingSteps: Promise<any>[] = [];
    loadingSteps.push(
      new Promise<void>(resolve => {
        this.onTileLoad = resolve;
      })
    );
    loadingSteps.push(this.vtPipeline.initialize());
    loadingSteps.push(this.sphere.loadSphereItems());
    let cancel = false;
    this.cancelLoading = () => {
      cancel = true;
    };
    Promise.all(loadingSteps)
      .then(() => {
        if (cancel) {
          throw new Error('Loading interrupted');
        }
      })
      .then(() => {
        this.videos = this.sphere.sphereItems.filter(item => item.type === SPHERE_ITEM_TYPES.VIDEO) as VideoComponent[];
        this.videoLimiter = new VideoLimiter(
          this.planogram,
          this.videos,
          isMobileSafari ? 5 : 2,
          isMobileSafari ? 5 : +Infinity
        );
        loadingService.progressStage(LOADING_STAGES.INIT_SPHERE, 1);
        // don't use .forEach, need to account for pushing more items to afterLoadQueue
        for (let i = 0; i < this.afterLoadQueue.length; i++) {
          this.afterLoadQueue[i]();
        }
        this.afterLoadQueue = [];
        this.isSphereLoaded = true;
        if (this.inputHandler.isOverlayShowing()) {
          this.blurGlCanvas();
        }
        this.camera.updateCamera();
        this.renderer.setAnimationLoop(this.render.bind(this));
      });

    this.isClusterSelected = false;
    this.selectedCluster = null;
    this.clickedImageFilename = '';
    this.updateCameraControls();

    this.inputHandler = new InputHandler(this);
    this.inputHandler.overlay = new Overlay(this);
    this.inputHandler.init(
      this.planogram,
      this.cameraControls,
      this.raycastControls,
      this.sphere,
      this.camera,
      this.scene
    );

    sphereEventHandler.listen(EVENTS.PERMANENT_TILES_LOAD, this.onTileLoad);
    sphereEventHandler.listen(
      [
        EVENTS.CAMERA.ZOOM_BY,
        EVENTS.OVERLAY.SHOW_CONTENT,
        EVENTS.ON_MOVEMENT_START,
        EVENTS.ANIMATE_ZOOM_FOV,
        EVENTS.AUTOROTATE,
        EVENTS.VIDEO.PLAY,
        EVENTS.ACCOUNT.OPEN
      ].join(' '),
      this.resetAutoplayRef
    );
    sphereEventHandler.listen(EVENTS.OVERLAY.SHOW_INFO_OVERLAY_CONTENT, this.pageInfoRef);
    sphereEventHandler.listen(
      [
        EVENTS.CAMERA.ZOOM_BY,
        EVENTS.TILT_AND_PAN_TO,
        EVENTS.ANIMATE_ZOOM_FOV,
        EVENTS.AUTOROTATE,
        EVENTS.VIDEO.PLAY,
        EVENTS.ACCOUNT.OPEN
      ].join(' '),
      this.resetNavigationRef
    );

    EntranceGuide.planogramName = this.planogram.name;
    EntranceGuide.clientName = this.planogram.clientName;
    sphereEventHandler.emit(EVENTS.SPHERE.INIT, this.planogram);
    sphereEventHandler.listen(EVENTS.SPHERE.VR, this.toggleVR);
    FontLoader.init(this.planogram.id);
  }

  private storeCredentialCookies() {
    const login = UrlUtils.getQueryValueFromUrl('login');
    const password = UrlUtils.getQueryValueFromUrl('password');
    if (login && password) {
      Cookies.set(`sphere-credentials-${this.planogram.name}`, `${login}:${password}`, {
        expires: 7,
        sameSite: COOKIES_ATTRIBUTES.SAME_SITE_LAX
      });
    }
  }

  toggleVR = () => {
    this.sphere.fitSceneToVR();
    this.vtPipeline.updateViewport();
    this.vtPipeline.updatePagesInViewport();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
  };

  setupThreeJSScene() {
    if (this.firstLoad) {
      this.scene = new THREE.Scene();
    }
    this.scene.background = new THREE.Color(
      this.planogram.backgroundColor?.[0],
      this.planogram.backgroundColor?.[1],
      this.planogram.backgroundColor?.[2]
    );
  }

  setupThreeJSCamera() {
    Camera.planogramName = this.planogram.name;

    this.camera = new Camera(this.planogram, this.sphereShape);
    this.camera.updateAspect();
    this.camera.updateCamera();
  }

  setupThreeJSRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: false,
      alpha: true,
      stencil: false,
      depth: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.autoClear = false;
  }

  setupComposerAndEffects() {
    this.composer = new EffectComposer(this.renderer);

    this.passes.renderPass = new RenderPass(this.scene, this.camera.perspectiveCamera);
    this.passes.renderPass.clear = false;

    this.composer.addPass(this.passes.renderPass);

    this.passes.smaaPass = new SMAAPass(window.innerWidth, window.innerHeight);
    this.composer.addPass(this.passes.smaaPass);

    this.passes.blurPass = new BlurPass({
      width: SphereApp.BLUR_RESOLUTION_WIDTH
    });
    this.composer.addPass(this.passes.blurPass);
  }

  updateComposerAndEffects() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.composer.removePass(this.passes.renderPass);
    this.passes.renderPass = new RenderPass(this.scene, this.camera.perspectiveCamera);
    this.passes.renderPass.clear = false;
    this.composer.insertPass(this.passes.renderPass, 0);

    this.passes.smaaPass.setSize(window.innerWidth, window.innerHeight);
    this.composer.reset();

    this.composer.setSize(window.innerWidth, window.innerHeight);
  }

  blurGlCanvas(kernelSize: number = SphereApp.BLUR_DEFAULT_KERNEL_SIZE) {
    this.passes.blurPass.kernelSize = kernelSize;
    this.passes.blurPass.enabled = true;
  }

  unBlurGlCanvas() {
    this.passes.blurPass.enabled = false;
  }

  updateCameraControls() {
    if (!this.firstLoad) {
      this.mouseControls.dispose();
      this.keyboardControls.dispose();
      this.touchControls.dispose();
    }

    const domElement = document.getElementById('main-container');
    this.cameraControls = new CameraControls(this.camera, this.intersectCalculator, this);
    this.mouseControls = new MouseControls(domElement, this.cameraControls, this);
    this.keyboardControls = new KeyboardControls(this.cameraControls, this);
    this.touchControls = new TouchControls(domElement, this.cameraControls, this);
  }

  // TODO: consider moving this and the other picking stuff to Sphere (since it
  // is conceptually only on the Sphere, rather than the entire application).
  setCursor(event) {
    const point = {x: event.clientX, y: event.clientY};
    const {mesh} = this.raycastControls.getInteractableObjectAtScreenCoordinate(
      point.x,
      point.y,
      this.scene,
      this.camera.perspectiveCamera
    );
    const item = mesh ? mesh.userData.component : undefined;

    if (item === undefined) {
      this.canvas.classList.remove('has-cursor-pointer');
      if (this.hoveredItem !== undefined) {
        this.hoveredItem.onHoverLeave();
      }
    } else {
      if (this.hoveredItem === undefined) {
        this.canvas.classList.add('has-cursor-pointer');
        item.onHoverEnter();
      } else if (item !== this.hoveredItem) {
        this.canvas.classList.add('has-cursor-pointer');
        item.onHoverEnter();
        this.hoveredItem.onHoverLeave();
      }
    }

    this.hoveredItem = item;
  }

  private cameraDirection: THREE.Vector3 = new THREE.Vector3();
  getPlanogramCoordinates(x: number, y: number) {
    const origin: THREE.Vector3 = this.camera.perspectiveCamera.position;
    const normalizedCoords = normalizeMouse(x, y);
    this.cameraDirection
      .set(normalizedCoords.x, normalizedCoords.y, 0.5)
      .unproject(this.camera.perspectiveCamera)
      .sub(origin)
      .normalize();
    const intersection = this.intersectCalculator.findFarthestIntersect(origin, this.cameraDirection);
    // If there is no intersection we can't calculate the viewport, this
    // only occurs when using such high limits that the camera sees outside of the sphere
    if (!intersection) {
      return;
    }

    const coords = this.intersectCalculator.getPlanogramCoordinates(intersection.point);

    if (coords) {
      return [coords[0] * this.planogram.width, (coords[1] * this.planogram.height) / 2.0];
    }

    return [0, 0];
  }

  getDraggableElementAt(x, y) {
    const {mesh} = this.raycastControls.getInteractableObjectAtScreenCoordinate(
      x,
      y,
      this.scene,
      this.camera.perspectiveCamera
    );
    return mesh && mesh.userData?.component?.isDraggable() ? mesh.userData?.component : undefined;
  }

  getIntersectionWithElement(element, x, y) {
    return this.raycastControls.getIntersectionWithObjectAtScreenCoordinate(
      x,
      y,
      element.mesh,
      this.camera.perspectiveCamera
    );
  }

  private rendererSize = new THREE.Vector4();

  resizeCanvas() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.composer.setSize(window.innerWidth, window.innerHeight);
    this.passes.smaaPass.setSize(window.innerWidth, window.innerHeight);

    this.heatMapService.updateScreenSize(window.screen.width, window.screen.height);
    this.renderer.getCurrentViewport(this.rendererSize);
    if (this.inputHandler.isShowingOverlay()) {
      this.inputHandler.overlay.resize();
    }
    this.renderer.setRenderTarget(null);

    this.camera.updateAspect();
    this.camera.zoomIn(Camera.FIELD_OF_VIEW);
    this.camera.updateCamera();
    this.composer.render();
  }

  onLimitsChange({top = this.planogram.topLimit, bottom = this.planogram.bottomLimit}) {
    if (this.camera) {
      this.planogram.topLimit = top;
      this.planogram.bottomLimit = bottom;
      this.camera.updateMaxFOV();
      this.intersectCalculator.updatePlanogram();
    }
  }

  redirectToProduct(...data: any) {
    this.inputHandler.redirectToProduct.call(this.inputHandler, ...data);
  }

  _setupForegroundElements() {
    this.fullScreen = new Fullscreen(
      document.getElementById('fullscreen-button-list'),
      document.body,
      document,
      document.getElementById('main-container'),
      this.resizeCanvas.bind(this)
    );
  }

  private resetAutoplay() {
    this.inputHandler.resetAutoplay.call(this.inputHandler);
  }

  private resetNavigation() {
    this.inputHandler.resetNavigation.call(this.inputHandler);
  }

  private syncControlButtons() {
    const container = document.querySelector('#bottom-container .navigation-buttons-wrapper');
    SphereApp.removeControlButtons(container);
    container.classList.add(this.applyButtonAlignment());
    this.planogram.planogramVersionControlButtons.forEach((button, index) => {
      const element = document.createElement('div');
      const elementIcon = document.createElement('img');
      element.classList.add('navigation-button', 'button');
      const controlButtonUrl = button?.control_button?.url;

      if (button.show_shadow) {
        element.classList.add('with-shadow');
      }

      if (button.show_title && button.title) {
        const title = document.createElement('span');
        title.innerText = button.title;
        element.append(title);
        const fontSettings = {
          ...button.planogram_version_control_button_font,
          assignment: `navigation-button-${index + 1}`
        };
        FontLoader.mountCustomFont(fontSettings);
      }

      if (!this.planogram) {
        return;
      }

      if (!button || button.enabled === false || (button.navigation_type === 'back' && this.firstLoad)) {
        element.classList.add('app-control-button-disabled');
        return;
      }

      element.classList.remove('app-control-button-disabled');
      elementIcon.setAttribute('src', controlButtonUrl);
      elementIcon.onerror = () => {
        elementIcon.setAttribute('src', URLS.CONTROL_BUTTON_ICON_FALLBACK);
      };
      element.append(elementIcon);
      element.addEventListener('click', () => {
        Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.CLICK_CONTROL_BUTTON(button.title));
        switch (true) {
          case button.element_type === NAVIGATION_BUTTON_TYPE.SEARCH: {
            searchEventHandler.emit(SEARCH_EVENTS.SHOW_SEARCH, {isSearchActive: true});
            break;
          }

          case button.element_type === NAVIGATION_BUTTON_TYPE.MENU: {
            if (!button.menu) return;
            if (this.activeMenu === index) {
              this.activeMenu = undefined;
              document.getElementById('navigation-menu-container').classList.add('is-hidden');
              Router.navigateToPlanogram(this.planogram.name);
              return;
            }
            Router.navigateToNavigationMenu(this.planogram.name, index.toString());
            break;
          }

          default: {
            this.activeMenu = undefined;
            document.getElementById('navigation-menu-container').classList.add('is-hidden');
            this.handleControlButtonClick(button);
          }
        }
      });
      container.appendChild(element);
    });
  }

  private activeMenu: number;

  private showNavigationMenu(menu: PlanogramVersionControlButtonMenu) {
    const items = menu.menu_items;
    const pattern = [2, 3, 2, 3, 2];

    const container = document.getElementById('navigation-menu-container');
    container.classList.remove('is-hidden');

    container.addEventListener('click', () => {
      container.classList.add('is-hidden');
      this.activeMenu = undefined;
      Router.navigateToPlanogram(this.planogram.name);
    });

    HTMLUtils.removeChildElements(container);
    const innerContainer = document.createElement('div');
    innerContainer.className = 'inner-nav-menu-container';
    container.appendChild(innerContainer);

    let itemIndex = 0;

    while (itemIndex < items.length) {
      pattern.forEach(count => {
        const row = document.createElement('div');
        row.className = 'navigation-menu-row';
        innerContainer.appendChild(row);

        for (let i = 0; i < count; i++) {
          if (itemIndex >= items.length) {
            return;
          }

          const item = document.createElement('div');
          item.className = 'navigation-menu-item';
          this.appendItemContent(item, items[itemIndex], menu.show_shadow, itemIndex);
          itemIndex++;

          row.appendChild(item);
        }
      });
    }
  }

  private appendItemContent(
    element: HTMLDivElement,
    item: PlanogramVersionControlButtonMenuItem,
    showShdow: boolean,
    index: number
  ) {
    const itemIcon = document.createElement('div');
    itemIcon.className = 'navigation-menu-item-icon';
    const image = document.createElement('img');
    image.src = item.control_button.url;
    image.onclick = e => {
      e.stopPropagation();
      this.activeMenu = undefined;
      document.getElementById('navigation-menu-container').classList.add('is-hidden');
      Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.CLICK_MENU_BUTTON(item.title));
      this.handleControlButtonClick(item);
    };
    if (showShdow) {
      image.classList.add('with-shadow');
    }
    itemIcon.appendChild(image);
    element.appendChild(itemIcon);

    if (item.show_title && item.title) {
      const title = document.createElement('span');
      title.classList.add('navigation-menu-item-text');
      title.classList.add(`navigation-menu-item-text-${index + 1}`);
      const span = document.createElement('span');
      span.innerText = item.title;
      title.appendChild(span);
      element.append(title);
      const fontSettings: PlanogramFonts = {
        ...item.menu_item_font,
        assignment: `navigation-menu-item-text-${index + 1}`
      };
      FontLoader.mountCustomFont(fontSettings);
      element.appendChild(title);
    }
  }

  private static removeControlButtons(container: Element) {
    container.classList.remove(...Object.values(ALIGNMENT_TYPES));
    HTMLUtils.removeChildElements(container);
  }

  private applyButtonAlignment() {
    let classNames;
    switch (true) {
      case this.planogram.navigationDistributeEvenly: {
        classNames = ALIGNMENT_TYPES.DISTRIBUTE;
        break;
      }

      case this.planogram.navigationAlignment === ALIGNMENT_TYPES.LEFT: {
        classNames = ALIGNMENT_TYPES.LEFT;
        break;
      }

      case this.planogram.navigationAlignment === ALIGNMENT_TYPES.RIGHT: {
        classNames = ALIGNMENT_TYPES.RIGHT;
        break;
      }

      case this.planogram.navigationAlignment === ALIGNMENT_TYPES.MIDDLE: {
        classNames = ALIGNMENT_TYPES.MIDDLE;
        break;
      }

      default: {
        classNames = '';
      }
    }

    return classNames;
  }

  private handleControlButtonClick(controlButton) {
    if (controlButton?.navigation_value === this.planogram.name) {
      Router.navigateToPlanogram(this.planogram.name);
      return;
    }
    if (!this.isSphereLoaded) {
      return;
    }

    const formattedUrl =
      controlButton.navigation_value && controlButton.navigation_value.match(ITEM_IDENTIFIER_URL_REGEX);
    const lang = L10nUtils.getCurrentLanguage();
    const isInternalUrl = formattedUrl?.[0].includes(this.planogram.name) && formattedUrl?.[1] === lang;

    if (controlButton?.navigation_type === 'planogram' && !controlButton?.open_in_new_page) {
      this.isSphereLoaded = false;
    }
    this.cameraControls.clearAnimation();

    if (controlButton?.navigation_type === 'back') {
      if (this.previousPlanogram) {
        Router.navigateToPlanogram(this.previousPlanogram);
      }
    } else if (controlButton?.navigation_type === 'url' && isInternalUrl) {
      const parsedUrl = `/${controlButton.navigation_value.slice(
        controlButton.navigation_value.lastIndexOf(this.planogram.name)
      )}`;
      const identifier = parsedUrl.match(ITEM_IDENTIFIER_URL_REGEX)?.[4];
      const animate = new URL(controlButton.navigation_value).hash;

      if (animate && identifier) {
        Router.navigateToItemOnSphere(`/${this.planogram.name}/s${identifier}`, true);
      } else {
        Router.navigateToItemOnSphere(parsedUrl);
      }
    } else {
      if (controlButton?.navigation_type === 'planogram') {
        this.previousPlanogram = this.planogram.name;
      }
      WebUtils.applyConfiguredNavigation(controlButton);
    }
  }

  private syncLogoIcon() {
    const sphereLogoContainer = document.getElementById('sphere-logo');

    if (!this.planogram || !sphereLogoContainer) {
      return;
    }

    sphereLogoContainer.classList.add('app-logo-disabled');

    if (!this.planogram.planogramVersionLogo || this.planogram.planogramVersionLogo.enabled === false) {
      return;
    }

    const logoImgElement = sphereLogoContainer.querySelector('img');
    const logoUrl = this.planogram.planogramVersionLogo?.logo?.url;
    if (!logoUrl) {
      if (this.defaultLogoUrl) {
        logoImgElement.onload = () => {
          sphereLogoContainer.classList.remove('app-logo-disabled');
        };
        logoImgElement.setAttribute('src', this.defaultLogoUrl);
      }
      return;
    }
    const previousLogoUrl = logoImgElement.getAttribute('src');
    if (!this.defaultLogoUrl) {
      this.defaultLogoUrl = previousLogoUrl;
    }
    logoImgElement.onload = () => {
      sphereLogoContainer.classList.remove('app-logo-disabled');
    };
    logoImgElement.onerror = () => {
      logoImgElement.setAttribute('src', previousLogoUrl);
      sphereLogoContainer.classList.remove('app-logo-disabled');
    };
    logoImgElement.setAttribute('src', logoUrl);
  }

  private syncOverlayAndControlColors() {
    const root = document.documentElement;
    const primaryColorRgb = AppUtils.hex2rgb(this.planogram.primaryColor);
    const secondaryColorRgb = AppUtils.hex2rgb(this.planogram.secondaryColor);

    root.style.setProperty(`--overlay-primary-color`, `${this.planogram.primaryColor}`);
    root.style.setProperty(`--overlay-secondary-color`, `${this.planogram.secondaryColor}`);
    root.style.setProperty(`--overlay-primary-color-rgb`, `${primaryColorRgb}`);
    root.style.setProperty(`--overlay-secondary-color-rgb`, `${secondaryColorRgb}`);
    root.style.setProperty(`--iframe-primary-color`, `${this.planogram.iframePrimaryColor}`);
    if (this.planogram.iframeSecondaryColor) {
      root.style.setProperty(`--iframe-secondary-color`, `${this.planogram.iframeSecondaryColor}`);
    }
  }

  private syncSocialMediaOverlayAndControlColors() {
    const root = document.documentElement;

    this.planogram.clientSocialMedias?.forEach(media => {
      const mediaName = media.social_media_title.toLowerCase();

      if (media.close_bg_color) {
        root.style.setProperty(`--social-media-${mediaName}-close-bg-color`, media.close_bg_color);
      }

      media.hide_overlay_container
        ? root.style.setProperty(`--social-media-${mediaName}-bg-color`, 'transparent')
        : root.style.setProperty(`--social-media-${mediaName}-bg-color`, media.container_color);
      media.scroll_container_fill
        ? root.style.setProperty(`--social-media-${mediaName}-scroll-container-color`, media.scroll_container_fill)
        : root.style.setProperty(`--social-media-${mediaName}-scroll-container-color`, 'transparent');
      // transparency equal to 30% for hex value is '4d/4D'
      // reference to get opacity value for hex colors: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
      root.style.setProperty(`--social-media-${mediaName}-scroll-indicator-color`, media.scroll_indicator_fill + '4d');
      root.style.setProperty(`--social-media-${mediaName}-close-icon-color`, media.close_icon_color);
    });
  }

  private handleLogoClick() {
    if (!this.planogram.planogramVersionLogo || !this.planogram.planogramVersionLogo.enabled) {
      Router.navigateToPlanogram(PLANOGRAM_NAME.MAIN);
      return;
    }

    WebUtils.applyConfiguredNavigation(this.planogram.planogramVersionLogo);
  }

  private handleLanguageChanged() {
    if (this.controlButton) {
      this.controlButton.setAttribute('alt', L10nUtils.l10n('control-button.alt-text'));
    }
    const sphereLogoIconElement = document.getElementById('sphere-logo').querySelector('img');
    if (sphereLogoIconElement) {
      sphereLogoIconElement.setAttribute('alt', L10nUtils.l10n('logo-button.alt-text'));
    }
  }

  private initCurrencySwitcher() {
    const {ecommercePlatformCurrency, ecommercePlatformCurrencies} = this.planogram;
    this.currencyService = new CurrencyService(ecommercePlatformCurrencies, ecommercePlatformCurrency);
    this.currencySwitcherControl = new CurrencySwitcher(this.currencyService);
  }

  private initShoppingCart() {
    this.shoppingCart?.button.remove();
    this.shoppingCart?.panel.remove();
    this.shoppingCartService = new ShoppingCartService(this.planogram, this.currencyService);
    this.shoppingCartService.checkMultipassToken().then(data => {
      const buttonSettings = AppUtils.isProfileAndCartIconsEnabled(
        this.planogram.ecommerceOverlaySettingsButtons.ecommerce_icon_buttons
      );
      this.shoppingCartService.storeEmail(data);
      if (buttonSettings[ECOMMERCE_BUTTON_TYPE.USER_PROFILE]) {
        this.account = new Account(this);
      }
      if (buttonSettings[ECOMMERCE_BUTTON_TYPE.SHOPPING_CART]) {
        this.shoppingCart = new ShoppingCartComponent(this, this.shoppingCartService, this.currencyService);
        document.getElementById('shopping-cart').append(this.shoppingCart.button);
      }
      this.shoppingCartService.updateShoppingCartState(true);
    });
  }
}
