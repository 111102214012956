import * as THREE from 'three';
import {Box3, Vector3} from 'three';
import {Planogram} from '../planogram';
import {SphereGeometry} from './sphere_geometry';

const Z_VECTOR_3 = new THREE.Vector3(0, 0, 1);

export class CurveGeometry extends THREE.BufferGeometry {
  type: string;
  curveLength: number;

  private parameters: any;

  private vertices: Float32Array;
  private indices: Array<number>;
  private uvs: Array<number>;

  private points: number[];
  private thickness: number;
  private scaleParameter: [number, number, number];
  private objectX: number;
  private objectY: number;
  private largeRadius: number;
  private fixedRadius: number;
  private planogramHeight: number;
  private planogramWidth: number;

  private midVertex: THREE.Vector3;
  private midNormalVertex: THREE.Vector3;

  constructor(
    points: number[],
    thickness: number,
    scale: [number, number, number],
    objectX: number,
    objectY: number,
    largeRadius: number,
    fixedRadius: number,
    planogramHeight: number,
    planogramWidth: number
  ) {
    super();

    this.type = 'CurveGeometry';
    this.parameters = {
      points,
      thickness,
      scale,
      objectX,
      objectY,
      largeRadius,
      fixedRadius,
      planogramHeight,
      planogramWidth
    };

    this.setDefaultsAndLimits();
    this.calculateGeometryAttributes();
    this.buildGeometry();
    this.calculateMidPoint();
  }

  dispose() {
    this.indices = null;
    this.vertices = null;
    super.dispose();
  }

  setDefaultsAndLimits() {
    this.points = this.parameters.points;
    this.thickness = this.parameters.thickness;
    this.scaleParameter = this.parameters.scale;
    this.objectX = this.parameters.objectX;
    this.objectY = this.parameters.objectY;
    this.largeRadius = this.parameters.largeRadius;
    this.fixedRadius = this.parameters.fixedRadius;
    this.planogramHeight = this.parameters.planogramHeight;
    this.planogramWidth = this.parameters.planogramWidth;
  }

  calculateMidPoint() {
    this.computeBoundingBox();

    this.midVertex = new Vector3();
    this.boundingBox.getCenter(this.midVertex);
    
    SphereGeometry.setMidVertexToNonZero(this.midVertex);
    
    this.midNormalVertex = new Vector3().copy(this.midVertex).multiplyScalar(-1).normalize();
  }

  calculateGeometryAttributes(levelOfDetail: number = 3) {
    const curvePath = CurveGeometry.curvePathFromPoints(this.points, this.scaleParameter);

    const vertices2D: number[] = [];
    const index: number[] = [];
    const uvs: number[] = [];

    const normal = new THREE.Vector3();
    let indexOffset: number;

    let cumulativeCurveLength = 0;
    let cumulativeDivisionCount = 0;

    let curve: THREE.Curve<THREE.Vector3>;
    let curveLength: number;
    let divisions: number;
    let curveLengths: number[];
    let curvePoints: THREE.Vector3[];

    for (let curveIndex = 0; curveIndex < curvePath.curves.length; curveIndex++) {
      curve = curvePath.curves[curveIndex];
      curveLength = curve.getLength();

      divisions = Math.round((curveLength / 25) * levelOfDetail);
      curve.arcLengthDivisions = divisions;

      curveLengths = curve.getLengths();
      curvePoints = curve.getPoints(divisions);

      curvePoints.forEach((curvePoint, pointIndex) => {
        curvePoint = curvePoints[pointIndex];

        curve.getTangent(pointIndex / (curvePoints.length - 1), normal);
        normal.applyAxisAngle(Z_VECTOR_3, Math.PI / 2).multiplyScalar(this.thickness);

        vertices2D.push(curvePoint.x + normal.x, curvePoint.y + normal.y);
        vertices2D.push(curvePoint.x - normal.x, curvePoint.y - normal.y);

        uvs.push(
          cumulativeCurveLength + curveLengths[pointIndex],
          1,
          cumulativeCurveLength + curveLengths[pointIndex],
          -1
        );

        indexOffset = (pointIndex - 1) * 2 + cumulativeDivisionCount * 2;

        if (indexOffset >= 0) {
          index.push(
            0 + indexOffset,
            1 + indexOffset,
            2 + indexOffset,
            1 + indexOffset,
            3 + indexOffset,
            2 + indexOffset
          );
        }
      });

      cumulativeCurveLength += curveLength > 0 ? curveLength : 0;
      cumulativeDivisionCount += divisions + 1;
    }

    this.vertices = this.projectPointsToSphere(vertices2D);
    this.uvs = uvs;
    this.indices = index;
    this.curveLength = cumulativeCurveLength;
  }

  static curvePathFromPoints(points: number[], scale: [number, number, number]): THREE.CurvePath<THREE.Vector3> {
    const curve = new THREE.CurvePath<THREE.Vector3>();

    for (let i = 0; i < points.length - 2; i += 6) {
      const curveSection = new THREE.CubicBezierCurve3(
        new THREE.Vector3(points[i] * scale[0], points[i + 1] * scale[1], 0),
        new THREE.Vector3(points[i + 2] * scale[0], points[i + 3] * scale[1], 0),
        new THREE.Vector3(points[i + 4] * scale[0], points[i + 5] * scale[1], 0),
        new THREE.Vector3(points[i + 6] * scale[0], points[i + 7] * scale[1], 0)
      );

      curve.add(curveSection);
    }

    return curve;
  }

  projectPointsToSphere(points2D: number[]): Float32Array {
    const spherePoints = new Float32Array((points2D.length / 2) * 3);

    const surfaceLength = SphereGeometry.calcTopToBottomSurfaceLength(
      Planogram.ALPHA,
      this.largeRadius,
      this.fixedRadius
    );
    const heightAdjustment = (surfaceLength - this.planogramHeight) / 2 + this.objectY;
    const rotationVector = new THREE.Vector3(0, 1, 0);
    const pointVector = new THREE.Vector3(0, 0, 0);

    let yDistance: number, rotationAngle: number;

    for (let i = 0; i < points2D.length / 2; i++) {
      yDistance = points2D[i * 2 + 1] + heightAdjustment;
      rotationAngle =
        SphereGeometry.calcAzimuthStartRadians(points2D[i * 2] + this.objectX, 0, this.planogramWidth) -
        SphereGeometry.ROTATION_OFFSET;
      const intersect = SphereGeometry.calcSpherePoint(
        yDistance,
        surfaceLength,
        Planogram.ALPHA,
        this.largeRadius,
        this.fixedRadius
      );
      const adjustedIntersect = SphereGeometry.distortionAdjustment(
        intersect,
        yDistance,
        surfaceLength,
        Planogram.ALPHA,
        this.largeRadius,
        this.fixedRadius
      );

      pointVector.setX(adjustedIntersect.point[0]);
      pointVector.setY(adjustedIntersect.point[1]);
      pointVector.setZ(0);
      pointVector.applyAxisAngle(rotationVector, rotationAngle);

      spherePoints[i * 3] = pointVector.x;
      spherePoints[i * 3 + 1] = pointVector.y;
      spherePoints[i * 3 + 2] = pointVector.z;
    }

    return spherePoints;
  }

  buildGeometry() {
    this.setIndex(this.indices);
    this.setAttribute('position', new THREE.BufferAttribute(this.vertices, 3));
    this.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(this.uvs), 2));
  }
}
