import {Camera} from 'three';
import {VideoComponent} from '../../components/video';
import {
  Action,
  ACTION_TYPE,
  AnimateActionData,
  ClusterMetaData,
  LinkActionData,
  TextMetaData
} from '../../interfaces/planogram.interface';
import {SPHERE_ITEM_TYPES} from '../../shared/constants';
import {SphereApp} from '../../sphere_app';
import {SphereItem} from '../../sphere_item';
import {AccessibilityService} from '../accessibility_service';
import {SphereSceneHTMLData} from '../interfaces';

export class ARIA {
  private accessibilityHTMLElement: HTMLElement;
  private itemListHTMLElements: {
    container: HTMLElement;
    list: HTMLElement;
    nextPageButton: HTMLElement;
    previousPageButton: HTMLElement;
    pageIndicator: HTMLElement;
    firstPageButton: HTMLElement;
    enterSphereButton: HTMLElement;
  };
  private sphereItemsHTMLElement: HTMLElement;
  private mainContainer: HTMLElement;
  private itemList: Array<SphereItem> = [];
  private itemListPage = 0;
  private itemListPageSize = 10;
  private itemListPageCount = 0;

  constructor(
    private accessiblityService: AccessibilityService,
    sphereData: SphereSceneHTMLData,
    itemList: Array<SphereItem>
  ) {
    this.accessibilityHTMLElement = document.getElementById('accessibility');
    this.mainContainer = document.getElementById('main-container');
    this.sphereItemsHTMLElement = document.getElementById('sphere-items');
    this.itemList = itemList;
    this.sphereScene(sphereData);
    this.setupItemsList();
  }

  /**
   * Assings the click actions for controlling the item list and generates the html from the item list
   */
  setupItemsList() {
    this.itemListHTMLElements = {
      container: document.getElementById('item-list-container'),
      list: document.getElementById('item-list'),
      nextPageButton: document.getElementById('item-list-next-page'),
      previousPageButton: document.getElementById('item-list-previous-page'),
      pageIndicator: document.getElementById('item-list-page-indicator'),
      firstPageButton: document.getElementById('item-list-first-page'),
      enterSphereButton: document.getElementById('item-list-enter-sphere')
    };

    this.itemListPageCount = Math.ceil(this.itemList.length / this.itemListPageSize);

    this.itemListHTMLElements.previousPageButton.onclick = () => {
      this.itemListPage--;
      this.setItemList();
    };

    this.itemListHTMLElements.nextPageButton.onclick = () => {
      this.itemListPage = Math.min(this.itemListPage + 1, this.itemListPageCount);
      this.setItemList();

      (this.itemListHTMLElements.list.children[0] as HTMLElement).focus();
    };

    this.itemListHTMLElements.firstPageButton.onclick = () => {
      this.itemListPage = 0;
      this.setItemList();
      (this.itemListHTMLElements.list.children[0] as HTMLElement)?.focus();
    };

    this.itemListHTMLElements.enterSphereButton.onclick = () => {
      this.accessiblityService.navigateToFirstItem();
    };
    this.setItemList();
  }

  /**
   * Generates the HTML element for a SphereItem
   */
  generateItemHTML(item: SphereItem, assertive: boolean = false) {
    if (!item.htmlElement?.container) {
      return;
    }

    switch (item.type) {
      case SPHERE_ITEM_TYPES.CLUSTER:
        item.htmlElement.info.innerHTML = `
        <p>Group name ${
          item.data.accessibility?.title || item.data?.name || (item.data as ClusterMetaData).clusterLink
        }</p>
        <p>${item.data.accessibility?.description || 'No description available'}</p>
        `;

        // If the cluster has no children we can't enter the group
        if (item.itemData.childrenIds?.length) {
          const enterButton = this.createButton('Enter group', () => {
            this.accessiblityService.toggleGroupNavigation(item, true);
            this.accessiblityService.navigateToGroup(item);
          });
          const skipButton = this.createButton('Skip group', () => {
            this.accessiblityService.toggleGroupNavigation(item, false);
            this.accessiblityService.navigateToNextItem(item);
          });
          item.htmlElement.container.append(enterButton, skipButton);
        }
        break;
      case SPHERE_ITEM_TYPES.VIDEO:
        item.htmlElement.info.innerHTML = `<p>${item.type}</p>
          <p>${item.data.accessibility?.title || item.name || item.data.name}</p>
          <p>${item.data.accessibility?.description || 'No description available'}</p>`;
        const playVideoButton: HTMLElement = this.createButton('Play video', () => {
          (item as VideoComponent)?.play();
        });
        const stopVideoButton: HTMLElement = this.createButton('Stop video', () => {
          (item as VideoComponent)?.pause();
        });
        item.htmlElement.container.append(playVideoButton, stopVideoButton);
        if (item.itemData.parent) {
          if (this.accessiblityService.isLastItem(item)) {
            const repeatButton = this.createButton('Repeat group', () =>
              this.accessiblityService.repeatParentNavigation(item)
            );
            item.htmlElement.container.append(repeatButton);
          }
        }
        break;
      case SPHERE_ITEM_TYPES.IMAGE:
      case SPHERE_ITEM_TYPES.PRODUCT:
      case SPHERE_ITEM_TYPES.TEXT:
      case SPHERE_ITEM_TYPES.TEXT_AREA:
        item.htmlElement.info.innerHTML = `<p>${item.type}</p>
          <p>${
            item.data.accessibility?.title ||
            item.data.product?.name ||
            (item.data as ClusterMetaData).clusterLink ||
            item.name ||
            item.data.name
          }</p>
          <p>${
            item.data.accessibility?.description || (item.data as TextMetaData).text || 'No description available'
          }</p>`;
        if (item.action) {
          const actionButton: HTMLElement = this.createButton(this.actionButtonText(item.action), () => {
            this.accessiblityService.action(item);
          });
          item.htmlElement.container.append(actionButton);
        }
        if (item.itemData.parent) {
          if (this.accessiblityService.isLastItem(item)) {
            const repeatButton = this.createButton('Repeat group', () =>
              this.accessiblityService.repeatParentNavigation(item)
            );
            item.htmlElement.container.append(repeatButton);
          }
        }
        break;
    }
  }

  /**
   * Generate the action button text/description
   */
  actionButtonText(action: Action): string {
    switch (action.type) {
      case ACTION_TYPE.PRODUCT_OVERLAY:
        return 'Open product';
      case ACTION_TYPE.INFO:
        return 'Read information';
      case ACTION_TYPE.ABOUT_US:
        return 'Read about us';
      case ACTION_TYPE.COPYRIGHT:
        return 'Read copyright';
      case ACTION_TYPE.PRIVACY_POLICY:
        return 'Read privacy policy';
      case ACTION_TYPE.CONTACT_US:
        return 'Read contact us';
      case ACTION_TYPE.LINK:
        return `Go to ${(action.data as LinkActionData).url}`;
      case ACTION_TYPE.IFRAME:
        return 'Open link';
      case ACTION_TYPE.IMAGE:
        return 'Open image';
      case ACTION_TYPE.VIDEO_OVERLAY:
        return 'Open video';
      case ACTION_TYPE.ANIMATE:
        return `Go to ${(action.data as AnimateActionData).itemType}${
          (action.data as AnimateActionData).sphereName
            ? `on sphere ${(action.data as AnimateActionData).sphereName}`
            : ''
        }`;
      default:
        return '';
    }
  }

  /**
   * Generates the start of the flow, this includes the sphere description, keyboard instructions and continue button
   */
  sphereScene(data: SphereSceneHTMLData) {
    const sphereDescription = document.getElementById('sphere-description');
    sphereDescription.innerHTML = `<h1>${`${data.title || 'Unnamed sphere'}`}</h1> 
    <h3>${data.description || 'No description available'}</h3>`;

    const continueButton = this.createButton('Continue', () => {
      AccessibilityService.isActive = true;
      this.setAccessiblityHTMLElement();
      const keyboardControlsDescription = document.createElement('p');
      keyboardControlsDescription.textContent = 'Press ALT+1 to read the list of items, press ALT+2 to enter Sphere';
      this.accessibilityHTMLElement.append(keyboardControlsDescription);
      this.generateSphereItemsHTML(this.itemList);
    });
    this.accessibilityHTMLElement.append(continueButton);
    continueButton.focus();
  }

  generateSphereItemsHTML(items: Array<SphereItem>) {
    items.forEach(item => {
      if (!item.itemData.parentId) {
        this.sphereItemsHTMLElement.append(item.htmlElement?.container);
        if (item.itemData.childrenIds) {
          item.itemData.childrenIds.forEach(id => {
            const child = this.accessiblityService.items.get(id.toString());
            if (child) {
              if (child.htmlElement) {
                this.sphereItemsHTMLElement.append(child.htmlElement.container);
              }
            }
          });
        }
        this.accessiblityService.toggleGroupNavigation(item, false);
      }
    });

    this.sphereItemsHTMLElement.append(
      this.createButton('Navigate to start', () => {
        this.focusOnSphereList();
      })
    );
  }

  setItemList() {
    // Clear the list
    this.clearHTMLElement(this.itemListHTMLElements.list);

    this.itemList
      .slice(this.itemListPage * this.itemListPageSize, (this.itemListPage + 1) * this.itemListPageSize)
      .forEach(item => {
        const itemButton = this.createButton(
          `${item.type} ${
            item.data.accessibility?.title || item.name || item.data?.name || (item.data as ClusterMetaData).clusterLink
          }`,
          () => this.accessiblityService.navigateToItemById(item.id.toString())
        );
        this.itemListHTMLElements.list.append(itemButton);
      });

    if (this.itemListPage > 0) {
      this.itemListHTMLElements.previousPageButton.removeAttribute('aria-hidden');
      this.itemListHTMLElements.previousPageButton.tabIndex = 0;
    } else {
      this.itemListHTMLElements.previousPageButton.setAttribute('aria-hidden', 'true');
      this.itemListHTMLElements.previousPageButton.tabIndex = -1;
    }
    if (this.itemListPage < this.itemListPageCount - 1) {
      this.itemListHTMLElements.nextPageButton.removeAttribute('aria-hidden');
      this.itemListHTMLElements.nextPageButton.tabIndex = 0;
    } else {
      this.itemListHTMLElements.nextPageButton.setAttribute('aria-hidden', 'true');
      this.itemListHTMLElements.nextPageButton.tabIndex = -1;
    }

    if (this.itemListPage === this.itemListPageCount - 1) {
      this.itemListHTMLElements.firstPageButton.removeAttribute('aria-hidden');
      this.itemListHTMLElements.firstPageButton.tabIndex = 0;

      this.itemListHTMLElements.enterSphereButton.removeAttribute('aria-hidden');
      this.itemListHTMLElements.enterSphereButton.tabIndex = 0;
    } else {
      this.itemListHTMLElements.firstPageButton.setAttribute('aria-hidden', 'true');
      this.itemListHTMLElements.firstPageButton.tabIndex = -1;

      this.itemListHTMLElements.enterSphereButton.setAttribute('aria-hidden', 'true');
      this.itemListHTMLElements.enterSphereButton.tabIndex = -1;
    }
    this.itemListHTMLElements.pageIndicator.textContent = `Page ${this.itemListPage + 1} of ${this.itemListPageCount}`;
  }

  focusOnSphereList() {
    this.mainContainer.insertBefore(this.sphereItemsHTMLElement, this.itemListHTMLElements.container);
    this.accessiblityService.navigateToFirstItem();
  }

  focusOnItemList() {
    this.itemListPage = 0;
    this.mainContainer.insertBefore(this.itemListHTMLElements.container, this.sphereItemsHTMLElement);
    (this.itemListHTMLElements.list.children[0] as HTMLElement)?.focus();
  }

  setAccessiblityHTMLElement(assertive: boolean = false) {
    this.clearHTMLElement(this.accessibilityHTMLElement);
    this.accessibilityHTMLElement.setAttribute('aria-live', assertive ? 'assertive' : 'polite');
  }

  createButton(text: string, onclick?: (this: GlobalEventHandlers, ev: MouseEvent) => any) {
    const button: HTMLElement = document.createElement('button');
    button.innerText = text;
    if (onclick) {
      button.onclick = onclick;
    }
    return button;
  }

  // Removes all the content from the HTMLElement
  clearHTMLElement(element: HTMLElement) {
    if (!element.childNodes.length) {
      return;
    }
    for (const index in element.childNodes) {
      const child = element.childNodes[index] as HTMLElement;
      if (child.hasChildNodes && child.hasChildNodes()) {
        this.clearHTMLElement(child);
      }
      if (child.onclick) {
        child.onclick = undefined;
      }
      if (child.remove) {
        child.remove();
      }
    }
    element.innerHTML = '';
  }

  dispose() {
    this.clearHTMLElement(this.accessibilityHTMLElement);
    this.clearHTMLElement(this.sphereItemsHTMLElement);
    this.clearHTMLElement(this.itemListHTMLElements.list);
    this.itemListHTMLElements.previousPageButton.onclick = undefined;
    this.itemListHTMLElements.nextPageButton.onclick = undefined;
    this.itemListHTMLElements.firstPageButton.onclick = undefined;
    this.itemListHTMLElements.enterSphereButton.onclick = undefined;
    this.itemList = undefined;
    this.accessiblityService = undefined;
  }
}
