import * as THREE from 'three';
import {PerspectiveCamera} from 'three';
import {ArrayUtils} from '../utils/array_utils';
import {Animation} from '../animations/animation';
import {AutoRotateAnimation} from '../animations/auto_rotate_animation';
import {MomentumAnimation} from '../animations/momentum_animation';
import {ZoomToAnimation} from '../animations/zoom_to_animation';
import {RotationUtils} from '../utils/rotation_utils';
import {SphereApp} from '../sphere_app';
import {Camera} from '../camera';
import {CookiesManagement} from '../cookies_management';
import {IntersectCalculator, Intersection} from '../intersect_calculator';
import {SPHERE_EVENT_NAMES as EVENTS} from '../event-names';
import {sphereEventHandler} from '../custom_event_utils';

export class CameraControls {
  private animationChain: Set<Animation>;
  private angularSpeedsX: Array<number>;
  private angularSpeedsY: Array<number>;
  private lastUpdatedAt: number;

  private pointerCameraState: PerspectiveCamera;
  private pointerIntersect: Intersection;

  private cameraDistance: number;
  private sphereApp: SphereApp;
  private clusterMidPoint: THREE.Vector3;
  private storedClusterNormal: THREE.Vector3;

  static get ROTATE_LEFT() {
    return 1;
  }

  static get ROTATE_RIGHT() {
    return -1;
  }

  static get TILT_UP() {
    return 1;
  }

  static get TILT_DOWN() {
    return -1;
  }

  static get MAX_SPEED_ELEMENT_COUNT() {
    return 5;
  }

  static limitSpeed(speed) {
    const MAXMUM_MOMENTUM_START_SPEED = 0.05;

    if (Math.abs(speed) > MAXMUM_MOMENTUM_START_SPEED) {
      return undefined;
    }

    return speed;
  }

  constructor(private camera: Camera, private intersectCalculator: IntersectCalculator, sphereApp: SphereApp) {
    this.sphereApp = sphereApp;
    this.clusterMidPoint = new THREE.Vector3();
    this.storedClusterNormal = new THREE.Vector3();
    this.animationChain = new Set();
    this.angularSpeedsX = [];
    this.angularSpeedsY = [];

    sphereEventHandler.listen(EVENTS.ENTRANCE_ANIMATION.ZOOM_ANIMATION, this.entranceAnimationZoom.bind(this));
    sphereEventHandler.listen(EVENTS.ENTRANCE_ANIMATION.DRAG_ANIMATION, this.entranceAnimationDrag.bind(this));
  }

  currentZoomFraction() {
    return this.camera.currentZoomFraction();
  }

  autoRotate(direction) {
    const autoRotate = AutoRotateAnimation.instance;
    autoRotate.addRotation(direction, this.currentZoomFraction());
    if (autoRotate.isNotRotating()) {
      this.stopAutoRotate();
    } else {
      this.addAnimation(autoRotate);
    }
    sphereEventHandler.emit(EVENTS.AUTOROTATE, {direction}); // R & L Keys
  }

  stopAutoRotate() {
    const autoRotate = AutoRotateAnimation.instance;
    autoRotate.clearRotation();
    this.removeAnimation(autoRotate);
  }

  zoomBy(zoomFactor) {
    this.camera.zoomBy(zoomFactor);
  }

  animateZoomFov() {
    if (!this.sphereApp.planogram.clustersOrder) {
      return;
    }
    const currentCoord = this.intersectCalculator.findFarthestIntersect(this.camera.position, this.camera.direction);
    const clusterHorMid = new THREE.Vector3(this.clusterMidPoint.x, 1, this.clusterMidPoint.z);
    const fullItemFOV = this.camera.clampFOV(Camera.FIELD_OF_VIEW);
    const intersect = this.intersectCalculator.findFarthestIntersect(clusterHorMid, this.storedClusterNormal);
    const animation = new ZoomToAnimation(
      this.camera.fov(),
      currentCoord,
      fullItemFOV,
      intersect.point,
      undefined,
      1,
      0,
      this.sphereApp.planogram.animationSettings.duration,
      this.sphereApp.planogram.animationSettings.transition_type,
      this.sphereApp.planogram.animationSettings.pan_before_zoom
    );
    this.addAnimation(animation);
    sphereEventHandler.emit(EVENTS.ANIMATE_ZOOM_FOV); // Bottom Key
    return animation;
  }

  entranceAnimationZoom() {
    let currentCoord = this.intersectCalculator.findFarthestIntersect(this.camera.position, this.camera.direction);
    const endCallback = () => {
      currentCoord = this.intersectCalculator.findFarthestIntersect(this.camera.position, this.camera.direction);
      const fullItemFOV = this.camera.clampFOV(Camera.FIELD_OF_VIEW);
      const zoomOutAnimation = new ZoomToAnimation(
        this.camera.fov(),
        currentCoord,
        fullItemFOV,
        currentCoord.point,
        undefined,
        1,
        0,
        this.sphereApp.planogram.animationSettings.duration,
        this.sphereApp.planogram.animationSettings.transition_type,
        this.sphereApp.planogram.animationSettings.pan_before_zoom
      );
      this.addAnimation(zoomOutAnimation);
    };
    const zoomInAnimation = new ZoomToAnimation(
      this.camera.fov(),
      currentCoord,
      Camera.ENTRANCE_ANIMATION_ZOOM_IN_FOV,
      currentCoord.point,
      () => endCallback(),
      1,
      0,
      this.sphereApp.planogram.animationSettings.duration,
      this.sphereApp.planogram.animationSettings.transition_type,
      this.sphereApp.planogram.animationSettings.pan_before_zoom
    );
    this.addAnimation(zoomInAnimation);
  }

  entranceAnimationDrag() {
    let currentCoord = this.intersectCalculator.findFarthestIntersect(this.camera.position, this.camera.direction);
    const finalDestination = new THREE.Vector3().copy(currentCoord.point);
    finalDestination.x -= Camera.ENTRANCE_ANIMATION_DRAG_VALUE;
    const fullItemFOV = this.camera.clampFOV(this.camera.fov());
    const dragLeftAnimationCallback = () => {
      currentCoord = this.intersectCalculator.findFarthestIntersect(this.camera.position, this.camera.direction);
      finalDestination.x += 2 * Camera.ENTRANCE_ANIMATION_DRAG_VALUE;
      const dragToInitialPositionCallback = () => {
        currentCoord = this.intersectCalculator.findFarthestIntersect(this.camera.position, this.camera.direction);
        finalDestination.x -= Camera.ENTRANCE_ANIMATION_DRAG_VALUE;
        const dragToInitialPosition = new ZoomToAnimation(
          this.camera.fov(),
          currentCoord,
          fullItemFOV,
          finalDestination,
          undefined,
          1,
          0,
          this.sphereApp.planogram.animationSettings.duration * 0.5,
          this.sphereApp.planogram.animationSettings.transition_type,
          this.sphereApp.planogram.animationSettings.pan_before_zoom
        );
        this.addAnimation(dragToInitialPosition);
      };
      const dragLeftAnimation = new ZoomToAnimation(
        this.camera.fov(),
        currentCoord,
        fullItemFOV,
        finalDestination,
        () => dragToInitialPositionCallback(),
        1,
        0,
        this.sphereApp.planogram.animationSettings.duration,
        this.sphereApp.planogram.animationSettings.transition_type,
        this.sphereApp.planogram.animationSettings.pan_before_zoom
      );
      this.addAnimation(dragLeftAnimation);
    };
    const dragRightAnimation = new ZoomToAnimation(
      this.camera.fov(),
      currentCoord,
      fullItemFOV,
      finalDestination,
      () => dragLeftAnimationCallback(),
      1,
      0,
      this.sphereApp.planogram.animationSettings.duration * 0.5,
      this.sphereApp.planogram.animationSettings.transition_type,
      this.sphereApp.planogram.animationSettings.pan_before_zoom
    );
    this.addAnimation(dragRightAnimation);
  }

  zoomToPoint(newPoint, zoomScaleFactor) {
    this._moveCameraToFlattenView();
    let previousIntersect = this.pointerIntersect;
    if (!previousIntersect) {
      previousIntersect = this.intersectCalculator.findFarthestMouseIntersect(
        newPoint.x,
        newPoint.y,
        this.camera.perspectiveCamera
      );
    }

    const oldZoomLevel = this.camera.currentZoomFraction();

    this.camera.zoomBy(zoomScaleFactor);
    this.camera.updateCamera();

    this.sphereApp.heatMapService.sendZoomEvent(
      newPoint.x,
      newPoint.y,
      oldZoomLevel,
      this.camera.currentZoomFraction()
    );

    const newIntersect = this.intersectCalculator.findFarthestMouseIntersect(
      newPoint.x,
      newPoint.y,
      this.camera.perspectiveCamera
    );
    this._tiltAndPanBetween(newIntersect, previousIntersect);
    sphereEventHandler.emit(EVENTS.CAMERA.ZOOM_TO_POINT, {
      newIntersect,
      previousIntersect
    }); // zoom (mouse wheel, pinch)
  }

  onMovementStart(pointer) {
    this.lastUpdatedAt = Date.now();
    this.pointerIntersect = this.intersectCalculator.findFarthestMouseIntersect(
      pointer.x,
      pointer.y,
      this.camera.perspectiveCamera
    );
    this.pointerCameraState = this.camera.perspectiveCamera.clone();
    sphereEventHandler.emit(EVENTS.ON_MOVEMENT_START, {pointer}); // mouse click
  }

  tiltAndPanToSpherePoint(spherePoint) {
    const midIntersect = this.intersectCalculator.findFarthestIntersect(this.camera.position, this.camera.direction);
    this._tiltAndPanBetween(midIntersect, spherePoint);
  }

  tiltAndPanTo(pointer) {
    const newIntersect = this.intersectCalculator.findFarthestMouseIntersect(
      pointer.x,
      pointer.y,
      this.pointerCameraState
    );
    this._tiltAndPanBetween(newIntersect, this.pointerIntersect);
    this.pointerIntersect = newIntersect;
    this.lastUpdatedAt = Date.now();
    sphereEventHandler.emit(EVENTS.TILT_AND_PAN_TO, {pointer}); // mouse pan
  }

  tiltAndPanBy(adjustment) {
    this.camera.tiltAndPanBy(adjustment);
    this._moveCameraToFlattenView();
    this.camera.updateCamera();
  }

  onMovementEnd() {
    if (this._isDragging()) {
      const avgAngularSpeedsX = ArrayUtils.average(this.angularSpeedsX);

      this.sphereApp.heatMapService.sendMoveEvent(
        window.innerWidth / 2,
        window.innerHeight / 2,
        this.camera.currentZoomFraction(),
        avgAngularSpeedsX < 0 ? 'right' : 'left'
      );

      this.addAnimation(new MomentumAnimation(avgAngularSpeedsX, this.lastUpdatedAt, MomentumAnimation.X_AXIS));
      this.addAnimation(
        new MomentumAnimation(ArrayUtils.average(this.angularSpeedsY), this.lastUpdatedAt, MomentumAnimation.Y_AXIS)
      );
    }
    this.angularSpeedsX = [];
    this.angularSpeedsY = [];
    this.lastUpdatedAt = undefined;
    this.pointerIntersect = undefined;
  }

  addAnimation(animation) {
    this.animationChain.add(animation);
  }

  removeAnimation(animation) {
    this.animationChain.delete(animation);
  }

  updateAnimations() {
    Array.from(this.animationChain.values()).forEach(animation => {
      const adjustment = animation.getAdjustment();
      if (!adjustment) {
        this.removeAnimation(animation);
      } else if (adjustment.fov) {
        this.camera.update(adjustment);
        this.tiltAndPanToSpherePoint(adjustment.targetPoint);
      } else {
        this.camera.update(adjustment);
      }
    });
  }

  clearAnimation(isSphereRotate?) {
    if (CookiesManagement.isRedirectAnimationProcessing && isSphereRotate) {
      setTimeout(() => CookiesManagement.init(), 0);
      CookiesManagement.isRedirectAnimationProcessing = false;
    }

    AutoRotateAnimation.clearInstance();
    this.animationChain.clear();
  }

  static getItemSize(arr) {
    return arr.indexOf(Math.max(...arr));
  }

  getCameraFov(value) {
    return 2 * Math.atan(value / (2 * this.cameraDistance)) * (180 / Math.PI);
  }

  static findProperFov(fovWidth, fovHeight) {
    return Math.max(fovWidth, fovHeight);
  }

  createZoomToAnimation(item, endCallback, options: {delay?; duration?; clusterAnimation?} = {}) {
    const {delay, duration, clusterAnimation = false} = options;
    const cameraRadius = Camera.calcInitialZPosition(item.planogram.fixedRadius);
    this.cameraDistance = cameraRadius + item.planogram.fixedRadius;
    const i = CameraControls.getItemSize([item.width, item.height]);
    const itemConfiguration = i
      ? {
          size: item.height,
          zoomStopPoint: 0.2
        }
      : {
          size: item.width / Camera.ASPECT_RATIO,
          zoomStopPoint: 0.25
        };
    const fovWidth = this.getCameraFov(item.width / Camera.ASPECT_RATIO);
    const fovHeight = this.getCameraFov(item.height);
    const fullItemFOV = this.camera.clampFOV(
      clusterAnimation ? CameraControls.findProperFov(fovWidth, fovHeight) : this.getCameraFov(itemConfiguration.size)
    );
    const intersect = this.intersectCalculator.findFarthestIntersect(this.camera.position, this.camera.direction);
    this.clusterMidPoint.copy(item.geometry.midVertex);
    this.storedClusterNormal.copy(item.geometry.midNormalVertex);
    return new ZoomToAnimation(
      this.camera.fov(),
      intersect,
      fullItemFOV,
      item.geometry.midVertex,
      endCallback,
      clusterAnimation ? 1 : itemConfiguration.zoomStopPoint,
      delay,
      duration ?? this.sphereApp.planogram.animationSettings.duration,
      this.sphereApp.planogram.animationSettings.transition_type,
      this.sphereApp.planogram.animationSettings.pan_before_zoom
    );
  }

  animateTo(item, endCallback?, options?) {
    const zoomToAnimation = this.createZoomToAnimation(item, endCallback, options);
    this.addAnimation(zoomToAnimation);
    return zoomToAnimation;
  }

  _tiltAndPanBetween(latestIntersect, previousIntersect) {
    const tiltAngle = RotationUtils.tiltAngleBetween(latestIntersect, previousIntersect, this.camera.position);
    const panAngle = RotationUtils.panAngleBetween(latestIntersect, previousIntersect);
    if (this.lastUpdatedAt) {
      const timePeriod = Date.now() - this.lastUpdatedAt;
      this.angularSpeedsX = ArrayUtils.append(
        this.angularSpeedsX,
        CameraControls.limitSpeed(panAngle / timePeriod),
        CameraControls.MAX_SPEED_ELEMENT_COUNT
      );
      this.angularSpeedsY = ArrayUtils.append(
        this.angularSpeedsY,
        CameraControls.limitSpeed(tiltAngle / timePeriod),
        CameraControls.MAX_SPEED_ELEMENT_COUNT
      );
    }
    this.tiltAndPanBy({tilt: tiltAngle, pan: panAngle});
  }

  _moveCameraToFlattenView() {
    const intersect = this.intersectCalculator.findFarthestIntersect(this.camera.position, this.camera.direction);
    if (intersect) {
      this.camera.rotateToNormal(intersect);
    }
  }

  _isDragging() {
    return this.angularSpeedsX.length > 0 && this.angularSpeedsY.length > 0 && Date.now() - this.lastUpdatedAt < 50;
  }
}
