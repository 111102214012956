import {PLANOGRAM_NAME} from './shared/constants';
import {SPHERE_EVENT_NAMES as EVENTS} from './event-names';
import {ViewableLimits} from './viewable_limits';
import {sphereEventHandler} from './custom_event_utils';
import {SphereShape} from './maths/sphereShape';
import {Planogram} from './planogram';
import {Box2, CameraHelper, MathUtils, PerspectiveCamera, Ray, Vector2, Vector3} from 'three';

export class Camera {
  private readonly _perspectiveCamera: PerspectiveCamera;
  private readonly cameraHelper: CameraHelper;
  private _pivotPoint: Vector3;
  private initialFOV: number;
  private _initialCameraPosition: Vector3;
  private _initialCameraDownTilt: number;
  private intersect: number;
  private _zoomAdjustAngle: number;
  private _panAngle: number;
  private _tiltAngle: number;
  private previousWindowHeight: number;
  private maxFOV: number;
  private viewableLimits: ViewableLimits;
  private cameraPosition: number;
  private updateCallbacks: Array<() => void> = [];
  private viewport: Box2;

  static planogramName: string;
  static VIEWPORT_DELAY_MILISECONDS: number = 70;

  static get ASPECT_RATIO_LIMIT() {
    return (16 / 9) * 2;
  }

  static get FIELD_OF_VIEW() {
    return 26.68;
  }

  static get ENTRANCE_ANIMATION_ZOOM_IN_FOV() {
    return 21.34;
  }

  static get ENTRANCE_ANIMATION_DRAG_VALUE() {
    return 400;
  }

  static ZOOM_LEVEL_LIMIT(maxFov, minFov, zoomLevelLimitPercentage) {
    const maxFovPercentage = 100;
    const zoomDistance = (zoomLevelLimitPercentage / maxFovPercentage) * maxFov;
    const limitedMinFov = maxFov - minFov - zoomDistance;

    return limitedMinFov;
  }

  static get MIN_FIELD_OF_VIEW() {
    const defaultMinFov = 0.8;
    const zoomLevelLimitPercentage = 75;

    switch (Camera.planogramName) {
      case PLANOGRAM_NAME.MAIN:
        return Camera.ZOOM_LEVEL_LIMIT(Camera.FIELD_OF_VIEW, defaultMinFov, zoomLevelLimitPercentage);

      default:
        return defaultMinFov;
    }
  }

  static get ASPECT_RATIO() {
    return window.innerWidth / window.innerHeight;
  }

  static get NEAR_CLIPPING() {
    return 1200;
  }

  static get FAR_CLIPPING() {
    return 9999999;
  }

  static get RADIUS_SCALAR() {
    return 1.3;
  }

  // The Camera starts above/off the normal at max zoomout.
  // As the camera zooms in, it starts to move towards the surface
  // normal (ZOOM_START_MOTION) then reaches the normal at ZOOM_END_MOTION
  static get ZOOM_START_MOTION() {
    return 0.5;
  }

  static get ZOOM_END_MOTION() {
    return 0.75;
  }

  static calcInitialZPosition(sphereFixedRadius) {
    return sphereFixedRadius * Camera.RADIUS_SCALAR;
  }

  static calcInitialYPosition(sphereFixedRadius: number, cameraPosition: number): number {
    if (typeof cameraPosition !== 'number' || isNaN(cameraPosition)) {
      console.error('cameraPosition value is not a number');
      return 0.27 * sphereFixedRadius;
    }

    switch (true) {
      case cameraPosition >= -0.1 && cameraPosition < 0: {
        return -0.1 * sphereFixedRadius;
      }

      case cameraPosition >= 0 && cameraPosition <= 0.1: {
        return 0.1 * sphereFixedRadius;
      }

      default: {
        return cameraPosition * sphereFixedRadius;
      }
    }
  }

  static calcInitialCameraDownTilt(sphereFixedRadius, cameraPosition) {
    const cameraY = Camera.calcInitialYPosition(sphereFixedRadius, cameraPosition);
    const cameraZ = Camera.calcInitialZPosition(sphereFixedRadius);
    return Math.atan2(cameraY, cameraZ + sphereFixedRadius);
  }

  static rotateVec3ToYZPlane(vec) {
    // Squash into a 2d vector on an x,y graph as if looking down the 3D y axis
    const fromTopVec2 = new Vector2(vec.x, vec.z);
    const angleFromX = fromTopVec2.angle();

    // Find z by rotating onto the 2d y axis
    fromTopVec2.rotateAround(new Vector2(0, 0), -(angleFromX - Math.PI / 2));
    return new Vector3(0, vec.y, fromTopVec2.y);
  }

  static angleToRotate(cameraVec3, normalVec3) {
    const cameraPoint = new Vector2(cameraVec3.z, cameraVec3.y);
    const normalPoint = new Vector2(normalVec3.z, normalVec3.y);

    let angleToRotate = normalPoint.angle() - cameraPoint.angle();
    if (normalPoint.angle() > Math.PI) {
      if (cameraPoint.angle() < Math.PI) {
        angleToRotate -= Math.PI * 2;
      }
    }

    if (-angleToRotate > Math.PI * 2 || (-angleToRotate > Math.PI * 1.5 && cameraPoint.angle() > Math.PI * 1.5)) {
      angleToRotate += Math.PI * 2;
    }

    return -angleToRotate;
  }

  static zoomFraction(fov, maxFOV) {
    return 1.0 - (fov - Camera.MIN_FIELD_OF_VIEW) / ((maxFOV ?? Camera.FIELD_OF_VIEW) - Camera.MIN_FIELD_OF_VIEW);
  }

  static adjustAngleForZoom(fullAngle, zoomFraction) {
    if (zoomFraction < Camera.ZOOM_START_MOTION) {
      return 0.0;
    }
    if (zoomFraction >= Camera.ZOOM_END_MOTION) {
      return fullAngle;
    }
    const a = zoomFraction - Camera.ZOOM_START_MOTION;
    const b = Camera.ZOOM_END_MOTION - Camera.ZOOM_START_MOTION;
    return (a / b) * fullAngle;
  }

  static fovFromHorizontalFov(aspect, horizontalFOV) {
    return horizontalFOV / aspect;
  }

  constructor(private planogram: Planogram, private sphereShape: SphereShape) {
    this._perspectiveCamera = new PerspectiveCamera(
      Camera.FIELD_OF_VIEW,
      Camera.ASPECT_RATIO,
      Camera.NEAR_CLIPPING,
      Camera.FAR_CLIPPING
    );
    this.viewport = new Box2(new Vector2(0, 0), new Vector2(0, 0));

    this.viewableLimits = new ViewableLimits(planogram);
    this.cameraPosition = planogram.cameraPosition;

    this._perspectiveCamera.matrixAutoUpdate = false;
    this._initCameraAngles(planogram.fixedRadius, planogram.startPoint);
    this._initCameraPosition(planogram.fixedRadius);
    this._pivotPoint = new Vector3(0, 0, 0);
    this.cameraHelper = new CameraHelper(this._perspectiveCamera);
    this._initViewableLimits();
    this.updateCamera();
  }

  get perspectiveCamera() {
    return this._perspectiveCamera;
  }

  get helper() {
    return this.cameraHelper;
  }

  // Return the world direction normal vector that the camera is looking.
  get direction() {
    return this._perspectiveCamera.getWorldDirection(new Vector3());
  }

  get position() {
    return this._perspectiveCamera.position;
  }

  get horizFieldOfView() {
    return this.initialFOV * Camera.ASPECT_RATIO_LIMIT;
  }

  get initialCameraPosition() {
    return this._initialCameraPosition;
  }

  get initialCameraDownTilt() {
    return this._initialCameraDownTilt;
  }

  updateAspect() {
    this._perspectiveCamera.aspect = Camera.ASPECT_RATIO;
    this._updateMaxFOVFromNewAspect();
    this._adjustFOVWhenHeightChanges();
    this._updateTiltAngle();
    this.rotateToNormal(this.intersect);
  }

  updateMaxFOV() {
    this.maxFOV = this.viewableLimits.maxFOV(this._perspectiveCamera.position);
    this._perspectiveCamera.fov = this.maxFOV;
    this.initialFOV = this.maxFOV;
  }

  updateCameraPosition(options) {
    this.cameraPosition = options.value;
    this._initialCameraPosition.y = Camera.calcInitialYPosition(this.planogram.fixedRadius, this.cameraPosition);
    this.updateAspect();
    this.updateMaxFOV();
    this.updateCamera();
  }

  angleFromInitialCameraPosTo(normalVec3, pivotVec3) {
    const cameraVec3 = this.initialCameraPosition.clone();

    this._pivotPoint = Camera.rotateVec3ToYZPlane(pivotVec3);
    this._pivotPoint.z *= -1;

    // Make pivot point the origin for camera vector
    cameraVec3.sub(this._pivotPoint);

    return Camera.angleToRotate(cameraVec3, Camera.rotateVec3ToYZPlane(normalVec3));
  }

  rotateToNormal(intersect) {
    this.intersect = intersect;
    if (!intersect) {
      return;
    }

    const cameraToNormalAngle = this.angleFromInitialCameraPosTo(intersect.normal, intersect.point);
    const fullAjustmentAngle = cameraToNormalAngle - this.initialCameraDownTilt;

    this._zoomAdjustAngle = Camera.adjustAngleForZoom(fullAjustmentAngle, this.currentZoomFraction());
  }

  currentZoomFraction() {
    return Camera.zoomFraction(this._perspectiveCamera.fov, this.maxFOV);
  }

  lookAt(point) {
    this._perspectiveCamera.lookAt(point);
  }

  pivotCameraForZoom() {
    this._perspectiveCamera.translateX(this._pivotPoint.x);
    this._perspectiveCamera.translateY(this._pivotPoint.y);
    this._perspectiveCamera.translateZ(this._pivotPoint.z);
    this._perspectiveCamera.rotateX(this._zoomAdjustAngle);
    this._perspectiveCamera.translateX(-this._pivotPoint.x);
    this._perspectiveCamera.translateY(-this._pivotPoint.y);
    this._perspectiveCamera.translateZ(-this._pivotPoint.z);
  }

  onUpdate(callback: () => void) {
    this.updateCallbacks.push(callback);
  }

  updateCamera() {
    this._perspectiveCamera.position.set(0, 0, 0);
    this._perspectiveCamera.rotation.set(0, 0, 0);
    this._perspectiveCamera.rotateY(this._panAngle);
    this.pivotCameraForZoom();
    this._perspectiveCamera.translateX(this.initialCameraPosition.x);
    this._perspectiveCamera.translateY(this.initialCameraPosition.y);
    this._perspectiveCamera.translateZ(this.initialCameraPosition.z);
    this._perspectiveCamera.rotateX(this._tiltAngle);

    this._perspectiveCamera.updateMatrix();
    this._perspectiveCamera.updateProjectionMatrix();
    this._perspectiveCamera.updateMatrixWorld();

    this.computeViewport();
    this.updateCallbacks.forEach(callback => callback());
  }

  tiltAndPanBy(adjustment: {pan?: number; tilt?: number}) {
    const angleToPan = adjustment.pan || 0;
    const angleToTilt = adjustment.tilt || 0;

    if (angleToPan === 0 && angleToTilt === 0) {
      return;
    }

    if (angleToTilt !== 0) {
      this._updateTiltAngle(angleToTilt);
    }
    if (angleToPan !== 0) {
      this._panAngle = (this._panAngle + angleToPan) % (Math.PI * 2);
    }
  }

  zoomBy(zoomFactor) {
    const fov = this._perspectiveCamera.fov * zoomFactor;
    this.zoomIn(fov);
    sphereEventHandler.emit(EVENTS.CAMERA.ZOOM_BY, {fov, zoomFactor}); // Mouse wheel, O & I keys
  }

  zoomIn(fov) {
    this._perspectiveCamera.fov = fov;
    this._clampCameraFOVToMaxFOV();
  }

  fov() {
    return this._perspectiveCamera.fov;
  }

  clampFOV(fov) {
    return MathUtils.clamp(fov, Camera.MIN_FIELD_OF_VIEW, this.maxFOV);
  }

  update(adjustment) {
    if (adjustment.fov) {
      this.zoomIn(adjustment.fov);
    }
    this.tiltAndPanBy(adjustment);
    this.updateCamera();
  }

  forceAspectUpdate() {
    this._updateTiltAngle();
    this.updateCamera();
  }

  _updateMaxFOVFromNewAspect() {
    if (this._perspectiveCamera.aspect > Camera.ASPECT_RATIO_LIMIT) {
      this.maxFOV = Camera.fovFromHorizontalFov(this._perspectiveCamera.aspect, this.horizFieldOfView);
    } else {
      this.maxFOV = this.initialFOV;
    }
    this._clampCameraFOVToMaxFOV();
  }

  _adjustFOVWhenHeightChanges() {
    if (this.previousWindowHeight) {
      this._perspectiveCamera.fov *= window.innerHeight / this.previousWindowHeight;
    }
    this.previousWindowHeight = window.innerHeight;
    this._clampCameraFOVToMaxFOV();
  }

  _clampCameraFOVToMaxFOV() {
    this._perspectiveCamera.fov = this.clampFOV(this._perspectiveCamera.fov);
  }

  _initCameraAngles(radius, arc) {
    this._panAngle = -(arc / radius);
    this._tiltAngle = 0;
    this._zoomAdjustAngle = 0;
  }

  _initCameraPosition(radius) {
    this._perspectiveCamera.translateOnAxis(
      new Vector3(0, 1, 0),
      Camera.calcInitialYPosition(radius, this.cameraPosition)
    );
    this._perspectiveCamera.translateOnAxis(new Vector3(0, 0, 1), Camera.calcInitialZPosition(radius));
    this._initialCameraPosition = this._perspectiveCamera.position.clone();
    this._initialCameraDownTilt = Camera.calcInitialCameraDownTilt(radius, this.cameraPosition);
  }

  _initViewableLimits() {
    this.updateMaxFOV();
    this.tiltAndPanBy({tilt: 0});
  }

  _updateTiltAngle(angleToTilt = 0) {
    this._tiltAngle = this.viewableLimits.newAngle(
      this._perspectiveCamera,
      this._tiltAngle,
      this._zoomAdjustAngle,
      angleToTilt
    );
  }

  private projectCameraRay(direction: Vector3) {
    const perspectiveCamera = this.perspectiveCamera;
    const origin = perspectiveCamera.position;

    const cameraDirection = direction.unproject(perspectiveCamera).sub(origin).normalize();
    const ray = new Ray(origin, cameraDirection);
    const rayT = this.sphereShape.castRayFarthest(ray);
    if (!rayT) return undefined;
    const intersection = ray.at(rayT, new Vector3());
    return intersection;
  }

  private sceneToPlanogramCoordinate(v: Vector3) {
    const sample = this.sphereShape.reverse(v);
    return this.sphereShape.planogramCoordinate(sample, this.planogram.size());
  }

  private computeViewport() {
    const left = this.projectCameraRay(new Vector3(-1, 0, 0));
    const right = this.projectCameraRay(new Vector3(1, 0, 0));
    const top = this.projectCameraRay(new Vector3(0, 1, 0));
    const bottom = this.projectCameraRay(new Vector3(0, -1, 0));
    if (left && right && top && bottom) {
      const min = new Vector2(this.sceneToPlanogramCoordinate(left).x, this.sceneToPlanogramCoordinate(bottom).y);
      const max = new Vector2(this.sceneToPlanogramCoordinate(right).x, this.sceneToPlanogramCoordinate(top).y);
      this.viewport = new Box2(min, max);
    }
  }

  getViewport() {
    return this.viewport.clone();
  }
}
