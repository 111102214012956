const CLIENT_NAMES = {
  LEGO: 'bricks',
  WB: 'explore',
  SELFRIDGES: 'selfridges',
  SOTHEBYS: 'sothebys',
  NINJAGO: 'legoninjagouniverse',
  AJTRACEY: 'ajtracey',
  UAL: 'ual',
  KHALILI: 'khalili',
  HARRY_POTTER: 'harrypotter',
  HARRY_POTTER_UK: 'harrypotteruk',
  HARRY_POTTER_US: 'harrypotterus',
  SASSENBACH: 'sassenbach'
};

const FONT_UNIT_RATIO = 0.0025;

const DESIGNER_PLANOGRAM_HEIGHT = 2500;

const DEFAULT_CLIENT_NAME = CLIENT_NAMES.LEGO;

const RESTRICTED_SUBDOMAIN_NAMES = ['localhost', 'dev-v2', 'test-v2', 'pre-dev-v2'];

const enum COOKIES_ATTRIBUTES {
  SAME_SITE_LAX = 'Lax'
}

const enum PURCHASING_FLOW {
  CHECKOUT = 'checkout',
  BUY_BUTTON = 'buy-button'
}

const enum PRODUCT_PAGE_ROUTES {
  GALLERY = 'gallery',
  DETAILS = 'details',
  CHECKOUT = 'checkout',
  SHOW = 'show'
}

const PLANOGRAM_NAME = {
  MAIN: 'main',
  SUPERHEROES: 'superheroes',
  FRIENDS: 'friends',
  PINS: 'pins',
  NINJAGO: 'ninjago',
  HARRYPOTTER: 'harrypotter',
  JURASSIC: 'jurassic'
};

enum BUTTONS_NAMES {
  AMAZON = 'amazon',
  RAKUTEN = 'rakuten',
  CHECKOUT = 'checkout',
  EBAY = 'ebay',
  CUSTOM = 'custom',
  CUSTOM_PIN = 'custom_pin'
}

enum CUSTOM_OVERLAYS {
  ABOUT_US = 'about-us',
  VIDEO = 'video',
  PRIVACY_POLICY = 'privacy-policy',
  CONTACT_US = 'contact-us',
  COPYRIGHT = 'copyright',
  IFRAME = 'iframe',
  COOKIES_POLICY = 'cookies-policy'
}

const INFO_OVERLAYS = {
  ABOUT_US: CUSTOM_OVERLAYS.ABOUT_US,
  COPYRIGHT: CUSTOM_OVERLAYS.COPYRIGHT,
  PRIVACY_POLICY: CUSTOM_OVERLAYS.PRIVACY_POLICY,
  CONTACT_US: CUSTOM_OVERLAYS.CONTACT_US,
  COOKIES_POLICY: CUSTOM_OVERLAYS.COOKIES_POLICY
};

enum SPHERE_ITEM_TYPES {
  CLICKABLE_AREA = 'CLICKABLE_AREA',
  PRODUCT = 'PRODUCT',
  PRODUCT_RELEASE_COUNTDOWN = 'PRODUCT-RELEASE-COUNTDOWN',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  INFO = 'INFO',
  CLUSTER = 'CLUSTER',
  IFRAME = 'IFRAME',
  CONTENT_OVERLAY = 'CONTENT-OVERLAY',
  SOCIAL_MEDIA_OVERLAY = 'SOCIAL_MEDIA',
  LINK = 'LINK',
  SPHERE_LINK = 'SPHERE-LINK',
  PRODUCT_TITLE = 'PRODUCT-TITLE',
  PRODUCT_SHOW_ANIMATION = 'PRODUCT-SHOW-ANIMATION',
  PRODUCT_LINK = 'PRODUCT-LINK',
  UNKNOWN = 'UNKNOWN',
  AUDIO = 'AUDIO',
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  PARTICLES = 'PARTICLES',
  OBJECT_3D = 'OBJECT-3D',
  SHAPE = 'SHAPE',
  VIDEO_OVERLAY = 'VIDEO-OVERLAY',
  PRODUCT_LINK_ANIMATION = 'PRODUCT-LINK-ANIMATION',
  CURVE = 'CURVE',
  NAVIGATION_MENU = 'NAVIGATION-MENU'
}

enum CLICK_EVENT_TYPES {
  EMPTY = 'webgl-empty',
  SPHERE = 'webgl-sphere',
  CLUSTER = 'webgl-cluster',
  PRODUCT_OVERLAY = 'webgl-product_overlay',
  LINK = 'webgl-click-link',
  CENTRAL_CARD = 'webgl-click-central_card',
  PRIVACY = 'webgl-open-privacy_overlay',
  COPYRIGHT = 'webgl-open-copyright_overlay',
  IMAGE = 'webgl-image',
  ABOUT_US = 'webgl-open-about_us_overlay'
}

enum HEATMAP_EVENT_TYPES {
  CLICK = 'click',
  MOVE = 'move',
  ZOOM_IN = 'zoom-in',
  ZOOM_OUT = 'zoom-out'
}

enum CSS_VARIABLE_TYPES {
  SHARING_BUTTON = 'share-button'
  // TODO: Add all used css variables
}

enum PRODUCT_GALLERY_ITEM {
  VIDEO = 'video',
  IMAGE = 'image'
}

enum PRODUCT_VIDEO_THUMB_TYPE {
  THUMB_PREV = 'thumbPrev',
  VIDEO_PREV = 'videoPrev'
}

enum PRODUCT_VIDEO_PROVIDER {
  HTML = 'html-video',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  DIRECT_LINK = 'direct',
  CDN = 'cdn'
}

enum DIRECTION {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down'
}

const PAGE_LOADING_SPEED = {
  LOADING: type => `${type}Loading`,
  LOADED: type => `${type}Loaded`,
  LOADING_TIME: type => `${type}LoadingTime`
};

enum PAGE_LOADING_TYPES {
  SPHERE = 'sphere',
  PRODUCT = 'product',
  CHECKOUT = 'checkout'
}

enum ACCOUNT_OVERLAY_TYPE {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  RESET_PASS = 'reset-pass',
  EMAIL_SENT = 'email-sent',
  PROFILE = 'profile'
}

enum LAYOUT_SWITCHER_TYPE {
  TEXT = 'text',
  ICON = 'icon'
}

enum ECOMMERCE_BUTTON_TYPE {
  USER_PROFILE = 'user_profile',
  SHOPPING_CART = 'shopping_cart'
}

enum NAVIGATION_BUTTON_TYPE {
  CONTROL_BUTTON = 'control_button',
  SEARCH = 'search_setting',
  MENU = 'menu'
}

enum COOKIES_SESSION_TYPE {
  LAST_TIME_FROM_ACTION = 'lastTimeFromAction'
}

const ACCESS_TOKEN_KEY = 'accesstoken';

const CURRENCY_CODE_KEY = 'selected-currency-code';

const SHOPIFY_MULTIPASS_TOKEN_KEY = 'multipass_token';

const CURRENCY_KEY = 'currency';

const ABOUT_US_URL = 'https://www.youtube.com/watch?v=8Qn_spdM5Zg';

const CLUSTER_CAPTION_REGEX = /^(cluster-).+(-caption)$/g;
const CLUSTER_NAME_REGEX = /^(cluster-)(?:(?!-caption|caption).)+$/g;
const CLICKABLE_BUTTONS_REGEX =
  /(about-us|copyright|privacy-policy|contact-us|cookies-policy|filter-single|filter-cluster|filter-others)/g;
const CLICKABLE_CARDS_REGEX = /^(sphere-)(.[^-]+)$/g;
const CLICKABLE_VIDEO_OVERLAY = /^video/g;
const CLICKABLE_IFRAME_OVERLAY = /^iframe/g;
const YOUTUBE_URL_REGEX =
  /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g;
const VIMEO_URL_REGEX = /^((?:https?:)?\/\/)?((?:www|m|player)\.)?((?:vimeo\.com))(?:$|\/|)(\S+)?$/;
const CLICKABLE_PRODUCT_TITLE_REGEX = /^(product-)/g;
const CLICKABLE_PRODUCT_SHOW_ANIMATION_REGEX = /^(product-show-)/g;
const CLICKABLE_PRODUCT_RELEASE_COUNTDOWN_REGEX = /^(product-release-)/g;
const CLICKABLE_PRODUCT_TITLE_OR_SHOW_ANIMATION_REGEX = /^(product-(show-)?)/g;
const CLICKABLE_PRODUCT_LINK_REGEX = /^(sphere-)(\w+)-(\w+)$/g;
const CLICKABLE_PRODUCT_LINK_ANIMATION_REGEX = /^(sphere-show-)(\w+)-(\w+)$/g;
const DEVELOPERS_DOMAIN_REGEX = /^(design|test|demo)+/g;
const CLUSTER_NAMING_REGEX = /^(?:cluster-)?(\w+)(?:-caption)?$/;
const HARRY_POTTER_FIELDS_REGEX = /[^a-zA-Z0-9 .'\/-]/g;
const IS_IFRAME_REGEX = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/;
const YOUTUBE_CHANNEL_REGEX = /^[UC]{2}/;
const ITEM_IDENTIFIER_URL_REGEX = /\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/(i|y|t|s|f|p|v|c|o|sm)([\w-]+)/;

export {
  CLIENT_NAMES,
  DEFAULT_CLIENT_NAME,
  RESTRICTED_SUBDOMAIN_NAMES,
  PLANOGRAM_NAME,
  CUSTOM_OVERLAYS,
  SPHERE_ITEM_TYPES,
  CLICK_EVENT_TYPES,
  HEATMAP_EVENT_TYPES,
  INFO_OVERLAYS,
  CSS_VARIABLE_TYPES,
  VIMEO_URL_REGEX,
  PRODUCT_GALLERY_ITEM,
  PRODUCT_VIDEO_PROVIDER,
  DIRECTION,
  CLUSTER_CAPTION_REGEX,
  CLUSTER_NAME_REGEX,
  CLICKABLE_BUTTONS_REGEX,
  CLICKABLE_CARDS_REGEX,
  CLICKABLE_VIDEO_OVERLAY,
  CLICKABLE_IFRAME_OVERLAY,
  YOUTUBE_URL_REGEX,
  ABOUT_US_URL,
  CLICKABLE_PRODUCT_TITLE_REGEX,
  CLICKABLE_PRODUCT_RELEASE_COUNTDOWN_REGEX,
  CLICKABLE_PRODUCT_TITLE_OR_SHOW_ANIMATION_REGEX,
  CLICKABLE_PRODUCT_SHOW_ANIMATION_REGEX,
  CLICKABLE_PRODUCT_LINK_REGEX,
  CLICKABLE_PRODUCT_LINK_ANIMATION_REGEX,
  DEVELOPERS_DOMAIN_REGEX,
  CLUSTER_NAMING_REGEX,
  BUTTONS_NAMES,
  PURCHASING_FLOW,
  PRODUCT_PAGE_ROUTES,
  PAGE_LOADING_SPEED,
  PAGE_LOADING_TYPES,
  ACCOUNT_OVERLAY_TYPE,
  LAYOUT_SWITCHER_TYPE,
  ACCESS_TOKEN_KEY,
  FONT_UNIT_RATIO,
  DESIGNER_PLANOGRAM_HEIGHT,
  COOKIES_ATTRIBUTES,
  PRODUCT_VIDEO_THUMB_TYPE,
  CURRENCY_CODE_KEY,
  COOKIES_SESSION_TYPE,
  SHOPIFY_MULTIPASS_TOKEN_KEY,
  HARRY_POTTER_FIELDS_REGEX,
  ECOMMERCE_BUTTON_TYPE,
  CURRENCY_KEY,
  IS_IFRAME_REGEX,
  YOUTUBE_CHANNEL_REGEX,
  NAVIGATION_BUTTON_TYPE,
  ITEM_IDENTIFIER_URL_REGEX
};
