import * as THREE from 'three';
import {InputEventUtils} from '../utils/input_event_utils';
import {CameraControls} from './camera_controls';
import {SphereApp} from '../sphere_app';
import {sphereEventHandler} from '../custom_event_utils';
import {SPHERE_EVENT_NAMES as EVENTS} from '../event-names';
import {BaseInputHandler} from '../base_input_handler';

export class TouchControls {
  private _startPointer: THREE.Vector2;
  private _previousTouchDistance: number;
  private _previousEvent: TouchEvent;
  private _pinched: boolean;
  private inputHandler: BaseInputHandler;

  static get CLICK_MARGIN_OF_ERROR() { return 5; }

  constructor(private domElement, private cameraControls: CameraControls, private sphereApp: SphereApp) {
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.domElement.addEventListener('touchstart', this.onTouchStart, { passive: false });
    this.domElement.addEventListener('touchend', this.onTouchEnd, { capture: true });
    this.domElement.addEventListener('touchmove', this.onTouchMove, { passive: false });
  }

  onTouchStart = (event: TouchEvent) =>{
    if (InputEventUtils.onSphereSurface(event)) {
      this._startPointer = InputEventUtils.getTouchPosition(event);
      if (event.touches.length > 1) {
        this._previousTouchDistance = InputEventUtils.calculateTouchDistance(
          event.touches[0],
          event.touches[1]
        );
        const pinchCenter = InputEventUtils.getPinchCentre(event.touches[0], event.touches[1]);
        this.cameraControls.onMovementStart(pinchCenter);
      } else {
        this.cameraControls.onMovementStart(this._startPointer);
      }
    }
    this._previousEvent = event;
  }

  onTouchMove(event) {
    this.inputHandler = this.sphereApp.ACTUAL_INPUT_HANDLER;
    if (!this._startPointer) {
      return;
    }
    if (this._previousEvent.touches.length !== event.touches.length) {
      this.onTouchStart(event);
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (event.touches.length > 1) {
      this._pinch(event);
      this._pinched = true;
    } else {
      this.inputHandler.isClusterSelected = false;
      const latestPointer = InputEventUtils.getTouchPosition(event);
      this.cameraControls.tiltAndPanTo(latestPointer);
    }
    this._previousEvent = event;
  }

  onTouchEnd(event) {
    this.inputHandler = this.sphereApp.ACTUAL_INPUT_HANDLER;
    if (!this._startPointer) {
      return;
    }
    if (this._isTap(event)) {
      event.preventDefault();
      const position = InputEventUtils.getEndTouchPosition(event);
      sphereEventHandler.emit(EVENTS.CONTROL.TAP, event);
      this.inputHandler.handleClick(position.x, position.y);
    } else {
      event.stopPropagation();
    }
    if (event.touches.length === 0) {
      this._pinched = false;
      this.cameraControls.onMovementEnd();
      this._startPointer = undefined;
      this._previousEvent = undefined;
    }
  }

  _pinch(event) {
    this.inputHandler = this.sphereApp.ACTUAL_INPUT_HANDLER;
    const currentTouchDistance = InputEventUtils.calculateTouchDistance(
      event.touches[0],
      event.touches[1]
    );
    const pinchCenter = InputEventUtils.getPinchCentre(event.touches[0], event.touches[1]);
    const zoomScaleFactor = (this._previousTouchDistance / currentTouchDistance);
    this.cameraControls.zoomToPoint(pinchCenter, zoomScaleFactor);
    this._previousTouchDistance = currentTouchDistance;
    this.inputHandler.isClusterSelected = false;
  }

  _isTap(event) {
    if (this._pinched || event.touches.length > 0) {
      return false;
    }
    const currentPointer = InputEventUtils.getEndTouchPosition(event);
    const moveDistance = InputEventUtils.distance(this._startPointer, currentPointer);
    return moveDistance < TouchControls.CLICK_MARGIN_OF_ERROR;
  }

  dispose() {
    this.domElement.removeEventListener('touchstart', this.onTouchStart, { passive: false });
    this.domElement.removeEventListener('touchend', this.onTouchEnd, { capture: true });
    this.domElement.removeEventListener('touchmove', this.onTouchMove, { passive: false });
  }
}
