import * as THREE from 'three';

import {SphereItem} from './sphere_item';
import {VTPipeline} from './vt/vt_pipeline';
import {Planogram} from './planogram';
import {ProductSlides} from './product_slides';
import {CLUSTER_NAME_REGEX, SPHERE_ITEM_TYPES} from './shared/constants';
import {TextComponent} from './components/text';
import {VideoComponent} from './components/video';
import {
  ACTION_TYPE,
  AnimateActionData,
  ClusterActionData,
  CurvesMetaData,
  ImageMetaData,
  ItemData,
  MetaDataType,
  ClusterMetaData,
  ContentOverlayActionData,
  MediaMetaData
} from './interfaces/planogram.interface';
import {CurveComponent} from './components/curve';
import {RectangleComponent} from './components/rectangle';
import {AppUtils} from './utils/app_utils';
import loadingProgress, {LOADING_STAGES} from './api/services/loading_progress.service';
import {disposeObject3D} from './utils/disposeThree';
import {Material, Mesh, Object3D} from 'three';
import {VRService} from './vr/vr_service';

function fetchVideoAspectRatio(url: string): Promise<number> {
  const videoElm = document.createElement('video');
  videoElm.preload = 'metadata';
  videoElm.src = url;
  videoElm.load();
  return new Promise(resolve => {
    videoElm.onloadedmetadata = () => {
      videoElm.remove();
      resolve(videoElm.videoWidth / videoElm.videoHeight);
    };
  });
}

async function fitFeedContent(item: SphereItem) {
  if (item.itemData.instagram_feed !== true) return;

  const size = item.getSize();
  const position = new THREE.Vector2(item.x, item.y).addScaledVector(size, 0.5);

  let newAspectRatio: number = 1;

  if (item.type === SPHERE_ITEM_TYPES.VIDEO)
    newAspectRatio = await fetchVideoAspectRatio((item.data as MediaMetaData).videoUrl);
  else if (item.type === SPHERE_ITEM_TYPES.IMAGE) {
    const resolution = (item.data as ImageMetaData).naturalResolution;
    newAspectRatio = resolution[0] / resolution[1];
  } else {
    throw new Error(`Unknown instagram feed object of type ${item.type}`);
  }

  const containerAspectRatio = size.x / size.y;
  const contentToContainer = newAspectRatio / containerAspectRatio;

  if (contentToContainer > 1) size.y /= contentToContainer;
  else size.x *= contentToContainer;

  position.addScaledVector(size, -0.5);
  item.overridePosition(position.x, position.y);
  item.overrideSize(size.x, size.y);
}

export class Sphere {
  sphereItems: Array<SphereItem> = [];
  private itemsGroup: THREE.Group;

  constructor(private planogram: Planogram, private scene: THREE.Scene, private vtPipeline: VTPipeline) {
    this.itemsGroup = new THREE.Group();
    this.itemsGroup.layers.enable(2);
    this.scene.add(this.itemsGroup);

    this.sphereItems = this.planogram.items.map(item => this.createSphereItem(item));
  }

  private setDepthTest(object: Object3D, depthTest: boolean) {
    for (const child of object.children) {
      child.renderOrder = 999999999;
      const material = (child as Mesh).material as Material;
      if (material) {
        material.transparent = true;
        material.depthTest = depthTest;
      }

      if (child.children) {
        this.setDepthTest(child, depthTest);
      }
    }
  }

  fitSceneToVR = () => {
    if (VRService.isActive) {
      this.itemsGroup?.scale.set(0.002, 0.002, 0.002);
      this.itemsGroup?.position.set(0, 0.8, 0);
      this.setDepthTest(this.itemsGroup, true);
    } else {
      this.itemsGroup?.scale.set(1, 1, 1);
      this.itemsGroup?.position.set(0, 0, 0);
      this.setDepthTest(this.itemsGroup, false);
    }
  };

  loadSphereItems(): Promise<void> {
    const newPickingItemsMap = new Map<string, THREE.Object3D>();
    if (this.sphereItems.length === 0) loadingProgress.progressStage(LOADING_STAGES.ITEM_MESHES, 1);
    const itemPromises = this.sphereItems.map(sphereItem =>
      fitFeedContent(sphereItem)
        .then(() => sphereItem.createMesh())
        .catch(err => {
          console.error(`Sphere item mesh creation failed: ${err}`);
        })
        .then(() => {
          loadingProgress.progressStage(LOADING_STAGES.ITEM_MESHES, this.sphereItems.length);
          if (sphereItem.type === SPHERE_ITEM_TYPES.CLUSTER || sphereItem.action?.type === ACTION_TYPE.CLUSTER) {
            return;
          }

          this.itemsGroup.add(sphereItem.object3D);
          const item = sphereItem.object3D.userData.component as SphereItem;
          if (item) {
            newPickingItemsMap.set(String(item.id), sphereItem.object3D);
          }
        })
    );

    this.vtPipeline.setItems(newPickingItemsMap);

    return Promise.all(itemPromises).then(); // discard void[], thanks Typescript
  }

  extractClusterFullName(clusterCaption: string): string {
    const clusterNameSliceIndex = clusterCaption.indexOf('-caption');
    return `cluster-${clusterCaption.slice(0, clusterNameSliceIndex)}`;
  }

  dispose() {
    this.sphereItems.forEach(it => it.dispose());
    disposeObject3D(this.scene);
  }

  addClusterInfoToItem(items, clusters) {
    items.forEach(item => {
      clusters.forEach(cluster => {
        const intersects =
          cluster.x < item.x + item.width &&
          cluster.x + cluster.width > item.x &&
          cluster.y < item.y + item.height &&
          cluster.y + cluster.height > item.y;
        if (intersects) {
          item.cluster = cluster.action;
        }
      });
    });

    return items;
  }

  createSphereItem(item: ItemData<MetaDataType>) {
    switch (item.type) {
      case SPHERE_ITEM_TYPES.VIDEO:
        return new VideoComponent(item, this.planogram);
      case SPHERE_ITEM_TYPES.TEXT:
      case SPHERE_ITEM_TYPES.TEXT_AREA:
        return new TextComponent(item, this.planogram);
      case SPHERE_ITEM_TYPES.SHAPE:
        return new RectangleComponent(item, this.planogram);
      case SPHERE_ITEM_TYPES.CURVE:
        return new CurveComponent(item as ItemData<CurvesMetaData>, this.planogram);
      case SPHERE_ITEM_TYPES.IMAGE:
      case SPHERE_ITEM_TYPES.PRODUCT:
        const sphereItem = new SphereItem(item, this.planogram);
        const lodMaterial = this.vtPipeline.createLODMaterial(item);
        sphereItem.material = lodMaterial;
        return sphereItem;
      default:
        return new SphereItem(item, this.planogram);
    }
  }

  getSphereItem(index: number): SphereItem {
    return this.sphereItems[index];
  }

  findClusterByClusterName(clusterName: string): SphereItem | undefined {
    // handle case with raw value as cluster name
    if (clusterName.match(CLUSTER_NAME_REGEX)) {
      clusterName = AppUtils.extractClusterName(clusterName);
    }
    const foundClusters = this.sphereItems.filter(item => {
      return (
        ((item.action?.data as ClusterActionData)?.clusterLink === clusterName &&
          item.action?.type === ACTION_TYPE.CLUSTER) ||
        ((item.action?.data as ClusterActionData)?.clusterLink === clusterName &&
          item.type === SPHERE_ITEM_TYPES.CLUSTER) ||
        ((item.data as ClusterMetaData).clusterLink === clusterName && item.type === SPHERE_ITEM_TYPES.CLUSTER)
      );
    });
    if (foundClusters.length) {
      if (foundClusters.length > 1) {
        console.error('There are more than one cluster with the same name');
      }
      return foundClusters[0];
    } else {
      console.error('There are no cluster related to this name');
    }
  }

  findItemsByCluster(clusterName: string): SphereItem[] {
    return this.sphereItems.filter(item => item.cluster === clusterName);
  }

  findItemsOutsideCluster(clusterName: string): SphereItem[] {
    return this.sphereItems.filter(item => item.cluster !== clusterName && item.cluster !== 'cluster-card');
  }

  findSphereItemByIdentifier(identifier: string) {
    const sphereItems = this.sphereItems.filter(item => {
      return item.identifier === identifier || item.id?.toString() === identifier;
    });
    const sphereItem = sphereItems.find(item => item.imageName === ProductSlides.BOX_ART_NAME(item.identifier));

    if (!sphereItem && sphereItems.length > 0) {
      return sphereItems[0];
    }
    return sphereItem;
  }

  findSphereItemByActionIdentifier(identifier: string) {
    return this.sphereItems.find(item => {
      return (
        (item.action?.data as AnimateActionData)?.itemUuid === identifier ||
        item.identifier === identifier ||
        item.id?.toString() === identifier
      );
    });
  }

  findSphereItemByImageName(imageName) {
    return this.sphereItems.find(item => item.imageName === ProductSlides.IMAGE_FILE_NAME(imageName));
  }

  findSphereItemByImageId(imageId: string) {
    return this.sphereItems.find(
      item => ((item.data as ImageMetaData).picture?.id || (item.data as ImageMetaData).id)?.toString() === imageId
    );
  }

  // is fired on sphere item click
  findSphereItemByImageFilename(imageFilename) {
    if (!imageFilename) {
      return;
    }
    return this.sphereItems.find(item => item.imageName === imageFilename);
  }

  findSphereItemByContentLink(link: string) {
    return this.sphereItems.find(it => (it.action?.data as ContentOverlayActionData)?.iframeLink === link);
  }
}
