import {ACTION_TYPE, AnimateActionData, ClusterActionData, ImageMetaData, ProductActionData} from '../../interfaces/planogram.interface';
import {CLICK_EVENT_TYPES, HEATMAP_EVENT_TYPES, SPHERE_ITEM_TYPES} from '../../shared/constants';
import {ApiService} from '../api';
import {URLS} from '../urls';
import {debounce} from 'throttle-debounce';
import {SphereItem} from '../../sphere_item';
import {AppUtils} from '../../utils/app_utils';
import {CookiesManagement} from '../../cookies_management';

export class HeatMapService extends ApiService {
  MATOMO_USER_ID_REGEX = /_pk_id\.[^\..]+\.[^=]+=([^\.]+)/;
  MATOMO_SESSION_ID_REGEX = /_pk_ses\.[^\..]+\.[^=]+=([^\. ;]+)/;

  matomoUserId: string;
  matomoSessionId: string;

  constructor(
     private planogramVersionId,
     private screenWidth: number, 
     private screenHeight: number,
     private getPlanogramCoordinates: (x, y) => Array<number>,
     private cookie?: string
     ) {
    super();
    this.updatePlanogram(planogramVersionId, cookie);
  }

  async get(url, headers?): Promise<Response> {
    if (!ENABLE_HEATMAP_SERVICE) return new Response();
    return super.get(url, headers);
  }

  updatePlanogram(planogramVersionId, cookie?) {
    this.planogramVersionId = planogramVersionId;
    if (cookie) {
      this.matomoUserId = cookie.match(this.MATOMO_USER_ID_REGEX);
      this.matomoSessionId = cookie.match(this.MATOMO_SESSION_ID_REGEX);
    }
  }

  updateScreenSize(screenWidth: number, screenHeight: number) {    
    this.screenWidth = screenWidth;
    this.screenHeight = screenHeight;
  }

  generateBaseUrl(eventType: HEATMAP_EVENT_TYPES, coords: Array<number>, zoomLevel: number) {
    const timestamp = new Date(Date.now()).toISOString();
    CookiesManagement.updateLastActionTime();
    return `${URLS.HEATMAP_URL}?\
planogram_version_id=${this.planogramVersionId}&event_type=${eventType}\
&timestamp=${timestamp}\
&x=${Math.round(coords[0])}&y=${Math.round(coords[1])}\
&screen_width=${this.screenWidth}\
&screen_height=${this.screenHeight}\
&zoom_level=${zoomLevel}\
${ this.matomoUserId ? `&matomo_user_id=${this.matomoUserId[1]}` : ''}\
${ this.matomoSessionId ? `&matomo_session_id=${this.matomoSessionId[1]}` : ''}`;
  }

  sendClickEvent(x: number, y: number, zoomLevel: number, item: SphereItem) {
    zoomLevel = Math.round(zoomLevel * 100) / 100.0;
    const coords = this.getPlanogramCoordinates(x, y);
    const baseUrl = this.generateBaseUrl(HEATMAP_EVENT_TYPES.CLICK, coords, zoomLevel);
    let itemUrl;
    let actionType = item?.action?.type;
    switch (item?.action?.type) {
      case ACTION_TYPE.ANIMATE:
        switch ((item.action.data as AnimateActionData).itemType) {
          case SPHERE_ITEM_TYPES.CLUSTER:
            itemUrl = `&object_type=cluster&object_id=${AppUtils.extractClusterName((item.action.data as AnimateActionData).clusterLink)}`;
            actionType = ACTION_TYPE.CLUSTER;
            break;
          case SPHERE_ITEM_TYPES.IMAGE:
            itemUrl = `&object_type=image&object_id=${(item.action?.data as AnimateActionData)?.imageId}`;
            actionType = ACTION_TYPE.IMAGE;
            break;
          case SPHERE_ITEM_TYPES.PRODUCT:
            itemUrl = `&object_type=product&object_id=${(item.action?.data as AnimateActionData)?.productIdentifier}`;
            actionType = ACTION_TYPE.PRODUCT;
            break;          
        }
        break;
      case ACTION_TYPE.CLUSTER_CAPTION:
      case ACTION_TYPE.CLUSTER:
        itemUrl = `&object_type=cluster&object_id=${AppUtils.extractClusterName((item.action.data as ClusterActionData).clusterLink) || (item.action.data as ClusterActionData).clusterLink}`;
        break;
      case ACTION_TYPE.PRODUCT:
      case ACTION_TYPE.PRODUCT_OVERLAY:
        itemUrl = `&object_type=product&object_id=${item.data?.product?.identifier || (item.action?.data as ProductActionData)?.productIdentifier}`;
        break;
      case ACTION_TYPE.IMAGE:
      case ACTION_TYPE.SINGLE_IMAGE:
        itemUrl = `&object_type=image&object_id=${(item.data as ImageMetaData)?.picture?.id || (item.data as ImageMetaData)?.id}`;
        break;
      default:
        itemUrl = '';
    }
    const clickUrl = `${baseUrl}&click_event_type=${this.actionTypeToClickEvent(actionType)}${itemUrl}`;
    this.get(clickUrl, {});
  }

  actionTypeToClickEvent(actionType: ACTION_TYPE): CLICK_EVENT_TYPES {
    switch (actionType) {
      case ACTION_TYPE.CLUSTER_CAPTION:
      case ACTION_TYPE.CLUSTER:
        return CLICK_EVENT_TYPES.CLUSTER;
      case ACTION_TYPE.ABOUT_US:
        return CLICK_EVENT_TYPES.ABOUT_US;
      case ACTION_TYPE.COPYRIGHT:
        return CLICK_EVENT_TYPES.COPYRIGHT;
      case ACTION_TYPE.LINK:
        return CLICK_EVENT_TYPES.LINK;
      case ACTION_TYPE.PRIVACY_POLICY:
        return CLICK_EVENT_TYPES.PRIVACY;
      case ACTION_TYPE.IMAGE:
      case ACTION_TYPE.SINGLE_IMAGE:
        return CLICK_EVENT_TYPES.IMAGE;
      case ACTION_TYPE.PRODUCT:
      case ACTION_TYPE.PRODUCT_OVERLAY:
        return CLICK_EVENT_TYPES.PRODUCT_OVERLAY;
      default:
        return CLICK_EVENT_TYPES.EMPTY;
    }
  }

  private previousZoomTime: number = Date.now();  
  private previousZoomLevel: number = 0.0;
  private debounceZoomFunction = debounce(400, (coords, newZoomLevel ) => {
    const zoomDuration = Math.round((Date.now() - this.previousZoomTime) / 1000.0);
    const baseUrl = this.generateBaseUrl(
      newZoomLevel > this.previousZoomLevel ? HEATMAP_EVENT_TYPES.ZOOM_IN : HEATMAP_EVENT_TYPES.ZOOM_OUT,
      coords,
      this.previousZoomLevel);
    this.previousZoomTime = Date.now();
    const zoomUrl = `${baseUrl}&duration=${zoomDuration}&final_zoom_level=${newZoomLevel}`;
    this.previousZoomLevel = newZoomLevel;
    this.get(zoomUrl, {});
  }).bind(this);
  sendZoomEvent(x: number, y: number, zoomLevel: number, newZoomLevel: number) {
    zoomLevel = Math.round(zoomLevel * 100) / 100.0;
    if (newZoomLevel === this.previousZoomLevel) { return; }
    const coords = this.getPlanogramCoordinates(x, y);
    this.debounceZoomFunction(coords, newZoomLevel);
  }
  
  private previousMovePosition: Array<number> = undefined;
  private previousMoveTime: number;
  sendMoveEvent(x: number, y: number, zoomLevel: number, direction: string) {
    if (this.previousMovePosition === undefined) {
      this.previousMovePosition = this.getPlanogramCoordinates(x, y);
      this.previousMoveTime = Date.now();
      return;
    }
    zoomLevel = Math.round(zoomLevel * 100) / 100.0;
    const baseUrl = this.generateBaseUrl(
      HEATMAP_EVENT_TYPES.MOVE,
      this.previousMovePosition,
      zoomLevel);
  
    const duration = Math.round((Date.now() - this.previousMoveTime) / 1000.0);
    this.previousMoveTime = Date.now();

    const coords = this.getPlanogramCoordinates(x, y);
    const moveUrl = `${baseUrl}&duration=${duration}&direction=${direction}&destination_x=${Math.round(coords[0])}&destination_y=${Math.round(coords[1])}`;
    this.previousMovePosition = this.getPlanogramCoordinates(x, y);
    this.get(moveUrl, {});
  }
}
