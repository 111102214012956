import {
  BufferGeometry,
  Color,
  DirectionalLight,
  Group,
  Line,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  WebGLRenderer
} from 'three';
import {VTPipeline} from '../vt/vt_pipeline';
import {VRController} from './vr_controller';
import {VRCameraControls} from './vr_camera_controls';

import {VRButton} from 'three/examples/jsm/webxr/VRButton';
import {sphereEventHandler} from '../custom_event_utils';
import {SPHERE_EVENT_NAMES} from '../event-names';

export class VRService {
  cameraDolly: Group = new Group();
  camera: PerspectiveCamera;
  private controllers: VRController[] = [];
  private vrButton: HTMLElement;
  private vrCameraControls: VRCameraControls;

  static isActive: boolean = false;

  constructor(private scene: Scene, private renderer: WebGLRenderer, private vtPipeline: VTPipeline) {
    this.vrButton = VRButton.createButton(renderer);
    this.vrButton.hidden = true;
    if ('xr' in navigator) {
      const vrButton = this.vrButton;
      navigator.xr.isSessionSupported('immersive-vr').then((supported: boolean) => {
        vrButton.hidden = !supported;
      });
    }
    const sphereGroup = this.scene.children[0];
    this.renderer.xr.enabled = true;
    this.renderer.xr.addEventListener('sessionstart', e => {
      VRService.isActive = true;
      sphereEventHandler.emit(SPHERE_EVENT_NAMES.SPHERE.VR);
    });
    this.renderer.xr.addEventListener('sessionend', e => {
      VRService.isActive = false;
      sphereEventHandler.emit(SPHERE_EVENT_NAMES.SPHERE.VR);
      sphereGroup.rotation.set(0, 0, 0);
    });

    document.body.appendChild(this.vrButton);

    this.setupCamera();
    this.setupControllers();

    this.vrCameraControls = new VRCameraControls(this.controllers, sphereGroup);
  }

  setupCamera = () => {
    this.camera = new PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
    const light = new DirectionalLight(0xffffff);
    light.castShadow = false;
    light.position.set(0, 6, 0);

    this.scene.add(light);

    this.cameraDolly.position.set(0, 0, 0);
    this.cameraDolly.add(this.camera);
    this.scene.add(this.cameraDolly);

    this.renderer.setClearColor(new Color(1.0, 1.0, 1.0));
  };

  setupControllers = () => {
    this.controllers.push(new VRController(this.renderer, 0, this.cameraDolly));
    this.controllers.push(new VRController(this.renderer, 1, this.cameraDolly));
  };

  render = () => {
    this.vrCameraControls.update(this.cameraDolly);
    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);
  };

  dispose = () => {
    this.vrButton.remove();
    this.camera = undefined;
    this.controllers.forEach((controller: VRController) => {
      controller.dispose();
    });
    this.controllers = [];
  };
}
