import {Camera, Mesh, Object3D, Raycaster, Scene, Vector3} from 'three';
import {Camera as SphereCamera} from '../camera';
import {normalizeMouse} from '../utils/math_utils';

export class RaycastControls {
  private raycaster: Raycaster;
  intersection: Vector3 = new Vector3();

  constructor(private sphereScene: Scene) {
    this.raycaster = new Raycaster();
  }

  getInteractableObjectAtScreenCoordinate(x, y, scene: Scene, camera: Camera): {mesh: Mesh; point: Vector3} {
    const items = scene.children;
    const coords = normalizeMouse(x, y);
    this.raycaster.setFromCamera(coords, camera);
    const intersects = this.raycaster.intersectObjects(items, true);

    // Filter intersection that are closer than the near clipping plane.
    // Since the camera, and thus the origin of the raycaster, is located outside of the sphere
    // it can happens that when raycasting items on the opposite side of the sphere are also intersected.
    const topIntersection = intersects
      .filter(i => i.distance > SphereCamera.NEAR_CLIPPING)
      .sort((a, b) => {
        return b.object.renderOrder - a.object.renderOrder;
      })[0];

    // Layer 2 is reserved for elements reacting to input
    const intersection = topIntersection?.object.layers.isEnabled(2) ? topIntersection : undefined;

    if (!intersection) {
      return {mesh: undefined, point: undefined};
    }

    this.intersection = intersection.point;
    return {mesh: intersection.object as Mesh, point: intersection.point};
  }

  getIntersectionWithObjectAtScreenCoordinate(x, y, object: Object3D, camera: Camera) {
    const coords = normalizeMouse(x, y);
    this.raycaster.setFromCamera(coords, camera);

    const intersects = this.raycaster.intersectObject(object, false);
    if (intersects.length) {
      this.intersection = intersects[intersects.length - 1].point;
      return intersects[intersects.length - 1].point;
    }
    return null;
  }

  getUVOnSphere(origin: Vector3, direction: Vector3) {
    this.raycaster.set(origin, direction);

    const intersects = this.raycaster.intersectObjects(this.sphereScene?.children, true);
    if (intersects.length) {
      return intersects[0].uv;
    }
    return null;
  }
}
