import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';

import makeBehaviorSubject from 'callbag-behavior-subject';
import {ApiService} from '../api';
import {ShopifyVariant} from '../../interfaces/shopify-product.interface';
import {URLS, UrlUtils} from '../urls';
import {Planogram} from '../../planogram';
import {CheckoutVariant, ShoppingCartInterface, ShoppingCartVariant} from '../../interfaces/shopping-cart.interface';
import {L10nUtils} from '../../utils/l10n_utils';
import {Account} from '../../account/account';
import {CurrencyService} from './currency.service';
import {
  ACCESS_TOKEN_KEY,
  CURRENCY_CODE_KEY,
  SHOPIFY_MULTIPASS_TOKEN_KEY
} from '../../shared/constants';
import Router from '../../router';
import {AppUtils} from '../../utils/app_utils';
import {ShoppingCartUtils} from '../../utils/shopping-cart_utils';

export class ShoppingCartService extends ApiService {
  checkoutSubject: makeBehaviorSubject<object>;
  processingSubject: makeBehaviorSubject<boolean>;
  shoppingCart: ShoppingCartInterface;
  isMultipassEnabled: boolean;
  email: string;
  cancelAnimationFn: Function;
  private readonly checkoutId: string;
  private readonly mutlipassId: string;
  private readonly checkoutSubjectRef: Function;
  static isProcessing: boolean;

  constructor(private planogram: Planogram, private currencyService: CurrencyService) {
    super();
    this.onShoppingCartStorageChange = this.onShoppingCartStorageChange.bind(this);
    this.checkoutId = `checkout-${this.planogram.clientName}`;
    this.mutlipassId = `multipass_token-${this.planogram.clientName}`;
    const storedCheckoutState = ShoppingCartUtils.getStoredShoppingCart(this.checkoutId);
    this.checkoutSubject = makeBehaviorSubject(JSON.parse(storedCheckoutState) || null);
    this.processingSubject = makeBehaviorSubject(false);

    window.addEventListener('storage', this.onShoppingCartStorageChange);

    this.checkoutSubjectRef = pipe(
      this.checkoutSubject,
      subscribe({
        next: (data: any) => {
          if (data && !data.last_update) {
            data = {...data, last_update: new Date().getTime()};
          }
          this.shoppingCart = data;
        },
        error: () => {
          this.shoppingCart = null;
        }
      })
    );
  }

  static get SHOPPING_CART_UPDATE_INTERVAL() {
    // 1 minute is equal to 60secs and 60secs is equal to 60 * 1000 = 60000ms
    return 60 * 1000;
  }

  static get SHOPPING_CART_TIME_THRESHOLD() {
    // 1 minute is equal to 60secs and 60secs is equal to 60 * 1000 = 60000ms
    return 30 * 60 * 1000;
  }

  get getMultipassToken() {
    return window.localStorage.getItem(this.mutlipassId);
  }

  fireShoppingCartUpdates(variant: ShopifyVariant): Promise<ShoppingCartInterface> {
    return this.shoppingCart ? this.updateShoppingCart(variant) : this.createShoppingCart(variant);
  }

  attachShoppingCartToUser() {
    const requestData = {
      checkout: this.shoppingCart.products.map((product: ShoppingCartVariant) => {
        return {
          variantId: product.variant_id,
          quantity: product.quantity,
          customAttributes: product.custom_attributes
        };
      })
    };
    this.processingSubject(1, true);
    return this.createCartRequest(requestData)
      .then(() => ShoppingCartUtils.deleteStoredShoppingCart(this.checkoutId))
      .finally(() => this.processingSubject(1, false));
  }

  updateShoppingCartQuantity(variant: ShoppingCartVariant): Promise<ShoppingCartInterface> {
    const indexOfVariant = this.getIndexVariant(variant);
    if (indexOfVariant !== -1) {
      this.shoppingCart.checkout[indexOfVariant].quantity = variant.quantity;
    }
    this.processingSubject(1, true);
    return this.updateCartRequest()
      .then(data => this.saveShoppingCartState(data))
      .catch(err => this.clearShoppingCartState(err))
      .finally(() => this.processingSubject(1, false));
  }

  clearShoppingCartState(err?) {
    this.checkoutSubject(2, true);
    ShoppingCartUtils.deleteStoredShoppingCart(this.checkoutId);
    if (err) {
      throw err;
    }
  }

  clearShoppingCartToken() {
    this.isMultipassEnabled = false;
    window.localStorage.removeItem(this.mutlipassId);
  }

  removeItemFromCart(variant: ShopifyVariant): Promise<ShoppingCartInterface> {
    const indexOfVariant = this.getIndexVariant(variant);
    this.shoppingCart.checkout.splice(indexOfVariant, 1);
    this.processingSubject(1, true);
    return this.updateCartRequest()
      .then(data => this.saveShoppingCartState(data))
      .catch(err => this.clearShoppingCartState(err))
      .finally(() => this.processingSubject(1, false));
  }

  generateMultipassLink(link: string) {
    const url = UrlUtils.insertFewValuesToUrl(
      URLS.SHOPIFY_GENERATE_MULTIPASS_LINK,
      {
        token: this.getMultipassToken,
        link
      }
    );

    return this.get(url)
      .then(resp => resp.json());
  }

  private createCartRequest(data): Promise<ShoppingCartInterface> {
    const currentLang = L10nUtils.getCurrentLanguage();
    const url = UrlUtils.insertFewValuesToUrl(
      URLS.SHOPIFY_CHECKOUT_CREATE,
      {lang: currentLang, currency_code: this.currencyService.selectedCurrencyCode}
    );

    return this.post(url, data)
      .then(resp => resp.json());
  }

  private updateCartRequest(): Promise<ShoppingCartInterface> {
    const currentLang = L10nUtils.getCurrentLanguage();
    const url = UrlUtils.insertFewValuesToUrl(
      URLS.SHOPIFY_CHECKOUT_UPDATE,
      {id: this.shoppingCart.checkout_id, lang: currentLang, currency_code: this.currencyService.selectedCurrencyCode}
    );

    return this.put(url, {
      checkout: this.shoppingCart.checkout
    }).then(resp => resp.json());
  }

  private createShoppingCart(variant: ShopifyVariant): Promise<ShoppingCartInterface> {
    const requestData = {
      checkout: [
        {
          variantId: variant.variant_id,
          quantity: variant.quantity,
          customAttributes: variant.custom_attributes
        }
      ]
    };
    this.processingSubject(1, true);
    return this.createCartRequest(requestData)
      .then(data => this.saveShoppingCartState(data))
      .catch(err => this.clearShoppingCartState(err))
      .finally(() => this.processingSubject(1, false));
  }

  private updateShoppingCart(variant: ShopifyVariant): Promise<ShoppingCartInterface> {
    const indexOfVariant = this.getIndexVariant(variant);
    const overallQuantity = AppUtils.overallQuantity(this.shoppingCart, variant, variant?.per_order_limit);
    let availableQuantity = variant.inventory_quantity - overallQuantity;
    if (indexOfVariant !== -1) {
      if (variant.per_order_limit) {
        availableQuantity = variant.per_order_limit - overallQuantity;
        this.shoppingCart.checkout[indexOfVariant].quantity = Math.min(
          this.shoppingCart.checkout[indexOfVariant].quantity + variant.quantity,
          availableQuantity < variant.per_order_limit ?
            this.shoppingCart.checkout[indexOfVariant].quantity + availableQuantity :
            variant.per_order_limit
        );
      } else {
        this.shoppingCart.checkout[indexOfVariant].quantity = Math.min(
          this.shoppingCart.checkout[indexOfVariant].quantity + variant.quantity,
          availableQuantity < variant.inventory_quantity ?
            this.shoppingCart.checkout[indexOfVariant].quantity + availableQuantity :
            variant.inventory_quantity
        );
      }
      (this.shoppingCart.checkout[indexOfVariant] as any).customAttributes = variant.custom_attributes;
    } else {
      if (variant.per_order_limit) {
        availableQuantity = variant.per_order_limit - overallQuantity;
      }
      const shoppingCartVariant = {
        variantId: variant.variant_id,
        quantity: variant.quantity > availableQuantity ? availableQuantity : variant.quantity,
        customAttributes: variant.custom_attributes
      } as CheckoutVariant;
      this.shoppingCart.checkout.push(shoppingCartVariant);
    }
    this.processingSubject(1, true);
    return this.updateCartRequest()
      .then(data => this.saveShoppingCartState(data))
      .catch(err => this.clearShoppingCartState(err))
      .finally(() => this.processingSubject(1, false));
  }

  private onShoppingCartStorageChange({storageArea, key, newValue}: StorageEvent) {
    if (storageArea === window.localStorage && key === this.checkoutId) {
      if (newValue) {
        const checkout = JSON.parse(newValue) as ShoppingCartInterface;
        this.saveShoppingCartState({checkout: checkout});
      } else {
        this.clearShoppingCartState();
      }
    } else if (storageArea === window.localStorage && key === ACCESS_TOKEN_KEY && !newValue) {
      this.clearShoppingCartState();
    } else if (storageArea === window.localStorage && key === CURRENCY_CODE_KEY && newValue && Account.isLogged) {
      this.updateShoppingCartCurrency(newValue);
    }
  }

  private getIndexVariant(variant: ShopifyVariant) {
    return this.shoppingCart.checkout?.findIndex((product) => {
      if (!variant.custom_attributes?.length && !product.customAttributes?.length) {
        return product.variantId === variant.variant_id;
      }
      return product.customAttributes?.length ? Array.from(product.customAttributes).every(({key, value}, index) => {
        return key === variant.custom_attributes?.[index]?.key && value === variant.custom_attributes?.[index]?.value;
      }) : false;
    });
  }

  checkMultipassToken(): Promise<any> {
    const currentUrl = new URL(window.location.href);
    const multipassToken =
      currentUrl.searchParams.get(SHOPIFY_MULTIPASS_TOKEN_KEY) ||
      this.getMultipassToken;

    if (multipassToken) {
      const url = UrlUtils.insertValueToUrl(
        URLS.SHOPIFY_VERIFY_MULTIPASS_TOKEN,
        multipassToken
      );

      return this.get(url)
        .then((resp) => resp.json().then(data => {
          this.isMultipassEnabled = !!data.status.status && this.planogram.isMultipassKeyAvailable;

          if (this.isMultipassEnabled) {
            window.localStorage.setItem(this.mutlipassId, multipassToken);
          } else {
            window.localStorage.removeItem(this.mutlipassId);
          }

          return data;
        }))
        .catch(err => {
          console.warn(err);
          this.clearShoppingCartToken();
        })
        .finally(() => Router.removeParamFromUrl(SHOPIFY_MULTIPASS_TOKEN_KEY));
    }

    return Promise.resolve();
  }

  saveShoppingCartState(data) {
    const checkoutProducts = data.checkout?.products?.map((product: ShoppingCartVariant) => {
      return {
        variantId: product.variant_id,
        quantity: product.quantity,
        customAttributes: product.custom_attributes
      };
    });
    const parsedData = {...data.checkout, checkout: checkoutProducts};
    this.checkoutSubject(1, parsedData);
    if (!Account.isLogged || this.isMultipassEnabled) {
      this.updateShoppingCartState();
      parsedData.last_update = new Date().getTime();
      window.localStorage.setItem(this.checkoutId, JSON.stringify(parsedData));
    }
    return parsedData;
  }

  storeEmail(data) {
    this.email = data?.email;
  }

  updateShoppingCartCurrency(currencyCode) {
    if (!this.shoppingCart) {
      return Promise.resolve();
    }
    const url = UrlUtils.insertFewValuesToUrl(
      URLS.SHOPIFY_CHECKOUT_UPDATE_CURRENCY,
      {id: this.shoppingCart.checkout_id, currency_code: currencyCode, lang: L10nUtils.getCurrentLanguage()}
    );

    this.processingSubject(1, true);
    ShoppingCartService.isProcessing = true;
    return this.put(url, {checkout: this.shoppingCart.checkout})
      .then(resp => resp.json())
      .then(data => this.saveShoppingCartState(data))
      .catch(err => this.clearShoppingCartState(err))
      .finally(() => {
        this.processingSubject(1, false);
        ShoppingCartService.isProcessing = false;
      });
  }

  isShoppingCartOutdated() {
    if (!Account.isLogged && this.shoppingCart?.last_update) {
      const currentTime = new Date().getTime();
      return this.shoppingCart.last_update + ShoppingCartService.SHOPPING_CART_TIME_THRESHOLD < currentTime;
    }

    return false;
  }

  private trackShoppingCartUpdates() {
    AppUtils.requestTimeoutRaf(
      () => this.updateShoppingCartState(),
      ShoppingCartService.SHOPPING_CART_UPDATE_INTERVAL,
      fn => this.cancelAnimationFn = fn
    );
  }

  updateShoppingCartState(forceUpdate: boolean = false) {
    this.clearShoppingCartTimer();
    if (!Account.isLogged && this.isShoppingCartOutdated() || forceUpdate && !ShoppingCartService.isProcessing) {
      this.updateShoppingCartCurrency(this.shoppingCart?.currency_code)
        .finally(() => this.trackShoppingCartUpdates());
      return;
    } else if (this.shoppingCart) {
      this.trackShoppingCartUpdates();
    }
  }

  clearShoppingCartTimer() {
    this.cancelAnimationFn?.();
  }

  dispose() {
    window.removeEventListener('storage', this.onShoppingCartStorageChange);
    this.checkoutSubject(2, true);
    this.processingSubject(2, true);
    this.checkoutSubjectRef();
  }
}
