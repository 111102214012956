import * as THREE from 'three';
import {InputEventUtils} from '../utils/input_event_utils';
import {CookiesManagement} from '../cookies_management';
import {CameraControls} from './camera_controls';
import {SphereApp} from '../sphere_app';
import {sphereEventHandler} from '../custom_event_utils';
import {SPHERE_EVENT_NAMES as EVENTS} from '../event-names';
import {throttle, debounce} from 'throttle-debounce';

export class MouseControls {
  private pointerOnLeftDown: THREE.Vector2;
  private mouseDownEvent: MouseEvent;
  private dragStart: boolean;
  private dragElement: any;
  static INPUT_DELAY_MILISECONDS: number = 150;

  private throttleFunction = throttle(MouseControls.INPUT_DELAY_MILISECONDS, true, (e, func) => { 
    func(e);
  });
  private debounceFunction = debounce(MouseControls.INPUT_DELAY_MILISECONDS, (e, func) => { 
    func(e);
  });
  
  static get MOUSE_BUTTONS() {
    return {
      LEFT: 1,
      MIDDLE: 4,
      RIGHT: 2
    };
  }

  static get WHEEL_ZOOM_FACTOR() { return 0.881; }

  static get TRACKPAD_ZOOM_FACTOR() { return 0.93; }

  constructor(private domElement, private cameraControls: CameraControls, private sphereApp: SphereApp) {
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.domElement.addEventListener('mousedown', this.onMouseDown, { capture: false });
    this.domElement.addEventListener('mousemove', this.onMouseMove, { capture: false });
    this.domElement.addEventListener('mouseup', this.onMouseUp, { capture: false });
    this.domElement.addEventListener('wheel', this.onWheel, { passive: false });
  }

  throttleEvent(e: Event, func: Function) {
    this.throttleFunction(e, func);
    this.debounceFunction(e, func);
  }

  onMouseDown = (event: MouseEvent) => {
    this.cameraControls.clearAnimation();
    this.pointerOnLeftDown = undefined;
    if (event.buttons === MouseControls.MOUSE_BUTTONS.LEFT && InputEventUtils.onSphereSurface(event)) {
      event.preventDefault();
      this.mouseDownEvent = event;
      this.pointerOnLeftDown = new THREE.Vector2(event.clientX, event.clientY);
      this.cameraControls.onMovementStart(this.pointerOnLeftDown);
    }
  }

  onMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;
    if (this.mouseDownEvent) {
      if (!this.dragStart) {
        this.dragElement = this.sphereApp.getDraggableElementAt(x, y);
      }
      this.dragStart = true;          
      event.preventDefault();
      if (event.buttons === MouseControls.MOUSE_BUTTONS.LEFT) {
        this.sphereApp.isClusterSelected = false;

        if (!this.dragElement) { 
          this.cameraControls.tiltAndPanTo({ x, y });
        }
        else {
          const intersection = this.sphereApp.getIntersectionWithElement(this.dragElement, x, y);
          
          if (intersection) {
            this.dragElement.onDrag(intersection);
          }
        }
      } else {
        event.stopPropagation();
      }
    } else {
      this.throttleEvent(event, this.sphereApp.setCursor.bind(this.sphereApp));
    }
  }

  onMouseUp = (event) => {
    const isRedirectAnimationProcessing = CookiesManagement.isRedirectAnimationProcessing;

    if (isRedirectAnimationProcessing) {
      this.cameraControls.clearAnimation(true);
    }
    if (!this.mouseDownEvent) {
      return;
    }
    event.preventDefault();
    if (this._isLeftButton()) {
      if (InputEventUtils.isClick(this.mouseDownEvent, event)) {
        sphereEventHandler.emit(EVENTS.CONTROL.CLICK, event);
        this.sphereApp.inputHandler.handleClick(event.clientX, event.clientY);
      } else {
        event.stopPropagation();
      }
      this.cameraControls.onMovementEnd();
    }
    this.mouseDownEvent = undefined;
    this.dragStart = false;
    this.dragElement = null;
  } 

  onWheel = (event) => {
    this.cameraControls.clearAnimation();
    if (InputEventUtils.onSphereSurface(event)) {
      event.preventDefault();
      this._handleWheel(event);
    } else {
      event.stopPropagation();
    }
  }

  _handleWheel(event) {
    const cursorPoint = { x: event.clientX, y: event.clientY };
    let zoomFactor;
    if (event.deltaY < 0) {
      if (this._isTrackPad(event)) {
        zoomFactor = MouseControls.TRACKPAD_ZOOM_FACTOR;
      } else {
        zoomFactor = MouseControls.WHEEL_ZOOM_FACTOR;
      }
    } else if (event.deltaY > 0) {
      if (this._isTrackPad(event)) {
        zoomFactor = (1 / MouseControls.TRACKPAD_ZOOM_FACTOR);
      } else {
        zoomFactor = (1 / MouseControls.WHEEL_ZOOM_FACTOR);
      }
    }
    
    if (zoomFactor) {
      this.sphereApp.isClusterSelected = false;
      this.cameraControls.zoomToPoint(cursorPoint, zoomFactor);
    }
  }

  _isTrackPad(event) {
    return Number.isInteger(event.deltaY);
  }

  _isLeftButton() {
    return this.pointerOnLeftDown !== undefined;
  }

  dispose() {
    this.domElement.removeEventListener('mousedown', this.onMouseDown, { capture: false });
    this.domElement.removeEventListener('mousemove', this.onMouseMove, { capture: false });
    this.domElement.removeEventListener('mouseup', this.onMouseUp, { capture: false });
    this.domElement.removeEventListener('wheel', this.onWheel, { passive: false });
  }
}
