import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import {shoppingCartPanelView} from './shopping-cart-panel.view';
import {shoppingCartButtonView} from './shopping-cart-button.view';
import {ShoppingCartService} from '../api/services/shopping-cart.service';
import {ShoppingCartItems} from './shopping-cart-items';
import {SphereApp} from '../sphere_app';
import {WebUtils} from '../utils/web_utils';
import {ShoppingCartInterface, ShoppingCartVariant} from '../interfaces/shopping-cart.interface';
import {InputEventUtils} from '../utils/input_event_utils';
import {Metrics} from '../metrics';
import {MATOMO_EVENT_NAMES} from '../metric-events';
import {CurrencyService} from '../api/services/currency.service';
import {CurrencyUtils} from '../utils/currency_utils';
import {FontLoader} from '../font_loader';
import {AppUtils} from '../utils/app_utils';
import {EcommerceOverlaySettings} from '../interfaces/ecommerce-overlay-settings';
import {AppState} from '../shared/app.state';
import {L10nUtils} from '../utils/l10n_utils';
import {OverlayScrollbar} from '../components/overlay_scrollbar';
import {appendHtml} from '../utils/html_utils';
import {UrlUtils} from '../api/urls';
import {shopifyOverlayHandler, sphereEventHandler} from '../custom_event_utils';
import {QuantityPicker} from '../overlays/shopify-product-info/components/quantity-picker/quantity-picker';
import {ECOMMERCE_BUTTON_TYPE} from '../shared/constants';
import { SPHERE_EVENT_NAMES } from '../event-names';

export class ShoppingCartComponent {
  private buttonElement: HTMLElement;
  protected panelElement: HTMLElement;

  private scButtonElement: HTMLElement;
  protected scPanelContainer: HTMLElement;
  private scItemsWrapper: HTMLElement;
  protected scCheckoutButton: HTMLElement;
  private subtotalPriceElement: HTMLElement;
  protected backdropWrapper: HTMLElement;
  private shoppingCartItems: ShoppingCartItems;
  protected overlaySettings: EcommerceOverlaySettings;
  private badgeSettings: EcommerceOverlaySettings;
  protected checkout: ShoppingCartInterface;
  private activeLoadings = 0;
  private static readonly defaultSubtotalPrice = '0.00';
  private readonly overlayScroll: OverlayScrollbar;
  checkoutSubjectRef: Function;
  currencySubjectRef: Function;
  private readonly loaderSubjectRef: Function;

  get panelVisibility() {
    return this.panelElement.classList.contains('is-shopping-cart-panel-active');
  }

  set panelVisibility(value) {
    if (value === true) {
      this.panelElement.classList.add('is-shopping-cart-panel-active');
    } else if (value === false) {
      this.panelElement.classList.remove('is-shopping-cart-panel-active');
    } else {
      console.warn('Shopping Cart Button > incorrect value ' + value);
    }
    shopifyOverlayHandler.emit('shoppingCartVisibility', {value});
  }

  get panel() {
    return this.panelElement;
  }

  get button() {
    return this.buttonElement;
  }

  get hasItemsWithError() {
    return document.querySelector('.app-shopping-cart-item.limit-error');
  }

  constructor(protected sphereApp: SphereApp,
              protected shoppingCartService: ShoppingCartService,
              protected currencyService: CurrencyService,
              protected quantityPicker?: QuantityPicker,
              protected wrapperSelector?: string) {
    this.initView();
    this.handleClicks();

    this.checkoutSubjectRef = pipe(
      this.shoppingCartService.checkoutSubject,
      subscribe({
        next: (checkout: any) => {
          if (
            checkout && checkout.currency_code !== this.currencyService.selectedCurrencyCode &&
            !ShoppingCartService.isProcessing ||
            checkout && checkout.language_code !== L10nUtils.getCurrentLanguage()
          ) {
            this.shoppingCartService.updateShoppingCartCurrency(this.currencyService.selectedCurrencyCode);
          }

          this.getQuantityBadgeProducts(checkout?.products);
          this.updateShoppingCartState(checkout);
          this.updateSubtotalPrice(checkout?.subtotal_price);
        },
        error: () => {
          this.getQuantityBadgeProducts(null);
          this.updateShoppingCartState(null);
          this.updateSubtotalPrice(ShoppingCartComponent.defaultSubtotalPrice);
        }
      })
    );

    this.currencySubjectRef = pipe(
      this.currencyService.changeCurrencySubject,
      subscribe({
        next: (currency) => {
          if (
            this.checkout &&
            this.checkout.currency_code !== this.currencyService.selectedCurrencyCode &&
            !ShoppingCartService.isProcessing
          ) {
            this.shoppingCartService.updateShoppingCartCurrency(currency);
          } else if (!this.checkout) {
            this.updateSubtotalPrice(ShoppingCartComponent.defaultSubtotalPrice);
          }
        }
      })
    );

    this.loaderSubjectRef = pipe(
      this.shoppingCartService.processingSubject,
      subscribe({
        next: (loading: boolean) => {
          this.showLoader(loading);
        }
      })
    );
    this.overlayScroll = new OverlayScrollbar('.app-shopping-cart-items-wrapper');
  }

  protected initView() {
    this.overlaySettings = this.sphereApp?.planogram?.ecommerceOverlaySettingsShoppingCart;
    this.badgeSettings = this.sphereApp?.planogram?.ecommerceOverlaySettingsButtons;

    this.buttonElement = document.createElement('div');
    appendHtml(this.buttonElement, shoppingCartButtonView());

    this.scButtonElement = this.buttonElement.querySelector('.app-shopping-cart-button-wrapper');

    this.panelElement = document.createElement('div');
    const isHarryPotterClient = AppUtils.isHarryPotterClient(
      this.sphereApp.planogram,
      this.sphereApp.planogram.clientName
    );
    appendHtml(
      this.panelElement,
      shoppingCartPanelView(this.overlaySettings, this.shoppingCartService.isMultipassEnabled, isHarryPotterClient)
    );
    document.getElementById('shopping-cart-panel').append(this.panel);

    this.subtotalPriceElement = this.panelElement.querySelector('.app-shopping-cart-subtotal-value');
    this.scItemsWrapper = this.panelElement.querySelector('.app-shopping-cart-items-wrapper');
    this.scCheckoutButton = this.panelElement.querySelector('.app-shopping-cart-checkout-button');
    this.backdropWrapper = this.panelElement.querySelector('.app-shopping-cart-panel-backdrop');
    this.scPanelContainer = this.panelElement.querySelector('.app-shopping-cart-panel-container');

    this.shoppingCartItems = new ShoppingCartItems(
      this.panelElement,
      this.shoppingCartService,
      this.currencyService,
      this.navigateToProduct.bind(this)
    );

    this.setOverlayFonts();
    this.setColorAndButtonSettings(this.overlaySettings, 'ecommerce-shopping-cart');
    this.setColorAndButtonSettings(this.badgeSettings, 'ecommerce-buttons');
  }

  protected getQuantityBadgeProducts(checkout) {
    const productsQuantity = checkout?.length
      ? checkout?.reduce((sum, product) => sum + product.quantity, 0)
      : null;
    const badgeCounter = this.scButtonElement.querySelector('#badge-counter');
    badgeCounter.classList.remove('app-shopping-cart-control-button-badge-counter');

    if (productsQuantity) {
      badgeCounter.classList.add('app-shopping-cart-control-button-badge-counter');
      (badgeCounter as HTMLElement).innerText = productsQuantity > 9 ? '9+' : `${productsQuantity}`;
    }
  }

  protected showLoader(isActive = false) {
    isActive ? this.activeLoadings++ : this.activeLoadings--;

    if (this.activeLoadings <= 0) {
      this.scButtonElement?.classList.remove('loading');
      this.scPanelContainer.classList.remove('loading');
      this.activeLoadings = 0;
    } else {
      this.scButtonElement?.classList.add('loading');
      this.scPanelContainer.classList.add('loading');
    }
  }

  protected setOverlayFonts() {
    this.overlaySettings?.ecommerce_fonts.forEach(font => {
      const fontSettings = {...font, assignment: `ecommerce-shopping-cart-${font.assignment}`};
      FontLoader.mountCustomFont(fontSettings);
    });
  }

  protected setColorAndButtonSettings(settings: EcommerceOverlaySettings, assignment: string) {
    const root = document.documentElement;
    if (settings?.background_color) {
      const primaryColorRgb = AppUtils.hex2rgb(settings.background_color);

      root.style.setProperty(`--${assignment}-primary-color`, `${settings.background_color}`);
      root.style.setProperty(`--${assignment}-primary-color-rgb`, `${primaryColorRgb}`);
    }

    if (settings?.secondary_color) {
      const secondaryColorRgb = AppUtils.hex2rgb(settings.secondary_color);

      root.style.setProperty(`--${assignment}-secondary-color`, `${settings.secondary_color}`);
      root.style.setProperty(`--${assignment}-secondary-color-rgb`, `${secondaryColorRgb}`);
    }

    if (settings?.controls_color) {
      const controlsColorRgb = AppUtils.hex2rgb(settings.controls_color);

      root.style.setProperty(`--${assignment}-controls-color`, `${settings.controls_color}`);
      root.style.setProperty(`--${assignment}-controls-color-rgb`, `${controlsColorRgb}`);
    }

    if (settings?.ecommerce_buttons?.length) {
      settings?.ecommerce_buttons.forEach(button => {
        if (button.color) {
          root.style.setProperty(`--${assignment}-${button.button_type}-button-color`, `${button.color}`);
        }
        if (button.color_disabled) {
          root.style.setProperty(
            `--${assignment}-${button.button_type}-button-color-disabled`,
            `${button.color_disabled}`
          );
        }
        if (button.use_border) {
          root.style.setProperty(
            `--${assignment}-${button.button_type}-button-border-color`,
            `${button.border_color}`
          );
          root.style.setProperty(
            `--${assignment}-${button.button_type}-button-border-width`,
            `${button.border_width.replace(/px/ig, '')}px`
          );
        }
        if (button.use_border_disabled) {
          root.style.setProperty(
            `--${assignment}-${button.button_type}-button-border-color-disabled`,
            `${button.border_color_disabled}`
          );
          root.style.setProperty(
            `--${assignment}-${button.button_type}-button-border-width-disabled`,
            `${button.border_width_disabled.replace(/px/ig, '')}px`
          );
        }
      });
    }

    if (settings?.ecommerce_icon_buttons?.length) {
      settings?.ecommerce_icon_buttons.forEach(button => {
        if (button.color) {
          root.style.setProperty(`--${assignment}-${button.button_type}-button-color`, `${button.color}`);
        }
        if (button.button_type === ECOMMERCE_BUTTON_TYPE.SHOPPING_CART && button.other_asset?.url) {
          const imgElem = document.createElement('img');
          imgElem.onerror = () => {
            this.scButtonElement.querySelector('img').remove();
          };
          imgElem.setAttribute('src', button.other_asset?.url);
          this.scButtonElement.appendChild(imgElem);
        }
      });
    }
  }

  protected handleClicks() {
    if (this.scButtonElement) {
      InputEventUtils.addSelectEvents(
        this.scButtonElement,
        () => {
          Metrics.storeTheEvent(
            AppState.planogramName,
            'open',
            MATOMO_EVENT_NAMES.OPEN_SHOPPING_CART

          );
          this.panelVisibility = true;
          sphereEventHandler.emit(SPHERE_EVENT_NAMES.CART.OPEN);
        }
      );
    }

    InputEventUtils.addSelectEvents(
      this.scCheckoutButton,
      () => {
        if (!this.scCheckoutButton.classList.contains('app-disabled')) {
          Metrics.storeTheEvent(
            AppState.planogramName,
            'click',
            MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_CHECKOUT_BUTTON
          );

          const checkoutUrl = UrlUtils.getUrlWithQueries(this.checkout?.checkout_url, {
            'locale': L10nUtils.getCurrentLanguage(),
            'planogram_id': this.sphereApp.planogram.id.toString()
          });

          if (this.shoppingCartService.getMultipassToken) {
            const link = window.open();
            this.scCheckoutButton.classList.add('loading');
            this.shoppingCartService.generateMultipassLink(checkoutUrl)
              .then(resp => link.location = resp?.link)
              .catch(() => link.location = checkoutUrl as any)
              .finally(() => this.scCheckoutButton.classList.remove('loading'));
          } else {
            WebUtils.openLink(checkoutUrl);
          }
        }
      }
    );

    const closeButton = this.panelElement.querySelector('.app-shopping-cart-close-button');

    InputEventUtils.addSelectEvents(
      closeButton,
      () => {
        Metrics.storeTheEvent(
          AppState.planogramName,
          'click',
          MATOMO_EVENT_NAMES.CLICK_SHOPPING_CART_OVERLAY_CLOSE_BUTTON
        );
        this.panelVisibility = false;
        sphereEventHandler.emit(SPHERE_EVENT_NAMES.CART.CLOSE);
      }
    );

    InputEventUtils.addSelectEvents(
      this.backdropWrapper,
      () => {
        this.panelVisibility = false;
        sphereEventHandler.emit(SPHERE_EVENT_NAMES.CART.CLOSE);
      }
    );
  }

  protected updateShoppingCartState(checkout) {
    this.checkout = checkout;
    this.updateCheckoutButtonState();
    if (checkout?.products?.length) {
      this.scItemsWrapper?.classList.remove('empty');
    } else {
      this.scItemsWrapper?.classList.add('empty');
    }
  }

  private navigateToProduct(action) {
    this.panelVisibility = false;
    this.sphereApp.redirectToProduct.call(this.sphereApp, action);
  }

  protected updateCheckoutButtonState() {
    const isCheckoutAvailable = this.checkout?.products.some((product: ShoppingCartVariant) => {
      return product.quantity_available > 0;
    });

    if (!isCheckoutAvailable || this.hasItemsWithError) {
      this.scCheckoutButton.classList.add('app-disabled');
    } else {
      this.scCheckoutButton.classList.remove('app-disabled');
    }
  }

  protected updateSubtotalPrice(subtotalPrice) {
    const {selectedCurrencyCode, currenciesList} = this.currencyService;
    this.subtotalPriceElement.innerHTML = CurrencyUtils.getPriceWithSymbolOrCode(
      subtotalPrice,
      selectedCurrencyCode,
      currenciesList
    );
  }

  dispose() {
    this.checkoutSubjectRef?.();
    this.currencySubjectRef?.();
    this.loaderSubjectRef();
  }
}
