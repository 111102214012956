import {Group, Material, Matrix4, Mesh, Object3D, Vector2, Vector3, WebGLRenderer} from 'three';
import {XRControllerModelFactory} from 'three/examples/jsm/webxr/XRControllerModelFactory';
import {disposeObject3D} from '../utils/disposeThree';
export class VRController {
  raycaster;

  intersected = [];
  tempMatrix = new Matrix4();

  private controller: Group;
  private model: Group;
  selected: boolean = false;
  private previousPosition: Vector3;
  private previousRotation: Vector3;

  constructor(renderer: WebGLRenderer, index: number, private dolly: Object3D) {
    this.controller = renderer.xr.getController(index);
    this.model = renderer.xr.getControllerGrip(index);
    const controllerModelFactory = new XRControllerModelFactory();

    this.model.add(controllerModelFactory.createControllerModel(this.model));
    dolly.add(this.controller);
    dolly.add(this.model);
    this.controller.addEventListener('selectstart', this.onSelectStart);
    this.controller.addEventListener('selectend', this.onSelectEnd);
  }

  deltaPosition(): Vector3 {
    if (!this.previousPosition || this.previousRotation) {
      this.previousPosition = this.controller.position.clone();
      return new Vector3(0, 0, 0);
    }
    const delta = this.previousPosition.clone().sub(this.controller.position);
    this.previousPosition.copy(this.controller.position);
    return delta;
  }

  deltaRotation(): number {
    if (!this.previousRotation || this.previousPosition) {
      this.previousRotation = new Vector3(this.controller.position.x, 0, this.controller.position.z);
      return 0;
    }

    const positionWithoutY = new Vector3(this.controller.position.x, 0, this.controller.position.z);
    const delta =
      positionWithoutY.angleTo(this.previousRotation) * Math.sign(this.previousRotation.x - this.controller.position.x);

    this.previousRotation = positionWithoutY;
    return delta;
  }

  onSelectStart = event => {
    this.selected = true;
    this.previousPosition = undefined;
    this.previousRotation = undefined;
  };

  onSelectEnd = event => {
    this.selected = false;
  };

  dispose() {
    this.controller.removeEventListener('selectstart', this.onSelectStart);
    this.controller.removeEventListener('selectend', this.onSelectEnd);

    disposeObject3D(this.controller);
    disposeObject3D(this.model);
  }
}
