import {CookiesManagement} from './cookies_management';
import Router from './router';
import {DIRECTION, PRODUCT_PAGE_ROUTES} from './shared/constants';
import {SphereApp} from './sphere_app';
import {ACTION_TYPE, AnimateActionData} from './interfaces/planogram.interface';
import {Overlay} from './overlay';
import {Planogram} from './planogram';
import {CameraControls} from './controls/camera_controls';
import {Sphere} from './sphere';
import * as THREE from 'three';
import {Camera} from './camera';
import {RaycastControls} from './controls/raycast_controls';
import {ProductNavigationData} from './interfaces/product.interface';
import {SphereItem} from './sphere_item';
import {AppUtils} from './utils/app_utils';

export abstract class BaseInputHandler {
  protected autoplayStarted: boolean = false;
  protected autoplaySpeedTime: number;
  protected autoplayTimeoutId: NodeJS.Timeout;
  protected repeatCallback: Function;
  protected autoplayAnimation: any;
  overlay: Overlay;
  protected isAnimatingToProduct: boolean;
  isClusterSelected: boolean;
  selectedCluster: string;
  sphereApp: SphereApp;
  planogram: Planogram;
  cameraControls: CameraControls;
  raycastControls: RaycastControls;
  sphere: Sphere;
  camera: Camera;
  scene: THREE.Scene;

  constructor(sphereApp: SphereApp) {
    this.sphereApp = sphereApp;
  }

  init(
    planogram: Planogram,
    cameraControls: CameraControls,
    raycastControls: RaycastControls,
    sphere: Sphere,
    camera: Camera,
    scene: THREE.Scene
  ) {
    this.planogram = planogram;
    this.cameraControls = cameraControls;
    this.raycastControls = raycastControls;
    this.sphere = sphere;
    this.camera = camera;
    this.scene = scene;
  }

  abstract setProductOverlay(productIdentifier: string | number, overlayType);

  abstract handleClick(x: number, y: number);

  abstract handleAction(item);

  abstract navigateToAutoplayClusters();

  abstract autoplay();

  abstract animateCameraToCluster(clusterIdentification: string, upArrow?: boolean, callback?: Function): void;

  abstract animateToClusterAfterLoad(clusterName: string);

  redirectToProduct(action: AnimateActionData | string, productName?: string) {
    let data;

    if ((action as AnimateActionData).itemId) {
      data = {
        planogramName: (action as AnimateActionData).sphereName,
        productIdentifier: (action as AnimateActionData).productIdentifier,
        productName: (action as AnimateActionData).productName || '',
        overlayView: PRODUCT_PAGE_ROUTES.GALLERY
      };
    } else {
      data = this.parseProductNavigationData(action as string, productName);
    }

    if (this.planogram.name === data.planogramName) {
      this.sphereApp.search.showSphereElements();
      this.overlay.hide();
      this.resetNavigation();
      const item = this.sphere.findSphereItemByIdentifier(data.productIdentifier);
      this.animateToProduct(item, () => {
        this.setProductOverlay(item.identifier, data.overlayView);
      });
      Router.navigateToProduct(item, data.overlayView);
    } else {
      const item = {
        planogram: {
          name: data.planogramName
        },
        name: data.productName || '',
        identifier: data.productIdentifier
      } as SphereItem;
      this.sphereApp.isSphereLoaded = false;
      Router.navigateToProduct(item, data.overlayView);
    }
  }

  protected parseProductNavigationData(action: string, productName?: string): ProductNavigationData {
    const separator = action.includes('#') ? '#' : '-';
    const parsedString = action.split(separator).slice(1);
    let overlayView: PRODUCT_PAGE_ROUTES;
    let planogramName = parsedString.shift();
    if (planogramName === PRODUCT_PAGE_ROUTES.SHOW) {
      overlayView = PRODUCT_PAGE_ROUTES.SHOW;
      planogramName = parsedString.shift();
    }
    const productIdentifier = parsedString.join('-');

    return {
      planogramName,
      productIdentifier,
      productName,
      overlayView
    };
  }

  isShowingOverlay(): boolean {
    return this.overlay.isShowing();
  }

  startAutoplay() {
    this.autoplayStarted = true;
    this.autoplaySpeedTime =
      this.sphereApp.planogram.animationSettings.autoplay_delay + this.sphereApp.planogram.animationSettings.duration;
    this.autoplay();
  }

  resetNavigation() {
    if (!window.location.pathname.endsWith(this.planogram.name)) Router.navigateToPlanogram(this.planogram.name);
  }

  resetAutoplay() {
    clearTimeout(this.autoplayTimeoutId);
    this.autoplayStarted = false;
    this.repeatCallback = null;
    this.autoplayTimeoutId = null;
    this.cameraControls.removeAnimation(this.autoplayAnimation);
    Router.removeClusterAutoplayState();
  }

  cycleThroughClusters(direction: DIRECTION, isAutoplay?: boolean): void {
    if (!(this.planogram.clustersOrder && this.planogram.clustersOrder.length)) {
      return;
    }
    const clusterOrderIndex = this.planogram.clustersOrder.indexOf(`cluster-${this.selectedCluster}`);
    const clustersMaxIndex = this.planogram.clustersOrder.length - 1;
    let nextIndex = 0;
    if (clusterOrderIndex >= 0) {
      nextIndex = clusterOrderIndex;
      if (direction === DIRECTION.RIGHT) {
        nextIndex = clusterOrderIndex + 1;
      } else if (direction === DIRECTION.LEFT) {
        nextIndex = clusterOrderIndex - 1;
      }
    } else if (direction === DIRECTION.LEFT) {
      nextIndex = clustersMaxIndex;
    }

    if (nextIndex > clustersMaxIndex) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = clustersMaxIndex;
    }
    const clusterIdentification = this.planogram.clustersOrder[nextIndex];
    Router.navigateToCluster(this.planogram.name, AppUtils.extractClusterName(clusterIdentification), {
      autoplay: isAutoplay
    });
  }

  animateCameraToClusterByArrows(direction: DIRECTION): void {
    switch (direction) {
      case DIRECTION.UP: {
        if (this.selectedCluster) {
          this.animateCameraToCluster(this.selectedCluster, true);
        } else if (this.planogram.clustersOrder?.length) {
          Router.navigateToCluster(this.planogram.name, AppUtils.extractClusterName(this.planogram.clustersOrder[0]));
        }
        break;
      }
      case DIRECTION.DOWN: {
        if (this.selectedCluster) {
          this.cameraControls.animateZoomFov();
        }
        break;
      }
      case DIRECTION.LEFT:
      case DIRECTION.RIGHT: {
        this.cycleThroughClusters(direction);
      }
    }
  }

  animateCameraToProductImage(itemIdentifier: string, openProductOverlay = true): void {
    const item = this.sphere.findSphereItemByIdentifier(itemIdentifier);
    this.animateToProduct(item, () => {
      if (openProductOverlay) {
        Router.navigateToProduct(item);
      }
    });
  }

  animateCameraToIdentifer(identifier: string, openProductOverlay = true): void {
    const item = this.sphere.findSphereItemByIdentifier(identifier);
    if (item) {
      this.animateToProduct(item, () => {
        if (openProductOverlay) {
          this.overlay.showItem(item, PRODUCT_PAGE_ROUTES.GALLERY);
          Router.navigateToProduct(item);
        }
      });
    }
  }

  animateToProductAfterLoad(productIdentifier: string, overlayType?: PRODUCT_PAGE_ROUTES) {
    const item = this.sphere.findSphereItemByIdentifier(productIdentifier);
    const delay = this.sphereApp.isSphereLoaded ? 0 : SphereApp.AFTER_LOAD_ANIMATION_DELAY;
    const afterAnimationEnd = () => {
      CookiesManagement.isRedirectAnimationProcessing = false;
      CookiesManagement.init();
      this.overlay.showItem(item, overlayType);
    };
    const fn = () => {
      CookiesManagement.isRedirectAnimationProcessing = true;
      this.animateToProduct(item, afterAnimationEnd, {duration: undefined, delay});
    };

    this.sphereApp.isSphereLoaded ? fn() : this.sphereApp.afterLoadQueue.push(fn);
  }

  animateToItemAfterLoad(item: SphereItem, action?: boolean, overlay?: boolean) {
    const delay = this.sphereApp.isSphereLoaded ? 0 : SphereApp.AFTER_LOAD_ANIMATION_DELAY;
    const afterAnimationEnd = () => {
      CookiesManagement.isRedirectAnimationProcessing = false;
      CookiesManagement.init();
      if (action) {
        if (overlay) {
          this.overlay.showItem(item);
        } else {
          if (item.action.type === ACTION_TYPE.ANIMATE)
            this.autoplayTimeoutId = setTimeout(() => {
              this.handleAction(item);
            }, this.planogram.animationSettings.autoplay_delay);
          else this.handleAction(item);
        }
      }
    };
    const fn = () => {
      CookiesManagement.isRedirectAnimationProcessing = true;
      this.animateToProduct(item, afterAnimationEnd, {duration: undefined, delay});
    };

    this.sphereApp.isSphereLoaded ? fn() : this.sphereApp.afterLoadQueue.push(fn);
  }

  animateToImageAfterLoad(itemIdentifier: string, action?: boolean) {
    const item = this.sphere.findSphereItemByIdentifier(itemIdentifier);
    const delay = this.sphereApp.isSphereLoaded ? 0 : SphereApp.AFTER_LOAD_ANIMATION_DELAY;
    const afterAnimationEnd = () => {
      CookiesManagement.isRedirectAnimationProcessing = false;
      CookiesManagement.init();
      if (action) {
        this.overlay.showImage(item);
      }
    };
    const fn = () => {
      CookiesManagement.isRedirectAnimationProcessing = true;
      this.animateToProduct(item, afterAnimationEnd, {duration: undefined, delay});
    };

    this.sphereApp.isSphereLoaded ? fn() : this.sphereApp.afterLoadQueue.push(fn);
  }

  animateToProduct(item, afterAnimationEnd?: Function, options?: {duration: number; delay: number}) {
    if (item) {
      this.cameraControls.clearAnimation();
      this.autoplayAnimation = this.cameraControls.animateTo(
        item,
        () => {
          if (afterAnimationEnd) {
            afterAnimationEnd();
          }
        },
        options
      );
    }
  }

  isOverlayShowing() {
    return this.overlay?.isShowing();
  }

  handleRightOnOverlay() {
    this.overlay.handleRightKey();
  }

  handleLeftOnOverlay() {
    this.overlay.handleLeftKey();
  }

  hideOverlay(withNavigation?: boolean) {
    if (this.overlay) {
      this.overlay.hide(withNavigation);
    }
  }
}
