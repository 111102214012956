import Navigo from 'navigo';
import {SphereItem} from './sphere_item';
import {UrlUtils} from './api/urls';
import {L10nUtils} from './utils/l10n_utils';
import {INFO_OVERLAYS, PRODUCT_PAGE_ROUTES, SPHERE_ITEM_TYPES} from './shared/constants';
import {AnimateActionData, ContentOverlayActionData} from './interfaces/planogram.interface';

export default class Router {
  private static router: Navigo;

  static get NAVIGATION_PAGES() {
    return {
      PLANOGRAM: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)$/,
      PLANOGRAM_FALLBACK: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)/,
      IMAGE: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/i([\w]+(?:(?!--|\/).)*)?\/?--([\w-_.]+)?(\/action)?$/,
      VIDEO: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/y([\w-]+)$/,
      TEXT: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/t([\w-]+)(\/action)?$/,
      SHAPE: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/s([\w-]+)(\/action)?$/,
      IFRAME: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/f([\w-]+)$/,
      PRODUCT_BY_CODE_AND_NAME:
        /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/p([\w]+(?:(?!--|\/).)*)(?:-{2})?([\w-]+)?\/?([\w-]+)?$/,
      SEARCH_OVERLAY: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/search$/,
      VIDEO_OVERLAY:
        /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/v([\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}|[\d]+)(?:--([\w-]+))?$/,
      INFO_OVERLAY: new RegExp(
        `^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/infos\/(${Object.values(INFO_OVERLAYS).join('|')})$`
      ),
      CLUSTER_AREA: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/c(\w+(?:(?!--|\/).)*)?\/?$/,
      CONTENT_OVERLAY: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/o(\w+)\/?$/,
      SOCIAL_MEDIA_OVERLAY: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/sm([\w-]+)$/,
      NAVIGATION_MENU: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/nm([\w-]+)$/,
      OTHER: '*'
    };
  }

  static init(root: string, useHash?: boolean, hash?: string) {
    if (!this.router) {
      this.router = new Navigo(root, useHash, hash);
    }
  }

  static on(path, handler, options?) {
    this.router.on(path, handler, options).resolve();
  }

  static withLang(fn: Function): Function {
    return (lang, ...rest) => {
      const currentLang = L10nUtils.getCurrentLanguage();

      if (!lang) {
        L10nUtils.initialize().then(() => {
          const {pathname, search} = window.location;
          this.navigate(`/${pathname}${search}`, {replace: true, silent: true});
        });
      } else if (currentLang !== lang) {
        void L10nUtils.selectLanguage(lang);
      }

      fn(...rest);
    };
  }

  static removeParamFromUrl(param: string) {
    const lastResolvedUrl = this.router.lastRouteResolved();
    const formattedUrl = lastResolvedUrl.url?.replace(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//, '');
    const search = new URLSearchParams(window.location.search) as URLSearchParams;
    search.delete(param);
    return this.navigate(`/${formattedUrl}${search.toString().length ? '?' + search.toString() : ''}`, {
      replace: true,
      silent: true
    });
  }

  static navigate(url: string, {replace = false, silent = false, langCode = undefined} = {}) {
    const lang = langCode ?? L10nUtils.getCurrentLanguage();
    const urlWithLang = `/${lang}${url}`;

    // tslint:disable:no-unused-expression
    replace && this.router.historyAPIUpdateMethod('replaceState');
    silent && this.router.pause();
    this.router.navigate(urlWithLang);
    silent && this.router.pause(false);
    replace && this.router.historyAPIUpdateMethod('pushState');
    // tslint:enable:no-unused-expression
  }

  static updateLangCode(langCode: string) {
    L10nUtils.setCurrentLanguage(langCode);
    this.navigate(`/${L10nUtils.getCurrentPlanogram()}${window.location.search}`);
  }

  static navigateToItemOnSphere(url: string, activate: boolean = false, {replace = false, silent = false} = {}) {
    this.navigate(`${url}${activate ? '/action' : ''}`, {replace, silent});
  }

  static navigateToCluster(planogramName: string, clusterName: string, options?: {autoplay: boolean}) {
    const urlParameters = options?.autoplay ? '?autoplay=true' : '';
    this.navigate(`/${planogramName}/c${clusterName}${urlParameters}`);
  }

  static removeClusterAutoplayState() {
    if (UrlUtils.getQueryValueFromUrl('autoplay')) {
      const lastRoute = this.router.lastRouteResolved();
      const currentLang = L10nUtils.getCurrentLanguage();
      const urlWithoutLangSlug = lastRoute.url.replace(`/${currentLang}`, '');
      this.navigate(urlWithoutLangSlug);
    }
  }

  static navigateToSearch(planogramName: string, isSearchActive: boolean) {
    const searchSegment = isSearchActive ? 'search' : '';
    this.navigate(`/${planogramName}/${searchSegment}`);
  }

  static updateSearchQuery(planogramName: string, query: string) {
    const encodedQuery = encodeURIComponent(query);
    this.navigate(`/${planogramName}/search?query=${encodedQuery}`, {replace: true, silent: true});
  }

  static navigateToPlanogram(planogramName: string) {
    this.navigate(`/${planogramName}`);
  }

  static navigateToPlanogramWithLang(planogramName: string, lang: string) {
    this.navigate(`/${planogramName}`, {langCode: lang});
  }

  static navigateToProduct(sphereItem: SphereItem, overlayView?: PRODUCT_PAGE_ROUTES) {
    this.navigateToItemOnSphere(Router.generateProductUrl(sphereItem, overlayView));
  }

  static navigateToImage(imageId: string, imageName: string, planogramName: string, activate: boolean) {
    this.navigateToItemOnSphere(Router.generateImageUrl(imageId, imageName, planogramName), activate);
  }

  static navigateToIframeId(id: string, planogramName: string) {
    this.navigateToItemOnSphere(Router.generateIframeUrl(id, planogramName));
  }

  static navigateToIframe(iframe: SphereItem) {
    const planogramName = iframe.planogram.name;
    const iframeId = (iframe.action?.data as AnimateActionData)?.itemUuid || (iframe.id as string) || iframe.identifier;
    this.navigateToItemOnSphere(Router.generateIframeUrl(iframeId, planogramName));
  }

  static navigateToSocialMedia(item: SphereItem) {
    const planogramName = item.planogram.name;
    const itemId = (item.action?.data as AnimateActionData)?.itemUuid || (item.id as string) || item.identifier;
    this.navigateToItemOnSphere(Router.generateSocialMediaUrl(itemId, planogramName));
  }

  static navigateToContentOverlay(item: SphereItem) {
    const actionData = (item.action?.data as ContentOverlayActionData)!;
    this.navigateToItemOnSphere(Router.generateContentOverlayUrl(actionData.iframeLink, item.planogram.name));
  }

  static navigateToVideo(id: string, planogramName: string) {
    this.navigateToItemOnSphere(Router.generateVideoUrl(id, planogramName));
  }

  static navigateToText(id: string, planogramName: string, activate: boolean) {
    this.navigateToItemOnSphere(Router.generateTextUrl(id, planogramName), activate);
  }

  static navigateToShapeOrCurve(id: string, planogramName: string, activate: boolean) {
    this.navigateToItemOnSphere(Router.generateShapeUrl(id, planogramName), activate);
  }

  static navigateToProductId(productId: string, planogramName: string, overlayView?: PRODUCT_PAGE_ROUTES) {
    this.navigateToItemOnSphere(Router.generateProductUrlOnOtherSphere(productId, planogramName, overlayView));
  }

  static navigateToProductIdAndName(
    productId: string,
    productName: string,
    planogramName: string,
    overlayView?: PRODUCT_PAGE_ROUTES
  ) {
    this.navigateToItemOnSphere(
      Router.generateProductUrlOnOtherSphere(productId, planogramName, overlayView, productName)
    );
  }

  static navigateToProductWithReplace(sphereItem: SphereItem, overlayView?: PRODUCT_PAGE_ROUTES) {
    this.navigateToItemOnSphere(Router.generateProductUrl(sphereItem, overlayView), false, {replace: true});
  }

  static navigateToCheckout(sphereItem: SphereItem) {
    this.navigate(Router.generateProductUrl(sphereItem, PRODUCT_PAGE_ROUTES.CHECKOUT));
  }

  static navigateToVideoOverlay(video: SphereItem) {
    const planogramName = video.planogram.name;
    const videoNameUrlSegment = video.name ? `--${UrlUtils.slugify(video.name)}` : '';
    const videoId = (video.action?.data as AnimateActionData).itemUuid || video.id;
    this.navigateToItemOnSphere(`/${planogramName}/v${videoId}${videoNameUrlSegment}`);
  }

  static navigateToInfoOverlay(planogramName: string, overlayType: string) {
    this.navigate(`/${planogramName}/infos/${overlayType}`);
  }

  static navigateToNavigationMenu(planogramName: string, id: string) {
    this.navigateToItemOnSphere(Router.generateNavigationMenuUrl(id, planogramName));
  }

  static generateProductUrl(product: SphereItem, overlayView?: PRODUCT_PAGE_ROUTES, withLang = false): string {
    const planogramName = product.planogram.name;
    const productNameUrlSegment = product.name ? `--${UrlUtils.slugify(product.name)}` : '';
    let overlayViewUrlSegment = '';
    if (overlayView && overlayView !== PRODUCT_PAGE_ROUTES.GALLERY) {
      overlayViewUrlSegment = '/' + overlayView.toString().toLowerCase();
    }
    const productId = `${product.identifier}${productNameUrlSegment}${overlayViewUrlSegment}`;
    return this.generateItemUrl(productId, SPHERE_ITEM_TYPES.PRODUCT, planogramName, withLang);
  }

  static generateImageUrl(imageId: string, imageName: string, planogramName: string, withLang = false) {
    return this.generateItemUrl(`${imageId}--${imageName}`, SPHERE_ITEM_TYPES.IMAGE, planogramName, withLang);
  }

  static generateIframeUrl(id: string, planogramName: string, withLang = false) {
    return this.generateItemUrl(id, SPHERE_ITEM_TYPES.IFRAME, planogramName, withLang);
  }

  static generateSocialMediaUrl(id: string, planogramName: string, withLang = false) {
    return this.generateItemUrl(id, SPHERE_ITEM_TYPES.SOCIAL_MEDIA_OVERLAY, planogramName, withLang);
  }

  static generateContentOverlayUrl(link: string, planogramName: string, withLang = false) {
    return this.generateItemUrl(link, SPHERE_ITEM_TYPES.CONTENT_OVERLAY, planogramName, withLang);
  }

  static generateVideoUrl(id: string, planogramName: string, withLang = false) {
    return this.generateItemUrl(id, SPHERE_ITEM_TYPES.VIDEO, planogramName, withLang);
  }

  static generateTextUrl(id: string, planogramName: string, withLang = false) {
    return this.generateItemUrl(id, SPHERE_ITEM_TYPES.TEXT_AREA, planogramName, withLang);
  }

  static generateShapeUrl(id: string, planogramName: string, withLang = false) {
    return this.generateItemUrl(id, SPHERE_ITEM_TYPES.SHAPE, planogramName, withLang);
  }

  static generateNavigationMenuUrl(id: string, planogramName: string, withLang = false) {
    return this.generateItemUrl(id, SPHERE_ITEM_TYPES.NAVIGATION_MENU, planogramName, withLang);
  }

  static generateProductUrlOnOtherSphere(
    productId: string,
    planogramName: string,
    overlayView?: PRODUCT_PAGE_ROUTES,
    productName: string = '',
    withLang = false
  ): string {
    let overlayViewUrlSegment = '';
    if (overlayView && overlayView !== PRODUCT_PAGE_ROUTES.GALLERY) {
      overlayViewUrlSegment = '/' + overlayView.toString().toLowerCase();
    }
    productName = productName ? `--${productName}` : '';
    const productUrlId = `${productId}${productName}${overlayViewUrlSegment}`;

    return this.generateItemUrl(productUrlId, SPHERE_ITEM_TYPES.PRODUCT, planogramName, withLang);
  }

  static generateItemUrl(id: string, type: SPHERE_ITEM_TYPES, planogramName: string, withLang = false) {
    const langSegment = withLang ? `/${L10nUtils.getCurrentLanguage()}` : '';
    let itemType;

    switch (type) {
      case SPHERE_ITEM_TYPES.CURVE:
      case SPHERE_ITEM_TYPES.SHAPE:
        itemType = 's';
        break;
      case SPHERE_ITEM_TYPES.VIDEO:
        itemType = 'y';
        break;
      case SPHERE_ITEM_TYPES.IFRAME:
        itemType = 'f';
        break;
      case SPHERE_ITEM_TYPES.TEXT_AREA:
        itemType = 't';
        break;
      case SPHERE_ITEM_TYPES.IMAGE:
        itemType = 'i';
        break;
      case SPHERE_ITEM_TYPES.PRODUCT:
        itemType = 'p';
        break;
      case SPHERE_ITEM_TYPES.CONTENT_OVERLAY:
        itemType = 'o';
        break;
      case SPHERE_ITEM_TYPES.SOCIAL_MEDIA_OVERLAY:
        itemType = 'sm';
        break;
      case SPHERE_ITEM_TYPES.NAVIGATION_MENU:
        itemType = 'nm';
        break;
    }

    return `${langSegment}/${planogramName}/${itemType}${id}`;
  }

  static generateShareableLink(path: string) {
    const url = new URL(window.location.href);
    url.pathname = path;
    url.search = '';
    return url.href;
  }
}
