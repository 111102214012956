import * as THREE from 'three';
import {
  ImageMetaData,
  ItemData,
  ItemTile,
  LODSMetaData,
  PlanogramData,
  VirtualTextureData
} from '../interfaces/planogram.interface';
import {DynamicElementsPageCache} from './dynamic_elements_page_cache';
import {Planogram} from '../planogram';
import {Camera} from '../camera';
import {Vector2, Box2, Object3D} from 'three';
import {throttle} from 'throttle-debounce';
import textureVertexShader from '../../shaders/texture_vertex_shader.glsl';
import textureFragmentShader from '../../shaders/item_fragment_shader.glsl';
import {Page} from '../interfaces/vt_pipeline.interface';
import {Modulo} from '../utils/moduloUtils';
import {ArrayUtils} from '../utils/array_utils';
import {VRService} from '../vr/vr_service';
import {SphereItem} from '../sphere_item';

const pagesModulo = new Modulo(PlanogramData.pages());

function bestLod(item: ItemData<LODSMetaData>) {
  return item.data.lods.reduce((best, lod) => Math.min(best, lod.lod), +Infinity) ?? +Infinity;
}

function worstLod(item: ItemData<LODSMetaData>) {
  return item.data.lods.reduce((worst, lod) => Math.max(worst, lod.lod), -Infinity) ?? -Infinity;
}

interface PageLimitsData {
  topY?: number;
  bottomY?: number;
  leftX?: number;
  rightX?: number;
}
export class VTPipeline {
  readonly dynamicElementsPageCache: DynamicElementsPageCache;
  private camera: Camera;
  readonly planogram: Planogram;

  private pageRequestsPerFrame: number;
  private disposed: boolean;
  private requestedLod: number;
  private viewport = new Box2(new Vector2(), new Vector2());
  private items: Map<string, Object3D>;

  private itemsLODMap: Array<Array<Array<Array<Page>>>>;
  private pickLodMap(lod: number) {
    let lodMap = this.itemsLODMap[lod];
    if (!lodMap) {
      const bestMatch = this.itemsLODMap.reduce(
        (bestMatch, _, i) => (Math.abs(bestMatch - lod) < Math.abs(i - lod) ? bestMatch : i),
        0
      );
      lodMap = this.itemsLODMap[bestMatch];
    }
    return lodMap;
  }
  private requestedItems: Set<Page> = new Set<Page>();
  private itemsInViewport = new Map<string, THREE.Object3D>();
  private framerate: number = 60.0;
  private vtParams: VirtualTextureData;
  private worstLod: number;
  private bestLod: number;

  static get BASE_LOD_BIAS() {
    return 2.5;
  } // Alter this value if the pages are too fuzzy or too detailed.
  static get MID_RATIO_THRESHOLD() {
    return 1.08;
  }
  static get TOP_RATIO_THRESHOLD() {
    return 1.5;
  }
  static get MAX_RENDER_DIMENSION() {
    return 256;
  } // TODO: Change hard-coded to planagram.width
  static get MAX_REQUEST_COUNT() {
    return 8;
  }
  static get FPS_TO_INCREASE_REQUEST_COUNT() {
    return 55;
  }
  static get FPS_TO_DECREASE_REQUEST_COUNT() {
    return 50;
  }
  static get PAGES_TO_REQUEST_STEP() {
    return 2;
  }
  static get READ_TIMEOUT_MS() {
    return 100;
  }

  private static BASE_LOD = 4;
  private static BASE_FOV = 21.12;

  activeRendering = true; // Tells us whenever to render the background

  static get VALUES_PER_TEXEL() {
    return 2;
  }
  static get BYTES_PER_TEXEL() {
    return 4;
  }

  constructor(
    vtParams: VirtualTextureData,
    private renderer: THREE.WebGLRenderer,
    numberOfTextures,
    textureSize,
    camera: Camera,
    planogram: Planogram
  ) {
    this.camera = camera;
    this.planogram = planogram;

    this.vtParams = {
      ...vtParams,
      pageBorderWidth: 2,
      pageSize: 512,
      pagesHigh: 64,
      pagesWide: 256
    };

    const lods = planogram.items
      .filter(item => (item.data as LODSMetaData)?.lods?.length > 0)
      .map(item => {
        return item as ItemData<LODSMetaData>;
      });

    this.bestLod = lods.reduce((best, item) => Math.min(bestLod(item), best), VTPipeline.BASE_LOD);
    this.worstLod = lods.reduce((best, item) => Math.max(worstLod(item), best), VTPipeline.BASE_LOD);
    this.vtParams.permanentLod = this.worstLod;

    this.createItemsLodMap(planogram.items);

    this.dynamicElementsPageCache = new DynamicElementsPageCache(
      vtParams,
      this.renderer,
      Math.min(textureSize, renderer.capabilities.maxTextureSize),
      Math.min(numberOfTextures, renderer.capabilities.maxTextures),
      lods
    );

    this.pageRequestsPerFrame = 1;
  }

  async initialize(): Promise<void> {
    this.updateViewport();
    await this.dynamicElementsPageCache.initialize(this.FOVtoLOD(), this.worstLod);
  }

  createMaterial(uniforms: Record<string, any>): any {
    return new THREE.RawShaderMaterial({
      uniforms,
      side: THREE.DoubleSide,
      transparent: true,
      fog: false,
      lights: false,
      depthTest: false,
      depthWrite: false,
      wireframe: false,
      vertexShader: textureVertexShader,
      fragmentShader: textureFragmentShader
    });
  }

  createLODMaterial(item: ItemData) {
    return this.createMaterial(this.itemUniforms(item));
  }

  itemUniforms(item: ItemData) {
    return this.dynamicElementsPageCache.getItemUniforms(item as ItemData<ImageMetaData>);
  }

  // This is called every frame. Although the feedback buffer is rendered on
  // every frame, it is only read and processed every VTPipeline.READ_TIMEOUT_MS,
  // since it is an expensive action and doesn't meaningfully change every frame.
  update(dt: number) {
    if (this.disposed) {
      return;
    }
    this.framerate = 0.5 * this.framerate + (0.5 * 1) / dt;

    this._updatePagesInViewport();
  }

  setItems(items: Map<string, THREE.Object3D>) {
    this.items = items;
  }

  updatePagesInViewport = () => {
    this.pageRequestsPerFrame = Math.min(this._pagesToRequest(this.pageRequestsPerFrame), 4);
    this.requestedLod = this.FOVtoLOD();

    const viewportI = new THREE.Box2(
      new THREE.Vector2(Math.floor(this.viewport.min.x), Math.floor(this.viewport.min.y)),
      new THREE.Vector2(Math.ceil(this.viewport.max.x), Math.ceil(this.viewport.max.y))
    );

    this.findItemsInViewport(viewportI, this.requestedLod);
    this.requestTiles();

    this.updateSphereItemsVisibility(this.itemsInViewport);
    this.itemsInViewport.clear();
  };

  private _updatePagesInViewport = throttle(VTPipeline.READ_TIMEOUT_MS, this.updatePagesInViewport);

  FOVtoLOD() {
    const fov = this.camera.fov();
    const zoom = VTPipeline.BASE_FOV / fov;

    const lod = VTPipeline.BASE_LOD - Math.ceil(Math.log(zoom) / Math.log(2));
    const boundLod = Math.max(this.bestLod, Math.min(this.worstLod, lod));
    return boundLod;
  }

  requestTiles(): void {
    this.requestedItems.forEach(page => this.requestItem(page));
    this.requestedItems.clear();
  }

  requestItem(page: Page) {
    this.dynamicElementsPageCache.loadItem(page, false);
  }

  // Updates and returns how many pages can be requested every frame, depending
  // on the current frame rate.
  _pagesToRequest(currentRequestCount) {
    if (this.framerate > VTPipeline.FPS_TO_INCREASE_REQUEST_COUNT) {
      return Math.min(
        currentRequestCount +
          Math.round((VTPipeline.PAGES_TO_REQUEST_STEP * this.framerate) / VTPipeline.FPS_TO_INCREASE_REQUEST_COUNT),
        VTPipeline.MAX_REQUEST_COUNT
      );
    } else if (this.framerate < VTPipeline.FPS_TO_DECREASE_REQUEST_COUNT) {
      return Math.max(
        currentRequestCount -
          Math.round((VTPipeline.PAGES_TO_REQUEST_STEP * VTPipeline.FPS_TO_DECREASE_REQUEST_COUNT) / this.framerate),
        1
      );
    }

    return currentRequestCount;
  }

  private findItemsInViewport(viewport: THREE.Box2, lod: number): void {
    const modulo = new Modulo(Planogram.pages());
    const lodMap = this.pickLodMap(lod);
    if (!lodMap) {
      return;
    }
    modulo.iterateB(viewport, v => {
      lodMap[v.x][v.y]?.forEach(item => {
        this.itemsInViewport.set(item.id, this.items.get(item.id));

        const itemLod = item.lod;
        if (itemLod !== undefined) {
          this.requestedItems.add(item);
        }
      });
    });
  }

  _outerLimitsFor(viewport, lodFactor, numXOuterPageLayers): PageLimitsData {
    const limits: PageLimitsData = {};
    limits.topY = Math.max(Math.floor(viewport.min.y / lodFactor) - 1, 0);
    limits.bottomY = Math.min(Math.floor(viewport.max.y / lodFactor) + 1, this.vtParams.pagesHigh / lodFactor - 1);
    limits.leftX = Math.floor(viewport.min.x / lodFactor) - numXOuterPageLayers;
    if (limits.leftX < 0) {
      limits.leftX = this.vtParams.pagesWide / lodFactor + limits.leftX;
    }
    limits.rightX = Math.floor(viewport.max.x / lodFactor) + numXOuterPageLayers;
    if (limits.rightX >= this.vtParams.pagesWide / lodFactor) {
      limits.rightX -= this.vtParams.pagesWide / lodFactor;
    }
    return limits;
  }

  private updateSphereItemsVisibility(itemsInViewportMap: Map<string, THREE.Object3D>) {
    const entries = Array.from(this.items.entries());

    for (const [_, child] of entries) {
      child.visible = !!itemsInViewportMap ? itemsInViewportMap.has(child.userData?.component?.id) : false;
      (child.userData.component as SphereItem).onVisibilityToggle(child.visible);
    }
  }

  updateViewport() {
    if (VRService.isActive) {
      this.viewport = Planogram.maximumBoundary.clone();
      this.viewport.max.subScalar(1);
    } else {
      const tileViewport = this.camera.getViewport();
      tileViewport.min = this.planogram.planogramToTileCoordinate(tileViewport.min);
      tileViewport.max = this.planogram.planogramToTileCoordinate(tileViewport.max);
      // planogramToTileCoordinate inverts Y
      [tileViewport.min.y, tileViewport.max.y] = [tileViewport.max.y, tileViewport.min.y];
      this.viewport.copy(tileViewport);
    }
    this.dynamicElementsPageCache.setViewport(this.viewport);
  }

  createItemsLodMap(items) {
    this.itemsLODMap = [];
    if (!items || !items.length) {
      return;
    }

    for (let lod = this.bestLod; lod <= this.worstLod; lod++) {
      this.itemsLODMap[lod] = ArrayUtils.createMultiArray(Planogram.PAGES_WIDE, Planogram.PAGES_HIGH, 0);
    }

    const itemsWithoutLOD = items.filter(item => !(item.data as LODSMetaData).lods);

    itemsWithoutLOD.forEach(item => {
      const virtualBoundaries = item.virtualBoundaries;
      for (let lod = this.bestLod; lod <= this.worstLod; lod++) {
        pagesModulo.iterateB(virtualBoundaries, v => {
          if (!this.itemsLODMap[lod][v.x][v.y]) {
            this.itemsLODMap[lod][v.x][v.y] = new Array<Page>();
          }
          this.itemsLODMap[lod][v.x][v.y].push({
            id: item.id.toString(),
            transparent: true,
            x: 0,
            y: 0,
            lod: undefined
          });
        });
      }
    });

    const itemsWithLOD = items.filter(item => !!(item.data as LODSMetaData).lods);
    itemsWithLOD.forEach(item => {
      const itemData = item.data as LODSMetaData;
      if (!itemData.lods) {
        return;
      }

      // canvas y coordinate points up, planogram tile y coordinate points down
      // so the item's coordinate is for the bottom left corner, not top left
      const topLeftTilePosition = new Vector2(item.x, item.y + item.height);
      // tiles always form a square, but the original item might not be a square
      const aspectRatio = item.width / item.height;
      if (aspectRatio < 1) {
        topLeftTilePosition.x -= (1 - aspectRatio) * item.height * 0.5;
      } else {
        topLeftTilePosition.y += (1 - 1 / aspectRatio) * item.width * 0.5;
      }

      const itemWorst = worstLod(item);
      const itemBest = bestLod(item);

      for (let lod = itemBest; lod <= itemWorst; lod++) {
        const itemLod = itemData.lods.find(lodItem => lodItem.lod === lod)!;

        const mapsToPush = [lod];
        if (lod === itemWorst) {
          for (let i = this.worstLod; i > itemWorst; i--) mapsToPush.push(i);
        }
        if (lod === itemBest) {
          for (let i = this.bestLod; i < itemBest; i++) mapsToPush.push(i);
        }

        const lodTiles: Array<ItemTile> = itemLod.textures;
        // original image is always split into a square grid of tiles
        const gridSize = Math.round(Math.sqrt(lodTiles.length));
        // larger side of the image is always split into tiles exactly
        const tileSize = Math.max(item.width, item.height) / gridSize;

        // account for inverted y axis again
        const tileOffset = new Vector2(tileSize, -tileSize);

        for (let tileX = 0; tileX < gridSize; tileX++) {
          for (let tileY = 0; tileY < gridSize; tileY++) {
            const tileCoord = new Vector2(tileX, tileY);
            const position = tileCoord.clone().multiply(tileOffset).add(topLeftTilePosition);
            const center = this.planogram.canvasToTileCoordinate(position.clone().addScaledVector(tileOffset, 0.5));
            const boundaries = this.planogram.tileVirtualBoundaries(position, position.clone().add(tileOffset));

            const index = tileCoord.x * gridSize + tileCoord.y;
            if (lodTiles[index]) {
              lodTiles[index] = {
                ...lodTiles[index],
                boundaries,
                center,
                size: gridSize
              };
            } else {
              lodTiles[index] = {
                url: undefined,
                boundaries,
                center,
                size: gridSize
              };
            }

            const page = {id: item.id.toString(), lod, x: tileCoord.x, y: tileCoord.y};
            pagesModulo.iterateB(boundaries, v => {
              mapsToPush.forEach(i => {
                if (!this.itemsLODMap[i][v.x][v.y]) {
                  this.itemsLODMap[i][v.x][v.y] = [];
                }
                this.itemsLODMap[i][v.x][v.y].push(page);
              });
            });
          }
        }
      }
    });
  }

  dispose() {
    this.disposed = true;
    this.itemsLODMap = undefined;
    this.renderer = undefined;
    this._updatePagesInViewport.cancel();
    this.dynamicElementsPageCache?.dispose();
  }
}
