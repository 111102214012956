import * as THREE from 'three';
import {CameraControls} from './camera_controls';
import {DIRECTION} from '../shared/constants';
import {Search} from '../search/search';
import {searchEventHandler} from '../custom_event_utils';
import {SEARCH_EVENT_NAMES as SEARCH_EVENTS} from '../event-names';
import {BaseInputHandler} from '../base_input_handler';
import {SphereApp} from '../sphere_app';
import {Account} from '../account/account';
import {AccessibilityService} from '../accessibility/accessibility_service';
import {LayoutSwitcher} from '../components/layout-switcher/layout-switcher';

export class KeyboardControls {
  private inputHandler: BaseInputHandler;

  static get KEYS() {
    return {
      ESC: 27,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      BOTTOM: 40,
      I: 73,
      L: 76,
      O: 79,
      R: 82,
      S: 83,
      A: 65,
      ONE: 49,
      TWO: 50
    };
  }

  static get ROTATION_BASE_ADJUSTMENT() {
    return 0.2;
  }

  static get KEYBOARD_ZOOM_FACTOR() {
    return 0.965;
  }

  constructor(private cameraControls: CameraControls, private sphereApp: SphereApp) {
    this.onKeyDown = this.onKeyDown.bind(this);
    window.addEventListener('keydown', this.onKeyDown, false);
  }

  zoomDependentRotation(degrees) {
    const zoom = 1 - this.cameraControls.currentZoomFraction() + KeyboardControls.ROTATION_BASE_ADJUSTMENT;
    return THREE.MathUtils.degToRad(zoom * degrees);
  }

  onKeyDown = (event) => {
    this.inputHandler = this.sphereApp.ACTUAL_INPUT_HANDLER;
    if (
      this.inputHandler.isOverlayShowing() ||
      Search.isSearchActive ||
      Account.isAccountActive ||
      LayoutSwitcher.isLayoutActive
    ) {
      this._handleInputOnOverlay(event);
      return;
    }

    if (AccessibilityService.isActive) {
      switch (event.keyCode) {
        case KeyboardControls.KEYS.ONE:
          if (event.altKey) {
            this.sphereApp.accessibilityService.navigateToItemList();
          }
          break;
        case KeyboardControls.KEYS.TWO:
          if (event.altKey) {
            this.sphereApp.accessibilityService.navigateToSphereList();
          }
          break;
        default:
        // Do nothing
      }
      return;
    }
    switch (event.keyCode) {
      case KeyboardControls.KEYS.UP:
        this.cameraControls.clearAnimation();
        this.inputHandler.animateCameraToClusterByArrows(DIRECTION.UP);
        break;
      case KeyboardControls.KEYS.RIGHT:
        this.cameraControls.clearAnimation();
        this.inputHandler.animateCameraToClusterByArrows(DIRECTION.RIGHT);
        break;
      case KeyboardControls.KEYS.BOTTOM:
        this.cameraControls.clearAnimation();
        this.inputHandler.animateCameraToClusterByArrows(DIRECTION.DOWN);
        break;
      case KeyboardControls.KEYS.LEFT:
        this.cameraControls.clearAnimation();
        this.inputHandler.animateCameraToClusterByArrows(DIRECTION.LEFT);
        break;
      case KeyboardControls.KEYS.A:
        this.inputHandler.navigateToAutoplayClusters();
        break;
      case KeyboardControls.KEYS.I:
        this.cameraControls.clearAnimation();
        this.cameraControls.zoomBy(KeyboardControls.KEYBOARD_ZOOM_FACTOR);
        break;
      case KeyboardControls.KEYS.O:
        this.cameraControls.clearAnimation();
        this.cameraControls.zoomBy(1 / KeyboardControls.KEYBOARD_ZOOM_FACTOR);
        break;
      case KeyboardControls.KEYS.L:
        this.cameraControls.autoRotate(CameraControls.ROTATE_LEFT);
        break;
      case KeyboardControls.KEYS.R:
        this.cameraControls.autoRotate(CameraControls.ROTATE_RIGHT);
        break;
      case KeyboardControls.KEYS.S:
        this.cameraControls.stopAutoRotate();
        this.inputHandler.resetAutoplay();
        break;
      default:
      // Do nothing
    }
  }

  _handleInputOnOverlay(event) {
    this.inputHandler = this.sphereApp.ACTUAL_INPUT_HANDLER;
    switch (event.keyCode) {
      case KeyboardControls.KEYS.RIGHT:
        event.stopPropagation();
        this.inputHandler.handleRightOnOverlay();
        break;
      case KeyboardControls.KEYS.LEFT:
        event.stopPropagation();
        this.inputHandler.handleLeftOnOverlay();
        break;
      case KeyboardControls.KEYS.ESC:
        this.inputHandler.hideOverlay(true);
        searchEventHandler.emit(SEARCH_EVENTS.CLOSE_SEARCH);
        break;
      default:
      // Do nothing
    }
  }

  dispose() {
    window.removeEventListener('keydown', this.onKeyDown, false);
  }
}
