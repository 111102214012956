import {Text} from 'troika-three-text';
import {ItemData, TextMetaData} from '../interfaces/planogram.interface';
import {SphereItem} from '../sphere_item';
import * as THREE from 'three';
import {FONT_UNIT_RATIO, DESIGNER_PLANOGRAM_HEIGHT, SPHERE_ITEM_TYPES} from '../shared/constants';
import {WebUtils} from '../utils/web_utils';
import {SphereGeometry} from '../geometries/sphere_geometry';
import {Planogram} from '../planogram';
import {MeshBasicMaterial} from 'three';
import {FontLoader} from '../font_loader';

// The max size of the final text texture
const MAX_TEXTURE_SIZE = 8192;

// How much to increase the original size of the text - this way we
// increase the resolution / quality of the rendered text
const TEXTURE_SCALING = 4;

export class TextComponent extends SphereItem {
  text: Text;

  fontSpecs: {width: number; height: number; horizontalOffset: number; verticalOffset: number};

  private createMeshPromisesResolves: ((res: PromiseLike<undefined>) => void)[] = [];

  constructor(itemData: ItemData, planogram: Planogram) {
    super(itemData, planogram);
  }

  onClick(position: THREE.Vector3): void {}

  onDrag(position: THREE.Vector3): void {}

  onHoverEnter(): void {}

  onHoverLeave(): void {}

  onVisibilityChange(): void {}

  isDraggable(): boolean {
    return false;
  }

  // Calculates bounding box of the text.
  private calculateSize(data: TextMetaData) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    this.applyFontStyle(canvas, ctx, data);
    const {topOffsetFromCenter} = data;

    let maxLeft = Number.MIN_SAFE_INTEGER;
    let maxRight = Number.MIN_SAFE_INTEGER;
    let topP = 0;
    let bottomP = 0;
    const lineHeight = Math.round(data.lineHeight * TextComponent.fontUnitSize);

    const lines = this.type === SPHERE_ITEM_TYPES.TEXT ? data.text.split('\n') : data.textLines;

    lines.forEach((line, index) => {
      if (!line) {
        return;
      }
      const {actualBoundingBoxLeft, actualBoundingBoxRight, actualBoundingBoxAscent, actualBoundingBoxDescent} =
        ctx.measureText(line);

      if (actualBoundingBoxLeft > maxLeft) {
        maxLeft = actualBoundingBoxLeft;
      }
      if (actualBoundingBoxRight > maxRight) {
        maxRight = actualBoundingBoxRight;
      }
      if (index === 0) {
        topP = actualBoundingBoxAscent;
      }
      if (index === lines.length - 1) {
        bottomP = actualBoundingBoxDescent;
      }
    });

    const height = Math.max(0, topP + bottomP + (lines.length - 1) * lineHeight);
    const width = Math.max(0, maxLeft + maxRight);

    const horizontalOffset = maxLeft;
    const verticalOffset = topP;

    if (this.type === SPHERE_ITEM_TYPES.TEXT) {
      // old Text component
      this.x = this.x - horizontalOffset;
      this.y = this.y + topOffsetFromCenter - height;
    } else {
      this.x = data.textPosition[0] - horizontalOffset + 0;
      this.y = data.textPosition[1] + topOffsetFromCenter - height;
    }

    // Update dimensions
    this.width = width;
    this.height = height;
    this.fontSpecs = {width, height, horizontalOffset, verticalOffset};
    this.azimuthStartRadians = SphereGeometry.calcAzimuthStartRadians(this.x, this.width, this.planogram.width);
    this.createMeshPromisesResolves.forEach(resolve => resolve(undefined));

    canvas.width = canvas.height = 0;
    canvas.remove();
  }

  async createMesh() {
    await this.createMaterial(this.itemData.data as TextMetaData);
    return super.createMesh();
  }

  async createMaterial(data: TextMetaData) {
    this.material = new MeshBasicMaterial({
      transparent: true,
      opacity: 0.0,
      depthTest: false,
      depthWrite: false
    });

    try {
      await this.loadFont(data);
    } catch (e) {
      console.error(e);
      return;
    }

    this.calculateSize(data);
    this.createTextTexture(data);
  }

  static get fontUnitSize() {
    return FONT_UNIT_RATIO * DESIGNER_PLANOGRAM_HEIGHT;
  }

  static get letterSpacingUnitSizes() {
    return Text.fontUnitSize / 1000;
  }

  createTextTexture(data: TextMetaData) {
    // If the initial size of the text is too big we can't increase
    // the resolution by too much or we will start having memory issues
    const textureScale =
      MAX_TEXTURE_SIZE / Math.max(Math.max(this.width, this.height), MAX_TEXTURE_SIZE / TEXTURE_SCALING);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const letterSpacing = data.letterSpacing * TextComponent.letterSpacingUnitSizes;
    const lineHeight = Math.round(data.lineHeight * TextComponent.fontUnitSize);

    const width = this.width * textureScale;
    const height = this.height * textureScale;
    const horizontalOffset = this.fontSpecs.horizontalOffset * textureScale;
    const verticalOffset = this.fontSpecs.verticalOffset * textureScale;

    // Resize canvas to match text size
    canvas.width = width;
    canvas.height = height;
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
    canvas.style.letterSpacing = letterSpacing + 'px';

    this.applyFontStyle(canvas, ctx, data, textureScale);

    const lines = this.type === SPHERE_ITEM_TYPES.TEXT ? data.text.split('\n') : data.textLines;

    lines.forEach((text, index) => {
      ctx.fillText(text, horizontalOffset, verticalOffset + lineHeight * textureScale * index);
    });

    const texture = new THREE.Texture(canvas);
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    (this.material as MeshBasicMaterial).map = texture;
    (this.material as MeshBasicMaterial).opacity = data.alpha || 1.0;
    (this.material as MeshBasicMaterial).map.needsUpdate = true;
    this.material.needsUpdate = true;

    texture.onUpdate = () => {
      canvas.width = 0;
      canvas.height = 0;
      canvas.remove();
      texture.onUpdate = undefined;
    };
  }

  async loadFont(data: TextMetaData) {
    const font = data.fontFamily;

    const family = WebUtils.removeFileExtension(font.name) || 'custom-font';
    const weight = this.computeFontWeight(data.weight !== undefined ? data.weight : '');

    let fontFace = undefined;

    await document.fonts.ready;

    for (const it of document.fonts) {
      if (
        it.family === family &&
        it.display !== 'swap' &&
        this.computeFontWeight(it.weight) === weight &&
        FontLoader.getCustomFont(font.file_url)
      ) {
        fontFace = it;
        break;
      }
    }

    if (!fontFace) {
      fontFace = FontLoader.addCustomFont(font);
    }

    if (fontFace.status !== 'loaded') {
      await fontFace.loaded;
    }
  }

  computeFontWeight(weight: string) {
    const str = weight.toLowerCase();
    let computedWeight: string;

    switch (str) {
      case 'thin':
        computedWeight = '100';
        break;
      case 'hairline':
        computedWeight = '100';
        break;
      case 'extra light':
        computedWeight = '200';
        break;
      case 'ultra light':
        computedWeight = '200';
        break;
      case 'light':
        computedWeight = '300';
        break;
      case 'book':
        computedWeight = '300';
        break;
      case 'regular':
        computedWeight = '400';
        break;
      case 'normal':
        computedWeight = '400';
        break;
      case 'medium':
        computedWeight = '500';
        break;
      case 'semi bold':
        computedWeight = '600';
        break;
      case 'bold':
        computedWeight = '700';
        break;
      case 'extra bold':
        computedWeight = '800';
        break;
      case 'black':
        computedWeight = '900';
        break;
      default:
        computedWeight = '400';
        break;
    }

    return computedWeight;
  }

  dispose() {
    (this.material as MeshBasicMaterial)?.map?.dispose();
    super.dispose();
  }

  private applyFontStyle(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, data: TextMetaData, scale = 1) {
    const fontSize = Math.round(data.fontSize * TextComponent.fontUnitSize);
    const letterSpacing = data.letterSpacing * TextComponent.letterSpacingUnitSizes;

    canvas.style.letterSpacing = letterSpacing + 'px';
    ctx.font = `${fontSize * scale}px "${WebUtils.removeFileExtension(data.fontFamily.name) || 'custom-font'}"`;
    ctx.textAlign = (data.alignment as CanvasTextAlign) || 'left';
    ctx.textBaseline = 'top';
    ctx.fillStyle = data.color || 'white';
  }
}
