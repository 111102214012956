import {Vector2} from 'three';

export class MathUtils {
  static log(value: number, base?: number): number {
    return Math.log(value) / (base ? Math.log(base) : 1.0);
  }
}

export function normalizeMouse(x, y) {
  return new Vector2((x / window.innerWidth) * 2 - 1, -((y / window.innerHeight) * 2) + 1);
}
