import {ApiService} from '../api';
import {URLS, UrlUtils} from '../urls';
import {L10nUtils} from '../../utils/l10n_utils';

export class PlanogramsService extends ApiService {

  getPlanogram(planogramName) {
    if (!planogramName) {
      return Promise.reject(new Error('Missing data for planogram URL'));
    }
    const lang = L10nUtils.getCurrentLanguage();
    const url = UrlUtils.insertFewValuesToUrl(URLS.PLANOGRAM, {planogramName, lang});
    return this.get(url).then(resp => resp.json());
  }
}
