import * as THREE from 'three';
import {Vector2, Vector3} from 'three';
import {solve2, solve4} from '../lib/solver';
import {SphereGeometry} from './geometries/sphere_geometry';
import {PlanogramData} from './interfaces/planogram.interface';
import {normalizeMouse} from './utils/math_utils';

const RESOLUTION_HIGH = 6 * PlanogramData.PAGES_HIGH;

export interface Intersection {
  distance: number;
  point: Vector3;
  normal: Vector3;
}

export class IntersectCalculator {
  raycaster: THREE.Raycaster;
  boundaryHeight: number;
  boundaryRadius: number;
  domeRadius: number;
  domeCentreHeights: Array<number>;
  totalHeight: number;

  constructor(private planogram: PlanogramData) {
    const fixedRadius = this.planogram.fixedRadius;
    const largeRadius = this.planogram.largeRadius;
    const alpha = PlanogramData.ALPHA;
    // Raycaster used to get ray origin/direction from camera and viewpoint co-ords.
    this.raycaster = new THREE.Raycaster();

    // The boundary height where the top/bottom domes start.
    this.boundaryHeight = largeRadius * Math.sin(alpha);

    // Radius that valid points in the middle section should fall within.
    this.boundaryRadius = fixedRadius;

    // Radius of the domes.
    this.domeRadius = (fixedRadius - largeRadius * (1 - Math.cos(alpha))) / Math.cos(alpha);

    // The centre heights of the domes
    const domeCentreHeight = this.boundaryHeight - this.domeRadius * Math.sin(alpha);
    this.domeCentreHeights = [domeCentreHeight, -domeCentreHeight];
    this.totalHeight = (this.boundaryHeight + domeCentreHeight) * 2;

    // Generates RESOLUTION_HIGH points from top to bottom of sphere,
    // this is then used for approximating the y component of the UV coordinates
    for (let i = RESOLUTION_HIGH; i >= 0; i--) {
      this.sphereYPoints.push(this.getSpherePoint(0, ((this.planogram.height / 2.0) * i) / RESOLUTION_HIGH));
    }
  }

  updatePlanogram() {
    const fixedRadius = this.planogram.fixedRadius;
    const largeRadius = this.planogram.largeRadius;
    const alpha = PlanogramData.ALPHA;

    // The boundary height where the top/bottom domes start.
    this.boundaryHeight = largeRadius * Math.sin(alpha);

    // Radius that valid points in the middle section should fall within.
    this.boundaryRadius = fixedRadius;

    // Radius of the domes.
    this.domeRadius = (fixedRadius - largeRadius * (1 - Math.cos(alpha))) / Math.cos(alpha);

    // The centre heights of the domes
    const domeCentreHeight = this.boundaryHeight - this.domeRadius * Math.sin(alpha);
    this.domeCentreHeights = [domeCentreHeight, -domeCentreHeight];
    this.totalHeight = (this.boundaryHeight + domeCentreHeight) * 2;
  }

  getTotalHeight() {
    return this.totalHeight;
  }

  // Find valid intersects
  getIntersects(ray, coeffs, intersectFunction) {
    // eslint-disable-line max-statements
    // Solve intersect equations
    let roots;
    if (coeffs.length === 5) {
      roots = solve4(coeffs);
    } else {
      roots = solve2(coeffs);
    }
    // Check solutions from furthest to closest.
    roots.sort((a, b) => b - a);
    const result = [];
    for (let i = 0; i < roots.length; i += 1) {
      const distance = roots[i];
      // If negative, no further valid solutions.
      if (distance < 0) {
        break;
      }
      // Get point at distance
      const point = new THREE.Vector3();
      ray.at(distance, point);
      // Get intersection if valid
      const intersect = intersectFunction(distance, point);
      if (intersect) {
        result.push(intersect);
      }
    }
    return result;
  }

  // Calculate the normal for a point on the surface
  static getNormal(point, centreHeight, radius) {
    const sinAltitude = (point.y - centreHeight) / radius;
    const cosAltitude = Math.cos(Math.asin(sinAltitude));
    const azimuth = Math.atan2(point.z, point.x);
    return new THREE.Vector3(-Math.cos(azimuth) * cosAltitude, -sinAltitude, -Math.sin(azimuth) * cosAltitude);
  }

  // Return intersection for a point on the middle torus, if valid
  torusIntersect(distance, point) {
    // eslint-disable-line max-statements
    // Check point is within the middle segment height range.
    if (point.y >= this.boundaryHeight || point.y <= -this.boundaryHeight) {
      return undefined;
    }
    // Check point is within the radial bounds of the surface.
    const radius = Math.sqrt(point.x ** 2 + point.z ** 2);
    if (radius > this.boundaryRadius) {
      return undefined;
    }
    // Valid point, calculate normal.
    const normal = IntersectCalculator.getNormal(point, 0, this.planogram.largeRadius);
    return {distance, point, normal};
  }

  // Return intersection for a point on the top or bottom dome, if valid
  domeIntersect(distance, point, domeIndex) {
    // Check point is within the height range of the dome.
    if (
      (domeIndex === 0 && point.y < this.boundaryHeight) || // top dome
      (domeIndex === 1 && point.y > -this.boundaryHeight)
    ) {
      // bottom dome
      return undefined;
    }
    // Valid point, calculate normal.
    const normal = IntersectCalculator.getNormal(point, this.domeCentreHeights[domeIndex], this.domeRadius);
    return {distance, point, normal};
  }

  // Return intersect on far wall of sphere shape:
  // { distance, point, normal }
  findFarthestIntersect(origin: Vector3, direction: Vector3): Intersection {
    // Calculate quartic coefficients for torus intersects.
    const torusCoeffs = this.torusIntersectCoefficients(origin, direction);
    // Calculate quadratic coefficients for dome intersects.
    const domeCoeffs = this.domeIntersectCoefficients(origin, direction);
    // Ray used for getting intersect points from distances.
    const ray = new THREE.Ray(origin, direction);
    // Get intersects on middle torus.
    const torusIntersects = this.getIntersects(ray, torusCoeffs, (distance, point) =>
      this.torusIntersect(distance, point)
    );
    // Get intersects on top dome.
    const topIntersects = this.getIntersects(ray, domeCoeffs[0], (distance, point) =>
      this.domeIntersect(distance, point, 0)
    );
    // Get intersects on bottom dome.
    const bottomIntersects = this.getIntersects(ray, domeCoeffs[1], (distance, point) =>
      this.domeIntersect(distance, point, 1)
    );
    // Collect all intersects.
    const intersects = torusIntersects.concat(topIntersects, bottomIntersects);
    // If no intersects, the line does not intersect the surface at all.
    if (intersects.length === 0) {
      return undefined;
    }
    // Otherwise, select the farthest intersect.
    intersects.sort((a, b) => b.distance - a.distance);
    return intersects[0];
  }

  findFarthestMouseIntersect(screenX, screenY, threeJSCamera) {
    const mousePoint = normalizeMouse(screenX, screenY);
    this.raycaster.setFromCamera(mousePoint, threeJSCamera);
    const origin = this.raycaster.ray.origin;
    const direction = this.raycaster.ray.direction;
    return this.findFarthestIntersect(origin, direction);
  }

  torusIntersectCoefficients(origin, direction) {
    // eslint-disable-line max-statements
    // Input terms to the equations
    const fixedRadius = this.planogram.fixedRadius;
    const largeRadius = this.planogram.largeRadius;
    const Ox = origin.x;
    const Oy = origin.y;
    const Oz = origin.z;
    const Dx = direction.x;
    const Dy = direction.y;
    const Dz = direction.z;
    // Generated code follows
    /* eslint-disable */
    const x0 = Math.pow(Dy, 2);
    const x1 = Math.pow(Dx, 2);
    const x2 = 2 * x1;
    const x3 = Math.pow(Dz, 2);
    const x4 = 2 * x3;
    const x5 = 4 * Ox;
    const x6 = 4 * Oy;
    const x7 = 4 * Oz;
    const x8 = Dx * x5;
    const x9 = Dy * x6;
    const x10 = Dz * x7;
    const x11 = Dx * Ox;
    const x12 = 8 * x11;
    const x13 = Dy * Oy;
    const x14 = Dz * Oz;
    const x15 = 8 * x13;
    const x16 = 4 * largeRadius;
    const x17 = fixedRadius * x16;
    const x18 = Math.pow(Ox, 2);
    const x19 = Math.pow(Oy, 2);
    const x20 = Math.pow(Oz, 2);
    const x21 = Math.pow(fixedRadius, 2);
    const x22 = Math.pow(largeRadius, 2);
    const x23 = 4 * x22;
    const x24 = 2 * x0;
    const x25 = fixedRadius * largeRadius;
    const x26 = 8 * x22;
    const x27 = 2 * x18;
    const x28 = 2 * x21;
    const m4 = Math.pow(Dx, 4) + Math.pow(Dy, 4) + Math.pow(Dz, 4) + x0 * x2 + x0 * x4 + x2 * x3;
    const m3 =
      Math.pow(Dx, 3) * x5 +
      Math.pow(Dy, 3) * x6 +
      Math.pow(Dz, 3) * x7 +
      x0 * x10 +
      x0 * x8 +
      x1 * x10 +
      x1 * x9 +
      x3 * x8 +
      x3 * x9;
    const m2 =
      -x0 * x17 +
      6 * x0 * x19 +
      x1 * x17 +
      6 * x1 * x18 -
      x1 * x23 +
      x12 * x13 +
      x12 * x14 +
      x14 * x15 +
      x17 * x3 +
      x18 * x24 +
      x18 * x4 +
      x19 * x2 +
      x19 * x4 +
      x2 * x20 -
      x2 * x21 +
      x20 * x24 +
      6 * x20 * x3 +
      x21 * x24 -
      x21 * x4 -
      x23 * x3;
    const m1 =
      4 * Dx * Math.pow(Ox, 3) +
      4 * Dy * Math.pow(Oy, 3) +
      4 * Dz * Math.pow(Oz, 3) +
      x10 * x18 +
      x10 * x19 -
      x10 * x21 -
      x11 * x26 +
      x12 * x25 +
      8 * x14 * x25 -
      x14 * x26 -
      x15 * x25 +
      x18 * x9 +
      x19 * x8 +
      x20 * x8 +
      x20 * x9 -
      x21 * x8 +
      x21 * x9;
    const m0 =
      Math.pow(Ox, 4) +
      Math.pow(Oy, 4) +
      Math.pow(Oz, 4) +
      Math.pow(fixedRadius, 4) -
      Math.pow(fixedRadius, 3) * x16 +
      x17 * x18 -
      x17 * x19 +
      x17 * x20 -
      x18 * x23 -
      x18 * x28 +
      2 * x19 * x20 +
      x19 * x27 +
      x19 * x28 -
      x20 * x23 +
      x20 * x27 -
      x20 * x28 +
      x21 * x23;
    return [m0, m1, m2, m3, m4];
    // End of generated code
    /* eslint-enable */
  }

  domeIntersectCoefficients(origin, direction) {
    // eslint-disable-line max-statements
    // Input terms to the equations
    const fixedRadius = this.planogram.fixedRadius;
    const largeRadius = this.planogram.largeRadius;
    const alpha = PlanogramData.ALPHA;
    const Ox = origin.x;
    const Oy = origin.y;
    const Oz = origin.z;
    const Dx = direction.x;
    const Dy = direction.y;
    const Dz = direction.z;
    // Generated code follows
    /* eslint-disable */
    const x0 = Math.cos(alpha);
    const x1 = 1 / x0;
    const x2 = 2 * largeRadius;
    const x3 = Math.sin(alpha);
    const x4 = Dy * x3;
    const x5 = x2 * x4;
    const x6 = 2 * fixedRadius * x4;
    const x7 = 2 * x0;
    const x8 = Oy * x7;
    const x9 = Dx * Ox * x7 + Dy * x8 + Dz * Oz * x7;
    const x10 = Math.pow(x0, 2);
    const x11 = 1 / x10;
    const x12 = Oy * x0 * x2 * x3;
    const x13 = fixedRadius * x3 * x8;
    const x14 = Math.pow(fixedRadius, 2);
    const x15 = Math.pow(largeRadius, 2);
    const x16 = Math.pow(x3, 2);
    const x17 = fixedRadius * x2;
    const x18 =
      Math.pow(Ox, 2) * x10 +
      Math.pow(Oy, 2) * x10 +
      Math.pow(Oz, 2) * x10 -
      x0 * x17 -
      x10 * x15 +
      x14 * x16 -
      x14 +
      x15 * x16 +
      x15 * x7 -
      x15 -
      x16 * x17 +
      x17;
    const t2 = 1;
    const t1 = x1 * (-x5 + x6 + x9);
    const t0 = x11 * (-x12 + x13 + x18);
    const b2 = 1;
    const b1 = x1 * (x5 - x6 + x9);
    const b0 = x11 * (x12 - x13 + x18);
    return [
      [t0, t1, t2],
      [b0, b1, b2]
    ];
    // End of generated code
    /* eslint-enable */
  }

  private sphereYPoints: Array<THREE.Vector3> = [];
  /**
   * Transforms y coordinates on sphere to y coordinates on planogram
   */
  getPlanogramYCoordinate(position: Vector3) {
    for (let i = 0; i < RESOLUTION_HIGH; i++) {
      if (
        (this.sphereYPoints[i + 1].y <= position.y && position.y <= this.sphereYPoints[i].y) ||
        (this.sphereYPoints[i].y <= position.y && position.y <= this.sphereYPoints[i + 1].y)
      ) {
        return this.interpolateBetweenPoints(position, this.sphereYPoints[i + 1], this.sphereYPoints[i], i + 1);
      }
    }
    throw new Error('Invalid position');
  }

  // Calculate the UV coordinate by interpolating between 2 points
  private interpolateBetweenPoints(
    point: Vector3,
    spherePointTop: Vector3,
    spherePointBottom: Vector3,
    i: number
  ): number {
    const normalizedY = (point.y - spherePointTop.y) / Math.abs(spherePointBottom.y - spherePointTop.y);
    return (normalizedY + i) / RESOLUTION_HIGH;
  }

  // Helper variables for performance
  private pointVector: Vector3 = new THREE.Vector3();
  private yAxis = new THREE.Vector3(0, 1, 0);
  private surfaceLength = SphereGeometry.calcTopToBottomSurfaceLength(
    PlanogramData.ALPHA,
    this.planogram.largeRadius,
    this.planogram.fixedRadius
  );

  private pointVector2D: Vector2 = new Vector2();
  getPlanogramCoordinates(point: Vector3) {
    this.pointVector2D.set(point.x, point.z);
    const angle = this.pointVector2D.angle();
    const x = Math.max(((angle + Math.PI / 2) % (Math.PI * 2)) / 2 / Math.PI, 0);

    const y = this.getPlanogramYCoordinate(point);

    return [x, y];
  }

  // Returns the 3D point on the sphere from the x, y coordinate on the planogram
  getSpherePoint(x: number, y: number) {
    const yDistance = (this.surfaceLength - this.planogram.height) / 2.0 + this.planogram.virtualTexture.y + y;
    const rotationAngle =
      SphereGeometry.calcAzimuthStartRadians(x, 0, this.planogram.width) - SphereGeometry.ROTATION_OFFSET;

    const intersect = SphereGeometry.calcSpherePoint(
      yDistance,
      this.surfaceLength,
      PlanogramData.ALPHA,
      this.planogram.largeRadius,
      this.planogram.fixedRadius
    );
    const adjustedIntersect = SphereGeometry.distortionAdjustment(
      intersect,
      yDistance,
      this.surfaceLength,
      PlanogramData.ALPHA,
      this.planogram.largeRadius,
      this.planogram.fixedRadius
    );

    this.pointVector.setX(adjustedIntersect.point[0]);
    this.pointVector.setY(adjustedIntersect.point[1]);
    this.pointVector.setZ(0);
    this.pointVector.applyAxisAngle(this.yAxis, rotationAngle);
    return this.pointVector.clone();
  }
}
