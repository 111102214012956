export const reqSettings = (method, data?, signal?, withToken = true) => {
  const accesstoken = window.localStorage.getItem('accesstoken');
  const authHeaders = (withToken && accesstoken) ? {accesstoken} : {};

  return {
    method,
    signal,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...authHeaders
    },
    body: data ? JSON.stringify(data) : null
  };
};
