import * as THREE from 'three';

export function normalizeAngle(a: number) {
  return (a + Math.PI * 2) % (Math.PI * 2);
}

export function angleDistance(a: number, b: number) {
  const d = normalizeAngle(b - a);
  return d > Math.PI ? Math.PI * 2 - d : d;
}

export class RotationUtils {
  static calculateZXFlattenAngle(vector) {
    const topDownVector = new THREE.Vector2(vector.x, vector.z);
    return topDownVector.angle();
  }

  static angleFor(point) {
    const angle = point.angle();
    if (angle > Math.PI) {
      return angle - 2 * Math.PI;
    }
    return angle;
  }

  static calc360AngleBetween(fromVec3, toVec3) {
    const firstAngle = RotationUtils.calculateZXFlattenAngle(fromVec3);
    const endAngle = RotationUtils.calculateZXFlattenAngle(toVec3);

    fromVec3.applyAxisAngle(new THREE.Vector3(0, 1, 0), firstAngle);
    toVec3.applyAxisAngle(new THREE.Vector3(0, 1, 0), endAngle);

    const flatFromPoint = new THREE.Vector2(fromVec3.x, fromVec3.y);
    const flatToPoint = new THREE.Vector2(toVec3.x, toVec3.y);

    return RotationUtils.angleFor(flatFromPoint) - RotationUtils.angleFor(flatToPoint);
  }

  static tiltAngleBetween(latestIntersect, previousIntersect, cameraPosition) {
    if (previousIntersect === undefined || latestIntersect === undefined) {
      return 0;
    }
    const camVec = cameraPosition.clone().negate();
    const startTiltPoint = previousIntersect.point.clone();
    const endTiltPoint = latestIntersect.point.clone();
    startTiltPoint.add(camVec);
    endTiltPoint.add(camVec);

    const angle = RotationUtils.calc360AngleBetween(startTiltPoint, endTiltPoint);
    return angle;
  }

  static panAngleBetween(latestIntersect, previousIntersect) {
    if (previousIntersect === undefined || latestIntersect === undefined) {
      return 0;
    }

    const startPanPoint = new THREE.Vector2(previousIntersect.point.x, previousIntersect.point.z);
    const endPanPoint = new THREE.Vector2(latestIntersect.point.x, latestIntersect.point.z);
    const panAngle = endPanPoint.angle() - startPanPoint.angle();

    return panAngle;
  }
}
