import {SphereItem} from '../../sphere_item';

export class SphereItemHTMLElement {
  container: HTMLElement;
  info: HTMLElement;

  constructor(item: SphereItem, parent?: HTMLElement) {
    this.container = document.createElement('div');
    parent?.append(this.container);
    this.container.className = 'sphere-item';
    this.container.style.position = 'absolute';
    this.container.style.top = '50%';
    this.container.style.left = '50%';
    this.container.style.transform = 'translate(-50%, -50%)';
    this.container.style.width = '100%';
    this.container.style.height = '100%';
    this.info = document.createElement('a');
    this.info.setAttribute('item-identifier', item.id.toString());
    this.info.tabIndex = 0;
    this.container.append(this.info);
  }

  dispose() {
    this.info.remove();
    this.container.remove();
  }
}
