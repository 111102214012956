import {isAppleDevice} from '../utils/platform';
import {searchEventHandler} from '../custom_event_utils';
import {throttle, debounce} from 'throttle-debounce';
import {UrlUtils} from '../api/urls';
import {SearchService} from '../api/services/search.service';
import {Search} from './search';
import {SearchSettings} from '../interfaces/search-setting.interface';
import {FontLoader} from '../font_loader';
import {SEARCH_EVENT_NAMES as SEARCH_EVENTS} from '../event-names';
import {Metrics} from '../metrics';
import {MATOMO_EVENT_NAMES} from '../metric-events';

interface IconSettings extends SearchSettings{
  customButtonColor: string;
  systemButtonColor: string;
  customIconUrl: string;
  systemIconUrl: string;
  customInputColor?: string;
  systemInputColor?: string;
}

export class SearchInput {
  private searchWrapper: HTMLElement;
  private isSearchInputDisabled: boolean;
  private inputEl: HTMLInputElement;
  private searchButton: HTMLElement;
  private searchSettings: SearchSettings;
  private searchedQuery: string;

  constructor(private searchService: SearchService) {
    this.isSearchInputDisabled = false;
    this.initInput();
    this.registerListeners();
  }

  static get ANIMATION_TIME() {
    return 300;
  }

  static get THROTTLE_DELAY() {
    return 200;
  }

  static get METRIC_EVENT_DELAY() {
    return 2000;
  }

  private initInput() {
    this.searchWrapper = document.querySelector('.search-wrapper');
    this.inputEl = document.getElementById('search-input') as HTMLInputElement;
    this.searchButton = document.getElementById('search-button');
    this.applySearchSettings();

    if (!this.searchButton) {
      console.error('element with id - "search-button" not found');
      return;
    }

    if (!this.inputEl) {
      console.error('element with id - "search-input" not found');
      return;
    }
  }

  updateSearchSettings(settings: SearchSettings) {
    this.searchSettings = settings;
    this.initInput();
  }

  toggle() {
    this.searchWrapper.classList.toggle('is-search-active');
    const isSearchActive = this.searchWrapper.classList.contains('is-search-active');

    if (isSearchActive) {
      this.inputFocus();
      Metrics.storeTheEvent(
        this.searchService.planogramName,
        'click',
        MATOMO_EVENT_NAMES.CLICK_SEARCH_BUTTON_DEFAULT
      );
    } else {
      Metrics.storeTheEvent(
        this.searchService.planogramName,
        'click',
        MATOMO_EVENT_NAMES.CLICK_SEARCH_BUTTON_ACTIVE
      );
    }

    searchEventHandler.emit(SEARCH_EVENTS.SHOW_SEARCH, {isSearchActive});
  }

  closeInput() {
    this.searchWrapper.classList.remove('is-search-active');
    this.inputEl.blur();
    this.searchedQuery = '';
  }

  showInput() {
    this.searchWrapper.classList.add('is-search-active');
    this.inputFocus();
    this.searchedQuery = SearchInput.getSearchedPhraseFromUrl();

    if (this.searchedQuery && this.searchSettings) {
      this.inputEl.value = this.searchedQuery;

      if (this.searchedQuery.length >= Search.SEARCH_QUERY_MINIMUM_LENGTH) {
        this.requestResults(this.searchedQuery);
      }
      this.setBorderState(this.searchedQuery);
    }
  }

  disableSearchInput() {
    this.isSearchInputDisabled = true;
  }

  registerListeners() {
    this.inputEl.addEventListener('input', throttle(SearchInput.THROTTLE_DELAY, (e) => {
      this.handleSearch(e);
    }));

    this.inputEl.addEventListener('input', debounce(SearchInput.METRIC_EVENT_DELAY, e => {
      const query = e.target.value;
      if (query.length >= Search.SEARCH_QUERY_MINIMUM_LENGTH) {
        Metrics.storeTheEvent(
          this.searchService.planogramName,
          'input',
          MATOMO_EVENT_NAMES.INPUT_SEARCH_QUERY(UrlUtils.slugify(query))
        );
      }
    }));

    this.inputEl.addEventListener('blur', (e) => {
      requestAnimationFrame(() => {
        window.scroll(0, 0);
      });
    });

    this.inputEl.addEventListener('touchstart', () => this.inputEl.focus());

    this.searchButton.addEventListener('click', this.toggle.bind(this));
  }

  requestResults(query: string) {
    searchEventHandler.emit(SEARCH_EVENTS.REQUEST_STATUS, {state: true});
    this.searchService.filterResults(query)
      .then(results => {
        searchEventHandler.emit(SEARCH_EVENTS.REQUEST_STATUS, {state: false});
        this.searchService.updateSearchFilters(results);
      });
  }

  private applySearchSettings() {
    if (!this.searchSettings) {
      return;
    }

    const iconWrapper = this.searchButton.querySelector('.search-button-icon-wrapper');

    const root = document.documentElement;
    const activeSettings =
      this.parseSettings(this.searchSettings?.search_setting.search_active_icons);
    if (activeSettings.customIconUrl || activeSettings.systemIconUrl) {
      const img = document.createElement('img');
      img.setAttribute('src', activeSettings.customIconUrl || activeSettings.systemIconUrl);
      img.classList.add('search-active-icon');
      iconWrapper.appendChild(img);
    }
    root.style.setProperty(
      '--search-button-active-color',
      activeSettings.customButtonColor || activeSettings.systemButtonColor
    );
    root.style.setProperty(
      '--search-input-color',
      activeSettings.customInputColor || activeSettings.systemInputColor
    );
    this.inputEl.setAttribute('placeholder', this.searchSettings?.search_setting.search_title);
    if (this.searchSettings.search_setting?.search_setting_font) {
      FontLoader.mountCustomFonts([this.searchSettings.search_setting?.search_setting_font]);
    }
  }

  private parseSettings(stateSettings): IconSettings {
    const obj = {} as IconSettings;

    stateSettings?.forEach(iconSettings => {
      if (iconSettings?.input_color) {
        obj[iconSettings.identifier + 'InputColor'] = iconSettings.input_color;
      }

      obj[iconSettings.identifier + 'ButtonColor'] = iconSettings?.button_color;
      obj[iconSettings.identifier + 'IconUrl'] = iconSettings?.url;
    });

    return obj;
  }

  private inputFocus() {
    // workaround for ios devices
    // on ios first focus event doesn't display keyboard
    let tempInputEl;
    if (isAppleDevice) {
      // Align temp input element approx. to be where the input element is
      tempInputEl = document.createElement('input');
      tempInputEl.setAttribute('readonly', 'true');
      tempInputEl.style.position = 'absolute';
      tempInputEl.style.top = this.inputEl.offsetTop + 'px';
      tempInputEl.style.left = this.inputEl.offsetLeft + 'px';
      tempInputEl.style.height = '0';
      tempInputEl.style.opacity = '0';
      // Put this temp element as a child of the page <body> and focus on it
      document.body.appendChild(tempInputEl);
      tempInputEl.focus();
    }

    // The keyboard is open. Now do a delayed focus on the target element
    setTimeout(() => {
      this.inputEl.focus();
      // Remove the temp element
      if (tempInputEl) {
        document.body.removeChild(tempInputEl);
      }
    }, SearchInput.ANIMATION_TIME);
  }

  private handleSearch(e: Event) {
    if (this.isSearchInputDisabled) {
      return;
    }

    const searchQuery = (e.target as HTMLInputElement).value;
    this.searchService.updateSearchQuery(this.searchService.planogramName, searchQuery);
    this.setBorderState(searchQuery);

    if (searchQuery.length < Search.SEARCH_QUERY_MINIMUM_LENGTH) {
      searchEventHandler.emit(SEARCH_EVENTS.UPDATE_SEARCH);
      return;
    }

    this.requestResults(searchQuery);
  }

  private static getSearchedPhraseFromUrl() {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const query = searchParams.get('query');
      return query && decodeURIComponent(query);
    } catch (e) {
      console.error('Can\'t encode searched phrase');
    }

    return '';
  }

  setBorderState(searchQuery: string) {
    if (searchQuery.length) {
      this.searchWrapper.classList.add('show-border');
    } else {
      this.searchWrapper.classList.remove('show-border');
    }
  }
}
