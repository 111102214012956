import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';

import {L10nUtils} from './utils/l10n_utils';
import {sphereEventHandler} from './custom_event_utils';
import {SPHERE_EVENT_NAMES} from './event-names';
import {CookiesManagement} from './cookies_management';

export class Fullscreen {
  private enterRef: Function;
  private exitRef: Function;

  static enter(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    CookiesManagement.allowFullscreen = true;
  }

  static exit(doc) {
    if (doc.exitFullscreen && doc.fullscreenElement) {
      doc.exitFullscreen();
    } else if (doc.webkitExitFullscreen && doc.webkitFullscreenElement) {
      doc.webkitExitFullscreen();
    } else if (doc.mozCancelFullScreen && doc.mozFullscreenElementn) {
      doc.mozCancelFullScreen();
    } else if (doc.msExitFullscreen && doc.msFullscreenElement) {
      doc.msExitFullscreen();
    }
    CookiesManagement.allowFullscreen = false;
  }

  constructor(private clickElement: HTMLElement,
              private elementToFullscreen: HTMLElement,
              private doc: Document,
              mainElement: HTMLElement,
              private resizeCallback) {
    this.enterRef = Fullscreen.enter.bind(null, elementToFullscreen);
    this.exitRef = Fullscreen.exit.bind(null, doc);
    if (clickElement && this.fullscreenEnabled()) {
      this._setUpButton();
      this.clickElement.addEventListener('click', (e) => {
        this.toggle(e);
      }, false);
      this.clickElement.addEventListener('touchend', (e) => {
        this.toggle(e);
      }, false);
      this.clickElement.addEventListener('wheel', (e) => {
        mainElement.dispatchEvent(new WheelEvent(e.type, e));
      }, {passive: false});
      this.addFullscreenChangeListeners();
    }

    sphereEventHandler.listen(
      SPHERE_EVENT_NAMES.COOKIES.ALLOW_FULLSCREEN,
      this.enterRef
    );

    sphereEventHandler.listen(
      SPHERE_EVENT_NAMES.COOKIES.DISABLE_FULLSCREEN,
      this.exitRef
    );

    pipe(
      L10nUtils.languageLoadedSubject,
      subscribe({
        next: (langCode: string) => {
          if (!langCode) {
            return;
          }
          this.handleLanguageChanged();
        }
      })
    );
  }

  addFullscreenChangeListeners() {
    const events = ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange'];
    for (const event of events) {
      this.doc.addEventListener(event, (e) => {
        this._toggleButton(e);
      }, true);
    }
  }

  toggle(event) {
    event?.preventDefault();
    event?.stopPropagation();
    if (this._isFullscreen()) {
      Fullscreen.exit(this.doc);
    } else {
      Fullscreen.enter(this.elementToFullscreen);
    }
  }

  fullscreenEnabled() {
    return (this.doc.fullscreenEnabled === true
      || this.doc['webkitFullscreenEnabled'] === true
      || this.doc['mozFullScreenEnabled'] === true
      || this.doc['msFullscreenEnabled'] === true);
  }

  _isFullscreen() {
    return !(this.doc.fullscreenElement == null
      && this.doc['webkitFullscreenElement'] == null
      && this.doc['mozFullScreenElement'] == null
      && this.doc['msFullscreenElement'] == null);
  }

  _setUpButton() {
    const fullscreenOnImage = document.getElementById('fullscreen-on-button');
    fullscreenOnImage.classList.remove('is-hidden');
  }

  _toggleButton(event) {
    event.preventDefault();
    event.stopPropagation();

    const fullscreenOnImage = document.getElementById('fullscreen-on-button');
    const fullscreenOffImage = document.getElementById('fullscreen-off-button');
    if (this._isFullscreen()) {
      fullscreenOffImage.classList.remove('is-hidden');
      fullscreenOnImage.classList.add('is-hidden');
    } else {
      fullscreenOnImage.classList.remove('is-hidden');
      fullscreenOffImage.classList.add('is-hidden');
    }

    if (this.resizeCallback) {
      this.resizeCallback();
    }
  }

  private handleLanguageChanged() {
    const fullscreenOnImage = document.getElementById('fullscreen-on-button').querySelector('img');
    const fullscreenOffImage = document.getElementById('fullscreen-off-button').querySelector('img');

    if (fullscreenOnImage) {
      fullscreenOnImage.setAttribute('alt', L10nUtils.l10n('fullscreen.on-button.alt-text'));
    }
    if (fullscreenOffImage) {
      fullscreenOffImage.setAttribute('alt', L10nUtils.l10n('fullscreen.off-button.alt-text'));
    }
  }
}
