export class Logger {
  private message: Function;

  constructor() {
    window.setInterval(this.displayMessages.bind(this), 1000);
  }

  log(...message) {
    this.message = window.console.log.bind(window.console, message.join(' '));
  }

  displayMessages() {
    if (this.message) {
      this.message();
    }
  }
}
