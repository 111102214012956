import * as THREE from 'three';
import {Box2, Vector2} from 'three';
import {PlanogramsService} from './api/services/planograms.service';
import {ErrorOverlay} from './errors_overlay';
import {PageLoadingSpeed} from './page_loading_speed';
import {
  CLICKABLE_PRODUCT_TITLE_OR_SHOW_ANIMATION_REGEX,
  PAGE_LOADING_TYPES,
  SPHERE_ITEM_TYPES
} from './shared/constants';
import {
  ANIMATION_POSITION,
  ANIMATION_TYPE,
  ItemData,
  ItemLOD,
  ItemTile,
  LODSMetaData,
  MediaMetaData,
  PlanogramData,
  PlanogramVersion,
  VirtualTextureData
} from './interfaces/planogram.interface';
import {L10nUtils} from './utils/l10n_utils';
import Router from './router';
import {AppState} from './shared/app.state';
import {CDN_RESOURCES, UrlUtils} from './api/urls';
import {modulo, Modulo} from './utils/moduloUtils';
import {MathUtils} from './utils/math_utils';

const pagesModulo = new Modulo(PlanogramData.pages());

export class Planogram extends PlanogramData {
  clientName: string;

  static DEFAULT_LODS_AMOUNT = 7;
  static BASE_LOD = 4;
  static THRESHOLD_FOR_SWITCHING_LODS = 3;

  size() {
    return new Vector2(this.width, this.height * 0.5);
  }

  constructor(name) {
    super();
    this.name = name;
    this.planogramsService = new PlanogramsService();
    ErrorOverlay.hide();
    PageLoadingSpeed.startMeasure(PAGE_LOADING_TYPES.SPHERE);

    this.planogramsService
      .getPlanogram(name)
      .then(res => {
        if (!res?.planogram_version?.actions_json) {
          throw new Response(res, {status: 404, statusText: 'Not Found'});
        }
        this._sphereLoaded(res.planogram_version);
      })
      .catch(err => {
        if (err.status === 404) {
          if (L10nUtils.getCurrentLanguage() === L10nUtils.fallbackLanguage) {
            ErrorOverlay.show404Error();
          } else {
            Router.updateLangCode(L10nUtils.fallbackLanguage);
          }
        } else if (
          err.status === 401 ||
          (err instanceof TypeError && (err?.message === 'cancelled' || err?.message === 'Load failed'))
        ) {
          // iOS return TypeError on cancelling authorization attempt
          // instead of trying to request data without credentials
          ErrorOverlay.show404Error();
        } else {
          ErrorOverlay.show500Error();
        }
        if (err.status === undefined) console.error(err);
      });
  }

  private loadedResolve: () => void;

  readonly loaded: Promise<void> = new Promise<void>((resolve, reject) => {
    this.loadedResolve = resolve;
  });

  _getTilesPath(versionConfig: PlanogramVersion) {
    const {tiles_path, tiles_paths} = versionConfig;
    return tiles_paths?.webp ?? tiles_path;
  }

  _sphereLoaded(versionConfig: PlanogramVersion) {
    const planogramConfig = versionConfig.actions_json;
    this.id = versionConfig.planogram_id;
    this.legacy = versionConfig.planogram_legacy;
    this.isExternalDomain = versionConfig.client?.is_external_domain;
    this.versionId = versionConfig.id;
    this.tilesPath = this._getTilesPath(versionConfig);
    this.clientName = versionConfig.client.name;
    this.ecommerceEnabled = versionConfig.client?.ecommerce_platform_enabled;
    this.isMultipassKeyAvailable = versionConfig.client?.shopify_multipass_enabled;
    this.multipassRedirectUrl = versionConfig.client?.shopify_multipass_url;
    this.ecommercePlatformName = versionConfig.client?.shopping_platform?.title;
    this.ecommercePlatformCurrency = versionConfig.client?.shopping_platform?.currency;
    this.ecommercePlatformCurrencies = versionConfig.client?.currencies;
    this.fingerprint = planogramConfig.fingerprint;
    this.width = planogramConfig.width;
    this.height = planogramConfig.height;
    this.topLimit = planogramConfig.topLimit;
    this.bottomLimit = planogramConfig.bottomLimit;
    this.seoTitle = versionConfig.seo_title;
    this.seoDescription = versionConfig.seo_desc;
    this.backgroundColor = planogramConfig.backgroundColor || [1, 1, 1];
    // to handle null and undefined cases
    // tslint:disable-next-line:triple-equals
    this.startPoint =
      planogramConfig.startPoint === null ? this.width / 2 : (planogramConfig.startPoint / 360.0) * this.width;
    this.clustersOrder = planogramConfig.clustersOrder || [];
    this.planogramVersion = planogramConfig.planogramVersion;
    this.planogramVersionControlButtons = versionConfig.planogram_version_control_buttons;
    this.planogramVersionLogo = versionConfig.planogram_version_logo;
    this.enabledGalleryOverlay = versionConfig.enabled_gallery_overlay;
    this.infoButtonSetting = versionConfig.info_button_setting;
    this.ecommerceOverlaySettingsPdp = versionConfig.ecommerce_overlay_settings_pdp;
    this.ecommerceOverlaySettingsShoppingCart = versionConfig.ecommerce_overlay_settings_shopping_cart;
    this.ecommerceOverlaySettingsSignIn = versionConfig.ecommerce_overlay_settings_sign_in;
    this.ecommerceOverlaySettingsButtons = versionConfig.ecommerce_overlay_settings_buttons;
    this.sharingButton = versionConfig.sharing_button;
    this.primaryColor = versionConfig.primary_color || '#181b25';
    this.secondaryColor = versionConfig.secondary_color || '#2b3147';
    this.iframePrimaryColor = versionConfig.iframe_primary_color || '#ffffff';
    this.iframeSecondaryColor = versionConfig.iframe_secondary_color || '';
    this.cameraPosition = versionConfig.camera_position;
    this.clientSocialMedias = versionConfig.client_social_medias;
    this.animationSettings = versionConfig.animation_settings;
    this.audioSettings = versionConfig.audio;
    this.otherAssets = versionConfig.other_assets;
    this.audioBackgroundColor = versionConfig.audio_background_color;
    this.entranceAnimation = versionConfig.entrance_animation;
    this.volume = versionConfig.volume;
    this.navigationAlignment = versionConfig.navigation_alignment;
    this.navigationDistributeEvenly = versionConfig.navigation_distribute_evenly;
    if (planogramConfig.virtualTexture) {
      this.virtualTexture = this.createVirtualTextureFromJSON(planogramConfig.virtualTexture);
      this.virtualTextureOffset = new Vector2(planogramConfig.virtualTexture.x, planogramConfig.virtualTexture.y);
    }

    this.items = this.createItemsFromJSON(planogramConfig.items);
    this.productSEO = {};
    versionConfig.planogram_version_products_seo?.forEach(
      o =>
        (this.productSEO[o.id] = {
          title: o.seo_title
        })
    );
    this.itemSEO = {};
    versionConfig.planogram_version_items_seo?.forEach(
      o =>
        (this.itemSEO[o.id] = {
          title: o.seo_title
        })
    );

    if (this.legacy) {
      console.warn('Legacy spheres are not supported, please regenerate sphere');
    }

    AppState.planogramName = this.name;
    AppState.isExternalDomain = this.isExternalDomain;
    this.loadedResolve();
    PageLoadingSpeed.completeMeasure(PAGE_LOADING_TYPES.SPHERE);
  }

  static get COLUMN_COUNT() {
    return 50;
  }

  static get ROW_COUNT() {
    return 50;
  }

  static colorInteger(color) {
    return color[0] + 256 * color[1] + 256 * 256 * color[2];
  }

  shiftLods(lods: ItemLOD[], targetTileCount: number) {
    // must be the same base lod as in vt_pipeline FOVtoLOD
    const baseLodLevel = 4;
    const baseLod: ItemLOD = lods.reduce((bestMatch, it) =>
      Math.abs(it.lod - baseLodLevel) < Math.abs(bestMatch.lod - baseLodLevel) ? it : bestMatch
    );
    const baseUV = baseLod.textures[0].uv;
    const baseTileCount = baseLod.gridSize * Math.min(baseUV.width, baseUV.height);
    const steps = MathUtils.log(targetTileCount / baseTileCount, 2);
    const wholeSteps = Math.max(-lods.length + 1, Math.min(lods.length - 1, Math.ceil(steps)));
    lods.forEach((lod, index) => {
      lod.lod = index + wholeSteps;
    });
  }

  createItemsFromJSON(items = []) {
    const loadAdjustment = parseFloat(new URLSearchParams(window.location.search).get('lod_adjust') ?? '1');
    const tileToPlanogramRatio = (window.devicePixelRatio * window.innerHeight) / loadAdjustment / this.size().y / 512;

    const filteredItems = items.filter(
      item => !(item.type === SPHERE_ITEM_TYPES.VIDEO && (item.data as MediaMetaData).videoUrl === '')
    );

    const mappedItems: ItemData[] = filteredItems.map((obj, index) => {
      const virtualBoundaries = this.tileVirtualBoundaries(
        new Vector2(obj.x, obj.y + obj.height),
        new Vector2(obj.x + obj.width, obj.y)
      );

      const lods: ItemLOD[] = obj?.lods?.map((lod: ItemLOD) => {
        lod.lod = Number(lod.lod);
        const gridSize = Math.ceil(Math.sqrt(lod.textures.length));
        const textures = lod.textures.map(itemTile =>
          itemTile
            ? {
                uv: itemTile.uv ?? {width: 1, height: 1, x: 0, y: 0},
                url: this.generateItemUrl(lod.url_start, itemTile.url)
              }
            : null
        );

        if (gridSize % 1 !== 0) {
          console.error(`Number of textures ${gridSize} is not of power of two!`, obj, lod);
        }
        return {...lod, textures, gridSize};
      });

      if (!obj.data?.imageName && obj.data?.picture?.name) {
        obj.data.imageName = obj.data?.picture?.name;
      }

      if (lods) {
        lods.sort((a, b) => a.lod - b.lod);

        const integerLodCorrection = 2;
        const itemTileSize =
          Math.max(Math.abs(obj.width), Math.abs(obj.height)) * tileToPlanogramRatio * integerLodCorrection;
        this.shiftLods(lods, itemTileSize);
      }

      return {
        id: obj.id ?? index,
        parentId: obj.parentId,
        renderOrder: 99999 - obj.layer,
        childrenIds: obj.childrenIds,
        type: obj.type,
        x: obj.x,
        y: obj.y,
        width: obj.width,
        height: obj.height,
        rotation: obj.rotation,
        action: obj.action,
        name: obj.name,
        instagram_feed: obj.instagram_feed,
        data: {
          ...obj.data,
          lods
        },
        virtualBoundaries
      };
    });

    if (this.tilesPath) {
      mappedItems.push(...this.backgroundToItemData(this.virtualTexture));
    }
    // Assign the parent object to items
    mappedItems.forEach(item => {
      item.childrenIds?.forEach(id => {
        const child = mappedItems.find(it => it.id === id);
        if (child === undefined) console.warn(`Cluster ${item.id} has unknown child ${id}`);
        else child.parent = item;
      });
    });

    // Sort the items by position
    mappedItems.sort((a, b) => {
      if (a.x < b.x) {
        return -1;
      } else if (a.x > b.x) {
        return 1;
      } else if (a.y < b.y) {
        return 1;
      }
      return -1;
    });

    this.itemsOrder = mappedItems.map(item => item.id as string);

    return mappedItems;
  }

  checkRegexpStatement(regex, action) {
    if (action) {
      const result = action.match(regex);
      return result && !!result.length;
    }
  }

  extractProductImage(productAction: string): string {
    return productAction.replace(CLICKABLE_PRODUCT_TITLE_OR_SHOW_ANIMATION_REGEX, '');
  }

  generateBackgroundItemLOD(lod: number, gridSize: number, offset: Vector2, amountOfItemsOnAxis: Vector2): ItemLOD {
    const textures: ItemTile[] = [];
    for (let x = 0; x < gridSize; x++) {
      for (let y = 0; y < gridSize; y++) {
        textures.push({
          boundaries: new Box2(
            new Vector2(
              ((offset.x + x / gridSize) / amountOfItemsOnAxis.x) * Planogram.PAGES_WIDE,
              ((offset.y + y / gridSize) / amountOfItemsOnAxis.y) * Planogram.PAGES_HIGH
            ),
            new Vector2(
              ((offset.x + (x + 1) / gridSize) / amountOfItemsOnAxis.x) * Planogram.PAGES_WIDE,
              ((offset.y + (y + 1) / gridSize) / amountOfItemsOnAxis.y) * Planogram.PAGES_HIGH
            )
          ),
          url: this.generateBackgroundLODUrl(lod, offset.x * gridSize + x, offset.y * gridSize + y),
          uv: {x: 0.0, y: 0.0, width: 1, height: 1},
          center: new Vector2(
            ((offset.x + (x + 0.5) / gridSize) / amountOfItemsOnAxis.x) * Planogram.PAGES_WIDE,
            ((offset.y + (y + 0.5) / gridSize) / amountOfItemsOnAxis.y) * Planogram.PAGES_HIGH
          ),
          size: gridSize
        });
      }
    }
    return {
      lod,
      textures,
      gridSize
    };
  }

  backgroundToItemData(virtualTexture: VirtualTextureData): ItemData<LODSMetaData>[] {
    const {worstLod, pagesHigh, pagesWide} = virtualTexture;

    // Find the amount of tiles at worst lod
    const backgroundItems: ItemData<LODSMetaData>[] = [];

    const initialGridSize = Math.round(Math.min(pagesWide, pagesHigh) / Math.pow(2, worstLod));

    // Split the background into squares - each square is 1 item
    const amountOfItemsOnAxis = new Vector2(Math.ceil(pagesWide / pagesHigh), Math.ceil(pagesHigh / pagesWide));
    const numberOfBackgroundItems = Math.max(amountOfItemsOnAxis.x, amountOfItemsOnAxis.y);
    const expandOnAxis = amountOfItemsOnAxis.x > amountOfItemsOnAxis.y ? new Vector2(1, 0) : new Vector2(0, 1);

    for (let offset = 0; offset < numberOfBackgroundItems; offset++) {
      const lods: ItemLOD[] = [];
      let gridSize = initialGridSize;
      const offsetVector = expandOnAxis.clone().multiplyScalar(offset);

      for (let lod = worstLod; lod >= 0; lod--) {
        lods.push(this.generateBackgroundItemLOD(lod, gridSize, offsetVector, amountOfItemsOnAxis));
        gridSize *= 2;
      }

      const width = this.width / amountOfItemsOnAxis.x;
      const height = this.height / 2.0 / amountOfItemsOnAxis.y;

      const x = (offsetVector.x / amountOfItemsOnAxis.x) * this.width;
      const y = this.height / 2.0 + ((offsetVector.y / amountOfItemsOnAxis.y - 0.5) * this.height) / 2.0;

      backgroundItems.push(this.generateBackgroundItemData(lods, offset, width, height, x, y));
    }

    return backgroundItems;
  }

  generateBackgroundItemData(
    lods: ItemLOD[],
    index: number,
    width: number,
    height: number,
    x: number,
    y: number
  ): ItemData<LODSMetaData> {
    return {
      id: `background${index}`,
      data: {
        code: '',
        description: '',
        name: '',
        lods
      },
      type: SPHERE_ITEM_TYPES.IMAGE,
      rotation: [0, 0, 0] as [number, number, number],
      x,
      y,
      childrenIds: [],
      renderOrder: 1,
      width,
      height,
      virtualBoundaries: new Box2(new Vector2(0, 0), new Vector2(Planogram.PAGES_WIDE, Planogram.PAGES_HIGH))
    };
  }

  generateBackgroundLODUrl(lod: number, x: number, y: number) {
    if (!CDN_HOST) {
      console.error("Can't load tiles. CDN_HOST contains wrong value.");
      return '';
    }

    return UrlUtils.insertFewValuesToUrl(CDN_RESOURCES.SPHERE_TEXTURE, {
      tilesPath: this.tilesPath,
      textureName: `${lod}-${x}-${y}`,
      extension: '.webp'
    });
  }

  generateItemUrl(urlStart: string, url: string) {
    return UrlUtils.insertFewValuesToUrl(CDN_RESOURCES.ITEM_TEXTURE, {url: `${urlStart}/${url}.${'webp'}`});
  }

  createVirtualTextureFromJSON(json) {
    return {
      x: json.x,
      y: json.y,
      width: json.width,
      height: json.height,
      pagesWide: json.pagesWide,
      pagesHigh: json.pagesHigh,
      pageSize: json.pageSize,
      worstLod: json.worstLod,
      permanentLod: json.permanentLod,
      pageBorderWidth: json.pageBorderWidth
    };
  }

  private virtualTextureOffset: Vector2;

  canvasToTileCoordinate(pos: Vector2) {
    return this.planogramToTileCoordinate(pos.clone().sub(this.virtualTextureOffset));
  }

  static maximumBoundary = new Box2(new Vector2(), PlanogramData.pages());

  planogramToTileCoordinate(pos: Vector2) {
    const planogramSize = this.size();
    const result = pos.clone();
    result.x = modulo(result.x, planogramSize.x);
    result.y = planogramSize.y - result.y;
    result.multiply(PlanogramData.pages()).divide(planogramSize);
    Planogram.maximumBoundary.clampPoint(result, result);
    return result;
  }

  tileVirtualBoundaries(min: Vector2, max: Vector2) {
    const boundaryBox = new THREE.Box2(
      this.canvasToTileCoordinate(min.clone()),
      this.canvasToTileCoordinate(max.clone())
    );
    boundaryBox.min.x = Math.floor(boundaryBox.min.x);
    boundaryBox.min.y = Math.floor(boundaryBox.min.y);
    boundaryBox.max.x = Math.ceil(boundaryBox.max.x);
    boundaryBox.max.y = Math.ceil(boundaryBox.max.y);
    pagesModulo.moduloB(boundaryBox);
    return boundaryBox;
  }
}
