import {Camera, Object3D, Vector3} from 'three';
import {VRController} from './vr_controller';

const CAMERA_MOVEMENT_SPEED = 4.0;
const CAMERA_ROTATION_SPEED = 100.0;

export class VRCameraControls {
  constructor(private controllers: VRController[], private sphere: Object3D) {}

  update(camera: Object3D) {
    const moving = this.controllers[0]?.selected && this.controllers[1]?.selected;

    const rotating = !moving && (this.controllers[0]?.selected || this.controllers[1]?.selected);

    if (moving) {
      const averageDeltaPosition = this.controllers[0]
        .deltaPosition()
        .add(this.controllers[1].deltaPosition())
        .divideScalar(2.0)
        .multiplyScalar(CAMERA_MOVEMENT_SPEED);
      camera.position.add(averageDeltaPosition);
    }

    if (rotating) {
      const deltaRotation = this.controllers[0]?.selected
        ? this.controllers[0].deltaRotation()
        : this.controllers[1].deltaRotation();
      this.sphere.rotateY(deltaRotation);
    }
  }

  dispose() {}
}
